.contacts-page {
		max-width: 1472px;
		padding-left: 16px;
		padding-right: 16px;

		& .container {
				padding: 0; }

		@include max-screen(1280px) {
				padding-left: 40px;
				padding-right: 40px; }

		@include max-screen(1024px) {
				padding-left: 32px;
				padding-right: 32px; }

		@include max-screen(976px) {
				padding-left: 15px;
				padding-right: 15px; }

		&__title {
				margin-bottom: 40px;
				text-align: center; }

		&__bread-crumbs {
				margin: 30px 0 40px; } }


.main-title {
		font-size: 35px;
		color: $c-black;
		font-family: $ffs-m;

		@include max-screen(1024px) {
				font-size: 30px; }

		@include max-screen(576px) {
			font-size: 28px; } }



.contacts-content {
		display: flex;
		gap: 44px;
		margin-bottom: 60px;

		@include max-screen(1280px) {
			gap: 40px; }

		@include max-screen(1024px) {
			flex-direction: column; }

		&__requisites {
				display: flex;
				gap: 83px;
				padding-bottom: 20px;
				margin-bottom: 30px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);

				@include max-screen(976px) {
					gap: 25px; }

				@include max-screen(576px) {
					flex-direction: column;
					gap: 40px; } }

		&__numbers {
				display: flex;
				padding-bottom: 25px;
				margin-bottom: 25px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);

				&:last-child {
						margin-bottom: 0; }

				@include max-screen(576px) {
					flex-direction: column;
					gap: 40px; } }

		&__numbers:last-child {
				border-bottom: none; }

		&__map {
				max-width: 579px;
				max-height: 475px;
				width: 100%;

				@include max-screen(1280px) {
					max-width: 425px; }

				@include max-screen(1024px) {
					min-height: 476px;
					align-items: flex-end; }

				@include max-screen(976px) {
					min-height: 385px;
					max-width: 100%; }

				@include max-screen(576px) {
						width: calc(100% + 30px);
						transform: translateX(-15px);
						min-height: 295px;
						max-width: calc(100% + 30px); } } }



