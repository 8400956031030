.product-configuration {
		max-width: 455px;
		width: 100%;

		@include max-screen(1280px) {
			max-width: 400px; }

		@include max-screen(976px) {
			max-width: 100%; }

		&__compare {
				margin-top: 40px;
				padding-bottom: 27px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				margin-bottom: 23px;

				@include max-screen(976px) {
						margin-top: 0;
						margin-bottom: 15px;
						padding-bottom: 10px; } }

		&__title {
				font-size: 35px;
				color: $c-black;
				font-family: $ffs-m;
				margin-bottom: 19px;

				@include max-screen(976px) {
					max-width: 300px;
					font-size: 30px; }

				@include max-screen(576px) {
					font-size: 20px;
					max-width: 100%; } }

		&__selects {
				flex-direction: column;
				margin-bottom: 18px;

				@include max-screen(576px) {
						margin-bottom: 0; } }

		&__link {
				color: $c-black;
				font-size: 14px;
				margin-bottom: 28px;
				display: inline-block;

				@include max-screen(976px) {
						margin-bottom: 15px; } }

		&__price {
				display: flex;
				align-items: center;
				gap: 19px;
				margin-bottom: 30px;

				@include max-screen(976px) {
						margin-bottom: 15px; } }

		&__advantages {
				margin-bottom: 31px; }

		&__basket {
				margin-bottom: 10px; }

		&__basket-installment {
				margin-bottom: 25px;

				@include max-screen(976px) {
						max-width: 100%; } }

		&__link-order {
				margin-bottom: 23px; } }



.product-compare {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&__label {
				display: flex;
				gap: 8px;
				cursor: pointer; }

		&__check-compare {
				display: flex;
				gap: 8px;
				align-items: center;

				.span {
						font-size: 14px;

						@include max-screen(576px) {
							font-size: 12px;
							line-height: 20px; } } }

		&__checkbox {
				width: 20px;
				height: 20px;
				cursor: pointer; } }



.product-selects {
		display: flex;
		justify-content: space-between;
		gap: 14px;

		&--direction {
				flex-direction: column; }

		&__item {
				width: 100%; } }



.product-price {

		&__main {
				padding: 0;
				font-size: 22px;

				@include max-screen(576px) {
						font-size: 20px; }

				&--min {
					font-size: 18px;

					@include max-screen(576px) {
						font-size: 16px; } }

				&--small {
						font-size: 16px;

						@include max-screen(576px) {
							font-size: 12px; } } }

		&__old {
				font-size: 18px;

				&--small {
						font-size: 14px; } } }



.product-advantages {
		gap: 17px;
		display: flex;
		flex-direction: column;

		&__text {
				font-size: 13px; } }

.product-configuration__advantages {
		margin-top: 0;

		& .product-advantages__item {
				margin-top: 0; } }


.product-basket {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		padding: 15px 20px;

		&__text-first {
				max-width: 225px;
				width: 100%;
				text-align: left;
				color: $c-black; }

		&__text {
				font-size: 14px; }

		&__icon {
				width: 23px;
				height: 23px;
				color: $c-white;

				&--blue {
						color: $c-blue-800; } } }



.product-link-order {
		text-decoration: none;
		color: $c-blue-800;
		display: flex;
		justify-content: center; }
