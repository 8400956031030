.page-home {

	&__section-slider {
			margin: 0 0 50px;

			@include max-screen(576px) {
					margin-bottom: 30px; } }

	&__bedding {
			position: relative;
			width: calc(100% + 50px);
			padding: 0 25px;
			transform: translateX(-25px);
			margin: 60px 0;

			@include max-screen(976px) {
					width: calc(100% + 65px); }

			& .common-slider__btn--prev {
					@include max-screen(976px) {
							left: 15px; } }

			& .swiper-button-disabled {
				opacity: 0;
				cursor: none;

				@include max-screen(1024px) {
					& .common-slider__btn {
						display: none; }

			 	@include max-screen(768px);
					margin-top: 15px; } }

			@include max-screen(976px) {
				margin-top: 0; } }

	&__border {
			margin: 60px 0;
			border: 1px solid rgba(0, 0, 0, 0.1); }

	&__sleeping-systems_subtitle--min {
		margin-bottom: 22px;

		@include max-screen(976px) {
			& + .section-advantages {
					margin-bottom: 60px; } }

		@include max-screen(576px) {
				margin-bottom: 30px; } }

	&__services {
		margin-bottom: 60px;

		@include max-screen(976px) {
			margin-bottom: 30px; }

		&__novelties-slider {
			margin-bottom: 60px; } }

	&__reviews {
		margin-top: 60px;
		margin-bottom: 55px; }

	&__questions {
			margin-bottom: 63px; }

	&__useful-articles {
			margin-bottom: 60px; } }


.sofa-article-link {
		position: absolute;
		&__link {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				z-index: 2;

				&:hover ~ .sofa-link__text .sofa-article-link__btn {
						background: #F6F5F5;
						color: #24306B; } }

		&__title {
				padding: 10px;
				font-size: 14px;
				line-height: 18px;
				color: #000000;
				background: rgba(255, 255, 255, 0.5);
				mix-blend-mode: normal;
				backdrop-filter: blur(4px);
				border-radius: 4px;
				display: inline-block;
				text-align: center;
				text-decoration: none;

				@include max-screen(576px) {
					padding: 3px 10px;
					margin-bottom: 10px;
					font-size: 12px;
					line-height: 1; }


				&--children-article {
						padding: 4px 10px; }

				&--article-dark {
						background: rgba(154, 154, 154, 0.2); } }



		&__subtitle {
				font-size: 27px;
				line-height: 35px;
				color: #000000;
				margin: 20px 0 24px;
				font-family: $ffs-m;

				@include max-screen(1280px) {
					font-size: 40px;
					line-height: 50px; }

				@include max-screen(976px) {
					font-size: 30px;
					line-height: 37.5px; }

				@include max-screen(576px) {
					margin: 0 0 15px 0;
					font-size: 14px;
					line-height: 17.7px; }


				&--min {
						font-size: 23px;
						line-height: 27px; }

				&--main-slider {
						font-weight: 500;
						font-size: 40px;
						line-height: 50px; }

				&--children-article {
						font-size: 23px;
						max-width: 279px; } }


		&__btn {
				text-align: center;
				text-decoration: none;
				display: inline-block;
				font-size: 16px;
				line-height: 20px;

				@include max-screen(576px) {
					font-size: 12px;
					line-height: 140%; } } }


.top-slider {
		display: flex;
		justify-content: space-between; }



.main-slide {
		position: relative;

		&__text {
				max-width: 334px;
				top: 122px;
				right: 163px;

				@include max-screen(1280px) {
					max-width: 465px;
					right: 50px; }

				@include max-screen(1024px) {
					top: 106px; }

				@include max-screen(976px) {
					max-width: 330px;
					top: 60px;
					right: 36px; }

				@include max-screen(576px) {
					top: 30px;
					max-width: 155px; } }

		&__image {
				height: 540px;

				& img {
						height: 100%;
						width: 100%;
						object-fit: cover; }

				@include max-screen(1024px) {
						height: 430px; }

				@include max-screen(976px) {
						height: 360px; }

				@include max-screen(576px) {
						height: 160px; } } }


.section-slider {
		display: flex;
		justify-content: space-between;
		gap: 20px;

		@include max-screen(976px) {
			gap: 10px; }

		@include max-screen(576px) {
			flex-direction: column;
			gap: 30px; }

		&__link {
 } }				//background: black



.section-bedding {
		//display: flex
 }		//justify-content: space-between



.btn-systems {
		display: flex;
		gap: 14px;
		padding: 13px 20px;
		background: $c-dark-400;
		border-radius: 50px;
		align-items: center;
		cursor: pointer;
		color: $c-blue-800;
		transition: .3s;

		&--white {
				background: $c-white;
				color: $c-black;
				padding: 0;

				&:hover {
						background: $c-white !important;
						color: $c-black !important; } }


		&:hover {
				background: $c-blue-800;
				color: $c-white; }

		&:hover .btn-systems__icon {
				color: $c-white; }

		&__material {
				font-family: $ffs-m;
				font-size: 14px;
				line-height: 18px;
				opacity: 0.5; }

		&--long {
				justify-content: space-between;
				color: $c-black;
				width: 100%;
				margin-bottom: 20px; }

		&__description {
				display: flex;
				flex-direction: column;
				align-items: flex-start; }

		&__text {
				font-family: $ffs-m;
				font-size: 14px;
				line-height: 18px; }

		&__icon {
				width: 16px;
				color: $c-blue-800;
				transition: .3s; } }




.big-article {
		position: relative;

		&__img {
				max-width: 959px;
				width: 100%;
				object-fit: contain;

				.img {
						width: 100%; } }

		&__wrapper {
				position: absolute;
				top: 1px; } }
