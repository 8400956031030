// Font's
$ff-r: 'TT Commons Pro Expanded Regular';
$ffs-m: 'TT Commons Pro Expanded Medium';
$ff: $ff-r;

// Color's

$c-white: #fff;
$c-black: #000;

$c-blue-900: #D5DFE9;
$c-blue-700: #407ab1;
$c-blue-750: #3B4FB0;
$c-blue-800: #24306B;

$c-dark-900: #111111;
$c-dark-800: #010101;
$c-dark-700: #808080;
$c-blue-680: #9A9A9A;
$c-dark-650: #C7C7C7;
$c-dark-500: #d7d3d3;
$c-dark-450: #EDEDED;
$c-blue-400: #F0F0F0;
$c-dark-300: #F9F9F9;

$c-dark-400: #F6F5F5;
$c-blue-300: #F6F8F9;

$c-yellow: #F0DA64;
$c-additional-yellow: #FFC700;
$c-additional-blue: #3C5AED;
$c-additional-orange: #F98600;
$c-additional-red: #CA0000;
$c-additional-green: #ACDB30;
$c-additional-grey: #C0C0C0;
$c-additional-green: #7FA392;
$c-red-900: #FF0000;
//$c-brown-900: #674933
//$c-brown-800: #F9F9F9
$c-brown-700: #9d9999;
//$c-brown-600: #C1B5A6
//$c-brown-500: #d7d3d3

//$c-red-900: #FF0000
$c-red-800: #D14040;
$c-red-700: #B86059;

$c-yellow-600: #F0DA64;

//$c-additional-blue: #3C5AED
//$c-additional-orange: #FFC700
//$c-additional-orange: #F3CC00
//$c-additional-orange: #F98600
//$c-additional-green: #ACDB30
//$c-additional-green: #7FA392
//$c-additional-red: #CA0000

$c-primary: $c-blue-800;
$c-secondary: rgba($c-blue-700, .5);

$t-function: cubic-bezier(.49,.31,.68,.81);
