.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
}


#SVG_container{
	position: absolute;
	height: 0;
	width: 0;
}

#SVG_container svg{
	position: absolute;
	height: 0;
	width: 0;
}

.icon-arrow-down {
	font-size:(12/10)*1rem;
	width:(12/12)*1em;
}
.icon-arrow-enter {
	font-size:(14/10)*1rem;
	width:(17/14)*1em;
}
.icon-arrow-input {
	font-size:(9/10)*1rem;
	width:(9/9)*1em;
}
.icon-arrow-left {
	font-size:(14/10)*1rem;
	width:(17/14)*1em;
}
.icon-arrow-right {
	font-size:(14/10)*1rem;
	width:(17/14)*1em;
}
.icon-arrow-select {
	font-size:(9/10)*1rem;
	width:(9/9)*1em;
}
.icon-arrow-size {
	font-size:(16/10)*1rem;
	width:(29/16)*1em;
}
.icon-ball {
	font-size:(8/10)*1rem;
	width:(8/8)*1em;
}
.icon-basket {
	font-size:(28/10)*1rem;
	width:(28/28)*1em;
}
.icon-bed {
	font-size:(34/10)*1rem;
	width:(34/34)*1em;
}
.icon-bread-crumb-arrow {
	font-size:(9/10)*1rem;
	width:(7/9)*1em;
}
.icon-callback {
	font-size:(32/10)*1rem;
	width:(32/32)*1em;
}
.icon-chart-price {
	font-size:(12/10)*1rem;
	width:(18/12)*1em;
}
.icon-check {
	font-size:(7/10)*1rem;
	width:(9/7)*1em;
}
.icon-check-marks {
	font-size:(15/10)*1rem;
	width:(28/15)*1em;
}
.icon-check2 {
	font-size:(8/10)*1rem;
	width:(11/8)*1em;
}
.icon-close {
	font-size:(13/10)*1rem;
	width:(13/13)*1em;
}
.icon-compare {
	font-size:(23/10)*1rem;
	width:(26/23)*1em;
}
.icon-dash {
	font-size:(2/10)*1rem;
	width:(15/2)*1em;
}
.icon-delivery {
	font-size:(22/10)*1rem;
	width:(24/22)*1em;
}
.icon-dif-hard {
	font-size:(19/10)*1rem;
	width:(19/19)*1em;
}
.icon-down {
	font-size:(9/10)*1rem;
	width:(14/9)*1em;
}
.icon-eurostandart {
	font-size:(26/10)*1rem;
	width:(25/26)*1em;
}
.icon-favorites {
	font-size:(22/10)*1rem;
	width:(24/22)*1em;
}
.icon-favorites-2 {
	font-size:(16/10)*1rem;
	width:(18/16)*1em;
}
.icon-hard {
	font-size:(19/10)*1rem;
	width:(19/19)*1em;
}
.icon-hight {
	font-size:(19/10)*1rem;
	width:(17/19)*1em;
}
.icon-insta {
	font-size:(20/10)*1rem;
	width:(20/20)*1em;
}
.icon-left {
	font-size:(14/10)*1rem;
	width:(9/14)*1em;
}
.icon-list {
	font-size:(13/10)*1rem;
	width:(14/13)*1em;
}
.icon-logo {
	font-size:(67/10)*1rem;
	width:(187/67)*1em;
}
.icon-loupe {
	font-size:(17/10)*1rem;
	width:(18/17)*1em;
}
.icon-menu {
	font-size:(96/10)*1rem;
	width:(96/96)*1em;
}
.icon-ok {
	font-size:(19/10)*1rem;
	width:(20/19)*1em;
}
.icon-pay-card {
	font-size:(30/10)*1rem;
	width:(30/30)*1em;
}
.icon-percent {
	font-size:(20/10)*1rem;
	width:(20/20)*1em;
}
.icon-personal {
	font-size:(23/10)*1rem;
	width:(20/23)*1em;
}
.icon-phone {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.icon-point-map {
	font-size:(20/10)*1rem;
	width:(20/20)*1em;
}
.icon-quality {
	font-size:(26/10)*1rem;
	width:(25/26)*1em;
}
.icon-quest {
	font-size:(23/10)*1rem;
	width:(23/23)*1em;
}
.icon-right {
	font-size:(14/10)*1rem;
	width:(9/14)*1em;
}
.icon-rigidity {
	font-size:(19/10)*1rem;
	width:(19/19)*1em;
}
.icon-spring {
	font-size:(19/10)*1rem;
	width:(15/19)*1em;
}
.icon-star {
	font-size:(26/10)*1rem;
	width:(27/26)*1em;
}
.icon-star-empty {
	font-size:(18/10)*1rem;
	width:(19/18)*1em;
}
.icon-star-fill {
	font-size:(18/10)*1rem;
	width:(19/18)*1em;
}
.icon-subway {
	font-size:(12/10)*1rem;
	width:(17/12)*1em;
}
.icon-table {
	font-size:(28/10)*1rem;
	width:(28/28)*1em;
}
.icon-top {
	font-size:(9/10)*1rem;
	width:(14/9)*1em;
}
.icon-vk {
	font-size:(12/10)*1rem;
	width:(22/12)*1em;
}
.icon-webest {
	font-size:(19/10)*1rem;
	width:(98/19)*1em;
}
.icon-youtube {
	font-size:(14/10)*1rem;
	width:(20/14)*1em;
}
