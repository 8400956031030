.grade-stars {
		position: relative;

		&__background {
				display: flex;
				gap: 2px;

				&-img {
						width: 23px;
						height: 23px;

						@include max-screen(576px) {
								width: 19px;
								height: 19px; } } }

		&__active {
				position: absolute;
				top: 0;
				display: flex;
				gap: 2px;

				&-img {
						width: 23px;
						height: 23px;

						@include max-screen(576px) {
								width: 19px;
								height: 19px; } } } }

