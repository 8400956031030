.pagination {
	display: flex;
	align-items: stretch;
	justify-content: center;
	min-width: 24px;

	&__icon {
			width: 16px;
			height: 16px;
			transition: .3s; }

	&__control {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #000000;
		transition: background ease .3s;
		margin: 0 4px;
		width: 50px;
		height: 50px;
		background: #F6F5F5;
		border-radius: 50%;

		&:hover .pagination__icon {
				color: #24306B; }

		.icon {
			font-size: 12px; }

		&--disabled {
				opacity: 0.5;
				cursor: auto; } }

	&__list {
		margin: 0 10px;
		display: flex;
		align-items: center; }

	&__link {
		margin: 0 4px;
		width: 50px;
		height: 50px;
		border: 1px solid #EDEDED;
		display: flex;
		align-items: center;
		justify-content: center;
		background: trasnsparent;
		color: #000000;
		text-decoration: none;
		font-family: $ff;
		font-size: 15px;
		//transition: background ease .3s
		border-radius: 50%;
		transition: .3s;

		&:hover:not(.pagination__link--not-hover) {
			border: 1px solid #808080; }

		&--current {
			border: 1px solid #24306B; } } }
