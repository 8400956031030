.btn {
		text-decoration: none;
		border: none;
		@include reset_btn;

		&--long {
				display: flex;
				justify-content: space-between;
				max-width: 476px;
				width: 100%;
				border-radius: 50px;
				background: $c-dark-400;
				padding: 25px 30px;
				color: $c-blue-800;
				font-size: 14px; }

		&:disabled {
				background: #EDEDED;
				cursor: not-allowed;

				&:hover {
						border: 1px solid transparent; } }

		&--empty {
				color: $c-blue-800;
				display: flex;
				//margin: 60px auto 0
				overflow: hidden; }

		&--primary {
				font-family: $ffs-m;
				font-style: normal;
				border-radius: 50px;
				background: $c-white;
				color: $c-black;
				transition: .3s;
				text-align: center;
				padding: 12px 0;

				&:hover {
						background: $c-blue-800;
						color: $c-white; } }

		&--min {
				font-family: $ff-r;
				color: $c-white;
				padding: 10px 20px;
				background: $c-blue-800;
				border-radius: 50px;
				text-align: center;
				transition: .3s;

				@include max-screen(576px) {
						padding: 10px 15px; }

				&:hover {
						background: $c-dark-400;
						color: $c-blue-800; } }

		&--dark {
				background: #F6F5F5;
				font-family: $ff-r;
				color: $c-blue-800;
				padding: 10px 20px;
				border-radius: 50px;
				text-align: center; }

		&--white-main {
				background: $c-white;
				border-radius: 50px;
				color: $c-blue-800;
				transition: .3s;

				&:hover {
						background: $c-blue-800;
						color: $c-white; } }

		&--white {
				text-decoration: none;
				font-family: $ff-r;
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;
				color: #000000;
				padding: 10px 15px;
				border: 1px solid #EDEDED;
				border-radius: 50px;
				transition: .3s;

				&:hover {
						border: 1px solid #24306B; } }

		&--sort {
				font-size: 14px;
				color: $c-black;
				opacity: 0.5;
				max-width: max-content;
				display: flex;
				align-items: center;
				padding: 0;
				gap: 8px; }

		&--active {
				opacity: 1; }

		&__icon {
				width: 18px;
				height: 12px; }

		&--material {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0;
			gap: 5px;

			&:hover .btn__material-img {
					border: 1px solid $c-dark-700; } }

		&__material-text {
				font-size: 12px;
				text-align: center; }

		&__material-img {
				padding: 5px;
				width: 68px;
				height: 68px;
				border: 1px solid transparent;
				border-radius: 50px;
				transition: border .2s;

				&--active {
						border: 1px solid $c-black; } } }



.btn-reset {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 14px;

		&__icon {
				width: 11px;
				height: 11px;
				color: $c-blue-800; }

		&__text {
				font-size: 14px;
				color: $c-blue-800; } }



.button-show-more {
		width: 100%;
		padding: 15px 0;
		background: $c-dark-400;
		border-radius: 50px;
		color: $c-blue-800;
		font-size: 14px;
		transition: .3s;

		&:hover {
				background: $c-blue-800;
				color: $c-dark-400; }

		&--hide {
				display: none; } }



.btn-show-card {
		padding: 15px 89px;
		margin: 0 auto 60px;
		display: flex; }



.btn-close {
		background: none;
		border: none;
		display: flex;
		cursor: pointer;

		&__icon {
				width: 11px;
				height: 11px;
				color: $c-blue-800; } }



.btn-danger {
		background-color: #dc3545;
		border-color: #dc3545; }



.btn-primary {
		background-color: #0d6efd;
		border-color: #0d6efd; }
