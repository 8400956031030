.text-main {

		&__title {
				font-size: 35px;
				font-family: $ffs-m;
				text-align: center;
				margin-bottom: 40px; }

		&__subtitle {
				font-size: 25px;
				font-family: $ffs-m;
				margin-bottom: 23px; }

		&__text {
				font-size: 18px;
				line-height: 31px;
				margin-bottom: 30px;

				@include max-screen(976px) {
					font-size: 16px;
					line-height: 1.5; } }

		&__border {
			height: 1px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
			margin-bottom: 30px; } }



.page-privacy {
		max-width: 1472px;
		padding-left: 16px;
		padding-right: 16px;

		& .container {
				padding: 0; }

		@include max-screen(1280px) {
				padding-left: 40px;
				padding-right: 40px; }

		@include max-screen(1024px) {
				padding-left: 32px;
				padding-right: 32px; }

		@include max-screen(976px) {
				padding-left: 15px;
				padding-right: 15px; }

		&__bread-crumbs {
				margin: 30px 0 40px; }

		&__content {
				margin-bottom: 60px; } }
