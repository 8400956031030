.homepage-reviews {

		&__title {
				max-width: 464px;
				width: 100%;
				margin: 0 auto;
				text-align: center;
				margin-bottom: 60px;

				@include max-screen(1024px) {
					margin-bottom: 30px; }
				@include max-screen(976px) {
					max-width: 100%; } } }



.users-review {
		display: flex;
		justify-content: space-between;
		font-family: $ffs-m;
		color: $c-black;

		@include max-screen(976px) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 30px 55px; }

		@include max-screen(576px) {
				grid-template-columns: 1fr; } }
