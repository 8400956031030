.page-feedback {

		&__title {
				margin-bottom: 60px;

				@include max-screen(1280px) {
						margin-bottom: 50px; }

				@include max-screen(976px) {
						margin-top: 30px;
						margin-bottom: 30px; } }

		&__card {
				margin-bottom: 60px;

				@include max-screen(1280px) {
						margin-bottom: 50px; }

				@include max-screen(976px) {
						margin-bottom: 30px; } }

		&__subtitle {
				font-size: 16px;
				font-family: $ffs-m;
				color: $c-black;
				margin-bottom: 20px; }

		&__rating {
				margin-bottom: 60px;

				@include max-screen(1280px) {
						margin-bottom: 50px; }

				@include max-screen(976px) {
						margin-bottom: 30px; } }

		&__input {
				max-width: 606px;
				width: 100%;
				border-radius: 10px;
				margin-bottom: 60px;
				min-height: 110px; }

		&__input-inside {
				min-height: 100px; }

		&__button {
				padding: 18px 25px;
				font-size: 14px;

				@include max-screen(976px) {
						margin-bottom: 0; } } }
