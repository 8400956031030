.product-card {
		position: relative;
		max-width: 456px;
		width: calc(50% - 10px);
		min-height: 414px;
		padding: 18px;
		text-decoration: none;

		@include max-screen(1280px) {
				min-height: 320px; }

		@include max-screen(1024px) {
				min-height: 260px; }


		&:hover .product-popup {
				opacity: 1; }

		&__image {
				position: absolute;
				bottom: 0;
				right: 0;
				max-width: 456px;
				width: 100%;
				height: 100%;

				& img {
						width: 100%;
						height: 100%;
						object-fit: cover; } }

		&__info {
				position: relative;
				display: flex;
				gap: 8px; }

		&__popup {
				position: absolute;
				top: 50%;
				right: -33%;
				padding: 15px 20px;
				background: $c-additional-green;
				color: $c-white;
				font-size: 14px; } }



.novelty {
		color: $c-white;
		padding: 5px 10px;
		background: $c-additional-green;
		font-size: 14px; }



.hit {
		color: $c-white;
		padding: 5px 10px;
		background: $c-blue-800;
		font-size: 14px; }
