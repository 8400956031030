.product-popup {
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 374px;
		width: 100%;
		z-index: 2;
		opacity: 0;
		transition: .3s;

		&:hover {
				opacity: 1; }

		&--appear {
				opacity: 0; }

		&__icon {
				width: 11px;
				height: 11px;
				cursor: pointer; } }
