.ymaps-2-1-79-float-button {
		width: 38px;
		height: 38px !important;
		border-radius: 11px !important; }

.ymaps-2-1-79-float-button-icon {
		padding: 5px; }

.ymaps-2-1-79-zoom {
		height: 20px !important; }


.ymaps-2-1-79-zoom__plus .ymaps-2-1-79-zoom__icon {
		background-image: url("/img/yamap_plus.svg") !important; }

.ymaps-2-1-79-zoom__minus .ymaps-2-1-79-zoom__icon {
		background-image: url("/img/yamap_minus.svg") !important; }

.ymaps-2-1-79-zoom__plus {
		border-radius:  10px 10px 0 0 !important; }

.ymaps-2-1-79-zoom__minus {
		border-radius:  0 0 10px 10px !important; }

.ymaps-2-1-79-controls__control_toolbar {
		position: absolute !important;
		top: 256px;
		right: 13px; }

.ymaps-2-1-79-controls__control {
		position: absolute !important;
		right: 23px !important;
		top: 177px !important; }
