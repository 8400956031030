.personal-page {
		max-width: 1472px;
		padding-left: 16px;
		padding-right: 16px;

		& .container {
				padding: 0; }

		@include max-screen(1280px) {
				padding-left: 40px;
				padding-right: 40px; }

		@include max-screen(1024px) {
				padding-left: 32px;
				padding-right: 32px; }

		@include max-screen(976px) {
				padding-left: 15px;
				padding-right: 15px; }

		&__bread-crumbs {
				margin: 30px 0 60px; }

		&__content {
				display: flex;
				gap: 36px; } }



.personal-cart {
		max-width: 1200px;
		width: 100%;
		padding: 30px 0 30px 40px;
		position: relative;

		@include max-screen(576px) {
				width: calc(100% + 30px);
				transform: translateX(-15px); }

		&__preview {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				display: flex; }

		&__img {
				width: 100%;
				height: auto;
				object-fit: cover;
				border-radius: 4px; }

		&__title {
				font-size: 30px;
				color: $c-white;
				font-family: $ffs-m;

				@include max-screen(576px) {
					font-size: 20px; } }

		&__content {
				position: relative;
				z-index: 2;
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				gap: 10px;
				justify-content: flex-end; }

		&__button {
				padding: 13px 20px;
				width: fit-content;
				display: flex;
				align-items: center;
				gap: 14px; }

		&__buttons {
				display: flex;
				gap: 10px; } }



.card-status-yellow {
		background: $c-additional-yellow; }

.card-status-blue {
		background: $c-additional-blue; }

.card-status-orange {
		background: $c-additional-orange; }

.card-status-red {
		background: $c-additional-red; }

.card-status-green {
		background: $c-additional-green; }

.card-status-grey {
		background: $c-additional-grey; }




.order-card {
		width: 100%;
		background: $c-dark-300;
		display: flex;
		flex-direction: column;
		border-radius: 4px;
		padding: 20px;

		&--wide {
				max-width: 582px; }

		&__block {
				display: flex;
				justify-content: space-between;

				@include max-screen(576px) {
					flex-direction: column; } }

		&__title {
				font-size: 15px;
				line-height: 19px;
				color: $c-black;
				font-family: $ffs-m;
				margin-bottom: 5px; }

		&__subtitle {
				font-size: 15px;
				line-height: 19px;
				color: $c-dark-700;
				margin-bottom: 10px; }

		&__text {
				color: $c-blue-800;
				font-size: 18px;
				line-height: 22px;
				margin-bottom: 35px;

				&--space {
						margin-bottom: 0px;
						min-width: 135px;
						text-align: right;

						@include max-screen(576px) {
								margin: 10px 0;
								text-align: left; } } }

		&__images {
				margin-bottom: 10px;
				display: flex;
				justify-content: space-between;

				&--width {
						max-width: 250px;
						width: 100%; } }

		&__status {
				padding: 5px 10px;
				color: $c-white;
				margin-top: 15px;
				margin-bottom: 30px; }

		&__link {
				color: $c-blue-800;
				text-decoration: none; }

		&__preview {
				max-width: 53px;
				width: 100%;
				height: 35px;
				object-fit: contain; }

		&__img {
				width: 100%;
				height: 100%;
				object-fit: cover; } }


.personal-card {
		max-width: 200px;
		width: 100%;

		&__preview {
				max-width: 110px;
				width: 100%;
				height: 70px;
				object-fit: contain; }

		&__img {
				width: 100%;
				margin-bottom: 14px; }

		&__title {
				margin-bottom: 10px; } }


.personal-main {
		display: flex;
		flex-direction: row;
		gap: 36px;

		@include max-screen(976px) {
			gap: 20px; }

		@include max-screen(576px) {
				flex-direction: column; }

		&__header {
				display: flex;
				white-space: nowrap;
				gap: 7px;
				flex-direction: column;
				align-items: flex-start;
				max-width: 210px;
				width: 100%; }

		&__content {
				width: calc(100% - 246px);
				opacity: 1;
				transition: .3s;

				@include max-screen(576px) {
						width: 100%; } }

		&__button {
				text-align: left;
				padding: 15px 0 15px 15px;
				width: 100%;
				transition: .3s;
				color: $c-black;

				&--active {
						border-radius: 10px;
						color: $c-blue-800;
						background: $c-blue-900; } }

		&__main-personal {
				margin-bottom: 76px; } }



.main-personal {

		&__top {
				margin-bottom: 30px;

				@include max-screen(576px) {
						margin-bottom: 60px; } }

		&__title {
				margin-bottom: 25px; }

		&__cards {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				gap: 20px;
				margin-bottom: 20px;

				@include max-screen(1280px) {
					grid-template-columns: repeat(3, 1fr);

					& .order-card:nth-child(n+4) {
							display: none; } }

				@include max-screen(1024px) {
						display: none; } }

		&__cards-slider {
				display: none;

				@include max-screen(1024px) {
						display: block;
						width: calc( 100% + 32px);
						margin-bottom: 20px; } }

		&__link {
				width: fit-content;
				margin-bottom: 30px; } }



.title-min {
		font-size: 20px;
		color: $c-black;
		font-family: $ffs-m; }



.personal-data {

		&__info {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				gap: 30px 10px;
				margin-bottom: 20px;

				@include max-screen(1024px) {
					grid-template-columns: repeat(2, 1fr); }

				@include max-screen(576px) {
					grid-template-columns: 1fr;
					gap: 25px; } }

		&__button {
				font-family: $ffs-m; }

		&__footer {
				margin-bottom: 60px;
				display: flex;
				gap: 12px;
				align-items: center;
				position: relative; }

		&__title {
				margin-bottom: 30px; }

		&__link {
				padding: 0;
				margin-bottom: 60px; }

		&__password {
				margin-bottom: 20px; }

		&__card {
				margin-bottom: 60px; } }



.card-personal {
		display: flex;
		flex-direction: column;
		gap: 4px;

		&__title {
				font-size: 13px;
				line-height: 16px;
				opacity: 0.5;
				color: $c-black; }

		&__text {
				font-size: 15px;
				line-height: 19px;
				color: $c-black; } }



.sale-card {
		position: relative;
		max-width: 680px;
		width: 100%;
		border: 1px solid $c-dark-400;
		border-radius: 4px;
		padding: 20px;
		display: flex;
		justify-content: space-between;
		overflow: hidden;

		&__title {
				font-size: 24px;
				line-height: 30px;
				color: $c-black;
				font-family: $ffs-m;
				margin-bottom: 20px;

				@include max-screen(576px) {
					margin-bottom: 10px;
					font-size: 16px;
					line-height: 22px; } }

		&__subtitle {
				font-size: 15px;
				line-height: 19px;
				color: $c-black;
				font-family: $ffs-m;
				margin-bottom: 30px;

				@include max-screen(576px) {
					font-size: 14px; } }

		&__text {
				font-size: 14px;
				line-height: 18px;
				color: $c-blue-800;
				margin-bottom: 20px; }

		&__column {
				position: relative;

				@include max-screen(976px) {
					&:first-child {
							max-width: 330px; }

					&:not(:first-child) {
							position: absolute;
							right: -50px; } }

				@include max-screen(576px) {
						&:first-child {
								max-width: 71%; }

						&:not(:first-child) {
								position: absolute;
								top: 55px;
								right: -60px;
								width: 127px; } } }

		&__preview {
				max-width: 163px;
				width: 100%;
				height: 163px;
				object-fit: contain;

				&--min {
						position: absolute;
						top: 7px;
						left: -33px;
						max-width: 70px;
						height: 70px; } }

		&__background,
		&__image {
				width: 100%; }

		&__checkbox {
				display: flex;
				gap: 25px; } }



.img-info {
		position: relative;

		&__img {
				cursor: pointer; }

		&:hover .img-info__content {
				display: flex; }


		&__content {
				position: absolute;
				max-width: 281px;
				width: 100%;
				padding: 15px;
				box-shadow: 4px 8px 23px $c-dark-650;
				top: -138px;
				left: 15px;
				z-index: 3;
				background: $c-white;
				font-size: 13px;
				display: none;
				opacity: 1;
				-webkit-animation: display-none-transition .3s both;
				animation: display-none-transition .3s both;

				@-webkit-keyframes display-none-transition {
						0% {
								opacity: 0; } }

				@keyframes display-none-transition {
						0% {
								opacity: 0; } } }

		&__block {
				width: 25px;
				height: 25px;
				background: $c-white;
				position: absolute;
				transform: rotate(136deg);
				left: 44%;
				bottom: -13px; } }



.personal-redact {

		@include max-screen(976px) {
			& .page-favorites__card {
					width: calc(50% - 8px); } }

		&__personal-order {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				gap: 20px 10px;
				margin-bottom: 60px;
				background: $c-dark-300;
				border-radius: 4px;
				padding: 20px;
				position: relative;

				@include max-screen(976px) {
					grid-template-columns: 1fr;
					padding-bottom: 70px; } }

		&__status {
				padding: 5px 10px;
				width: fit-content;
				color: $c-white;
				margin-bottom: 20px; }

		&__card-box {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 30px;
				margin-bottom: 60px;

				@include max-screen(1024px) {
					grid-template-columns: 1fr; }

				@include max-screen(576px) {
					gap: 40px; } }

		&__goods {
				display: grid;
				grid-template-columns: 1fr;
				gap: 20px;
				margin-bottom: 60px;

				@include max-screen(576px) {
					grid-template-columns: repeat(2, 1fr);
					gap: 16px; } }

		&__personal-card {
				margin-bottom: 60px; }

		&__button {
				width: fit-content;
				margin-bottom: 34px; }

		&__title {
				margin-bottom: 30px; }

		&__form {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 20px;
				margin-bottom: 30px;

				@include max-screen(768px) {
					grid-template-columns: 1fr;
					gap: 25px; } }

		&__form-passwords {
				display: flex;
				flex-direction: column;
				gap: 10px;
				margin-bottom: 30px; }

		&__button-submit {
				padding: 15px 20px;
				margin-bottom: 445px; } }



.total-personal-card {
		display: flex;
		justify-content: space-between;
		max-width: 606px;
		width: 100%;
		border: 1px solid #CACACA;
		border-radius: 10px;
		padding: 40px;
		align-items: center;

		@include max-screen(976px) {
			flex-direction: column;
			align-items: flex-start;
			gap: 32px;
			padding: 20px; }

		&__column {
				max-width: 285px;
				width: 100%; }

		&__button {
				padding: 18px 50px;
				display: flex;
				align-items: center;
				gap: 12px;
				height: 53px;
				justify-content: center;

				@include max-screen(576px) {
						width: 100%; } }

		&__border {
				border: 1px solid black;
				color: $c-black;
				width: 100%;
				margin-top: 20px;
				margin-bottom: 20px; }

		&__icon {
				width: 20px;
				height: 20px; }

		&__description {
				max-width: 300px;
				width: 100%;
				display: flex; }

		&__text {
				font-size: 14px;
				line-height: 31px;
				color: $c-black;
				max-width: 100px;
				width: 100%;

				&--big {
						font-size: 20px;
						line-height: 25px;
						font-family: $ffs-m;
						max-width: 150px; } } }



.btn-close-empty {
		display: flex;
		align-items: center;
		gap: 14px;
		position: absolute;
		top: 20px;
		right: 20px;
		padding: 0;

		@include max-screen(1024px) {
			top: auto;
			bottom: 20px; }

		@include max-screen(976px) {
			bottom: 20px;
			left: 20px; }

		&__icon {
				width: 11px;
				height: 11px;
				color: $c-blue-800; }

		&__text {
				font-size: 14px;
				line-height: 18px;
				font-family: $ffs-m;
				color: $c-blue-800; } }


.input-text {
		position: relative;
		max-width: 348px;
		width: 100%;

		&__input {
				padding: 20px 20px 4px;
				height: 60px;
				max-width: 348px;
				width: 100%; }

		&__text {
				position: absolute;
				top: 9px;
				left: 21px;
				font-size: 13px;
				line-height: 16px;
				color: $c-black;
				opacity: 0.5; } }



.single-card {
		display: flex;
		gap: 40px;

		@include max-screen(576px) {
			flex-direction: column;
			gap: 20px; }

		&__preview {
				max-width: 110px;
				width: 100%;
				height: 100px;
				object-fit: cover; }

		&__img {
				width: 100%;
				height: 100%; }

		&__description {
				max-width: 303px;
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 10px; }

		&__title {
				font-size: 16px;
				line-height: 20px;
				color: $c-black;
				font-family: $ffs-m; }

		&__text {
				font-size: 14px; } }



.password {
		width: 300px;
		margin: 15px auto;
		position: relative; }

.password-control {
		position: absolute;
		top: 21px;
		right: 23px;
		display: inline-block;
		width: 23px;
		height: 21px;
		background: url('/img/icons/open-oko.svg') 0 0 no-repeat; }

.password-control.view {
		background: url('/img/icons/close-oko.svg') 0 0 no-repeat; }
