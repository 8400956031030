.product-reviews {
		display: flex;
		justify-content: space-between;
		gap: 115px;

		@include max-screen(1280px) {
			gap: 100px; }

		@include max-screen(1024px) {
			gap: 60px; }

		@include max-screen(976px) {
			flex-direction: column;
			gap: 50px; }

		&__select {
				max-width: 193px;
				width: 100%; }

		&__reviews {
				width: 100%; }

		&__review {
				margin-top: 30px; }

		&__title {
				display: flex;
				justify-content: space-between; }

		&__subtitle {
				font-size: 28px;
				color: $c-black;
				font-family: $ffs-m;

				@include max-screen(576px) {
					font-size: 20px; } }

		&__button {
				max-width: 954px;
				width: 100%;
				margin-top: 40px; } }
