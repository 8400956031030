.review-item {
		display: flex;
		flex-direction: column;
		align-items: center;

		&__title {
				font-size: 20px;
				font-family: $ffs-m;
				margin-bottom: 15px;
				color: $c-black;
				max-width: 363px; }

		&__text {
				max-width: 363px;
				text-align: center;
				font-size: 16px;
				color: $c-black; }

		&__img {
				margin-bottom: 29px;

				& img {
						width: 48px;
						height: 48px;
						object-fit: contain; } } }
