.bread-crumbs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  //padding: 30px 0 50px
  flex-wrap: no-wrap;
  overflow: hidden;
  overflow-x: auto; }



.bread-crumb {
	//color: #000000
	font-size: 16px;
	text-decoration: none;
	font-family: $ff;
	line-height: 18px;
	white-space: nowrap;
	color: $c-dark-700;

	span {
		transition: .3s; }

	&:hover span {
			color: $c-blue-800; }

	&:after {
		content: url("/img/icons/bread-crumb-arrow.svg");
		padding-left: 10px;
		padding-right: 10px;
		text-decoration: none !important; }

	&:not(a) {
		color: #848E99;

		&:after {
			display: none; } }

	&--current {
			text-decoration: underline; } }
