.modals {
	display: none; }

.mfp-close {
  display: none !important; }

.mfp-bg {
	background: rgba(39, 62, 92, 0.45); }



.modal {
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	padding: 55px 76px 40px;
	background-color: #fff;
	color: #000000;
	position: relative;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.32);
	background-image: url('/img/11.png');

	&--message {
		max-width: 520px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 60px 20px 70px;
		overflow: hidden;

		.modal__description {
			max-width: 325px;
			margin-top: 25px;
			text-align: center; } }

	&__close {
		@include reset_btn;
		position: absolute;
		top: 17px;
		right: 17px;
		display: flex;
		padding: 10px;
		align-items: center;
		justify-content: center;

		@include max-screen(576px) {
				top: 10px;
				right: 10px; }

		& svg {
				pointer-events: none; }

		.icon {
			font-size: 10px;
			color: $c-blue-800;
			width: 11px;
			height: 11px; } }

	&__title {
			font-size: 20px;
			line-height: 25px;
			color: $c-blue-800;
			margin-bottom: 20px;
			font-family: $ffs-m;
			text-align: start; }

	&__description {
		font-size: 15px;
		margin: 0 auto;
		line-height: 1.5; }

	&__content {
		margin-top: 45px; }

	.form-default {

		.input-group {
			margin-bottom: 20px; }

		.input-group--help {
			height: 30px; }

		&__send {
			margin-bottom: 20px; }

		&__consent .input-group {
			display: flex;
			justify-content: center; } } }



.error-review {
		max-width: 640px;
		width: 100%;
		padding: 60px 108px;

		&__description {
				font-size: 16px;
				line-height: 20px;
				text-align: center;
				color: $c-black;
				margin-bottom: 60px; }

		&__button {
				max-width: 278px;
				margin: 0 auto; } }



.modal-cities {
		max-width: 645px;
		width: 100%;
		padding: 60px 95px;

		@include max-screen(576px) {
				display: flex;
				flex-direction: column;
				justify-content: center;
				min-height: 100vh;
				width: calc( 100% + 12px);
				padding: 70px 20px;
				transform: translateX(-6px);
				overflow: auto; }

		&__items {
				display: flex;
				flex-wrap: wrap;
				gap: 9px 0;
				justify-content: space-between; }

		&__item {
				display: flex;
				max-width: 152px;
				width: 100%;
				font-size: 16px;
				color: $c-black;
				text-decoration: none;
				transition: .3s;

				&:hover {
						color: $c-blue-800;
						text-decoration: underline; } }

		&__body {
				width: 100%; }

		&__title {
				margin-bottom: 28px; }

		&__search {
				width: 100%;
				margin-bottom: 31px; }

		&__btn {
				margin: 60px auto 0;

				@include max-screen(576px) {
						margin-top: 20px; } } }


.modal-callback {
		max-width: 640px;
		width: 100%;
		padding: 60px;

		@include max-screen(1024px) {
				padding: 40px; }

		@include max-screen(976px) {
				padding: 30px; }

		@include max-screen(576px) {
				padding: 20px;

				& .page-feedback__title {
						font-size: 20px;
						line-height: 1;
						margin-top: 0;
						margin-bottom: 20px; }

				& .page-feedback__card {
						flex-direction: column; }

				& .page-feedback__card {
						width: 100%; }

				& .card-min__img {
						max-width: 100%;

						& img {
								object-fit: cover; } }

				& .page-feedback__card {
						margin-bottom: 20px; } }

		&__body {
				width: 100%; }

		&__inputs {
				display: flex;
				gap: 10px;
				margin-bottom: 28px;

				@include max-screen(576px) {
					flex-direction: column; } }

		&__input {
				width: 100%!important;
				background: none;
				outline: none; }

		&__send {
				@include max-screen(576px) {
					flex-direction: column; } } }


.callback-send {
		display: flex;
		gap: 10px;

		@include max-screen(576px) {
			flex-direction: column; } }


.privacy-policy {
		display: flex;
		align-items: center;
		gap: 8px;
		cursor: pointer;

		&__span {
				font-size: 12px;
				color: rgba(0, 0, 0, 0.5);

				a {
						color: rgba(0, 0, 0, 0.5); } }

		&__checkbox {
				height: 21px;
				width: 29px;
				margin-bottom: 12px;
				border: 1px solid $c-blue-800; } }


.modal-material {
		margin-left: auto;
		margin-right: unset;
		max-width: 513px;
		width: 100%;
		height: 100vh;
		padding: 0;
		justify-content: flex-start;

		&__show-close {
				position: absolute;
				top: 20px;
				right: 35px;
				padding: 0; }

		&__top {
				padding: 30px 35px 22px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);

				@include max-screen(576px) {
						padding: 24px 15px; } }

		& .modal__title {
			text-align: center;
			font-size: 16px; }

		&__bottom {
				padding: 30px 35px;
				height: calc(100vh - 153px);
				overflow: auto;

				@include max-screen(576px) {
						padding: 15px; }

				&::-webkit-scrollbar-thumb {
						border-radius: 10px;
						background-color: $c-blue-800; }

				&::-webkit-scrollbar {
						width: 5px;
						background-color: #f9f9fd; }

				&--active {
						padding-bottom: 250px; } }


		&__body {
				position: relative;
				width: 100%; }

		&__button {
				top: 23px;
				z-index: 1; }

		&__show {
				width: 100%;
				position: absolute;
				bottom: 0;
				right: 0;

				@include max-screen(576px) {
					position: fixed; } }

		&__select {
				display: flex;
				justify-content: center;
				gap: 14px;

				@include max-screen(576px) {
					flex-direction: column; }

				.custom-select {
						max-width: 215px;

						@include max-screen(576px) {
							max-width: 100%; } } }

		&__item {
				margin-bottom: 30px; } }



.material-selection {

		&__title {
				margin-bottom: 15px;
				font-family: $ffs-m;
				font-size: 15px; }

		&__body {
				display: grid;
				grid-template-columns: repeat(5, 1fr);
				gap: 15px 12px;
				flex-wrap: wrap;

				@include max-screen(576px) {
					grid-template-columns: repeat(3, 1fr); } } }


.show-material {
		background: $c-dark-400;
		padding: 20px 35px;
		display: none;
		opacity: 1;
		-webkit-animation: display-none-transition 0.5s both;
		animation: display-none-transition 0.5s both;

		@include max-screen(576px) {
				padding: 15px; }

		@-webkit-keyframes display-none-transition {
				0% {
						opacity: 0; } }

		@keyframes display-none-transition {
				0% {
						opacity: 0; } }

		&--active {
				display: block; }

		&__img {
				background-image: url("/img/materialLoop.jpg");
				cursor: pointer;
				max-width: 150px;
				width: 100%;
				min-height: 150px;
				display: flex;
				justify-content: center;
				align-items: center;

				@include max-screen(576px) {
					max-width: 100px;
					height: 100px;
					min-height: 100px; }

				&:hover .show-material__icon {
						opacity: 1; } }

		&__icon {
				color: $c-dark-400;
				width: 17px;
				height: 17px;
				opacity: 0;
				transition: opacity .2s; }

		&__info {
				display: flex;
				gap: 25px;
				margin-bottom: 15px; }

		&__bottom {
				display: flex;
				justify-content: space-between;
				gap: 29px;

				@include max-screen(576px) {
					flex-direction: column;
					gap: 20px; } }

		&__price {
				display: flex;
				align-items: center;
				gap: 15px; }

		&__btn {
				padding: 15px 38px; } }



.material-description {
		display: flex;
		flex-direction: column;
		gap: 8px;

		&__title {
				font-size: 20px;
				color: $c-black;
				font-family: $ffs-m; }

		&__row {
				color: rgba(0, 0, 0, 0.5); }

		&__span {
				color: $c-black; } }


#modal-material {
		display: none;
		opacity: 1;
		-webkit-animation: display-none-transition 0.5s both;
		animation: display-none-transition 0.5s both;

		@-webkit-keyframes display-none-transition {
				0% {
						opacity: 0; } }

		@keyframes display-none-transition {
				0% {
						opacity: 0; } }
		position: fixed;
		z-index: 6;
		top: 0;
		right: 0; }


.modal-background {
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.2); }



.modal-show-color {

		&__body {
				width: 100%; }

		&__image {
				width: 100%; } }


.modal-show-color {
		padding: 45px 20px 20px; }




.modal-email {
		max-width: 640px;
		display: flex;
		justify-content: center;
		padding: 60px 80px;

		@include max-screen(576px) {
				display: flex;
				flex-direction: column;
				justify-content: center;
				min-height: 100vh;
				width: calc( 100% + 12px);
				padding: 70px 20px;
				transform: translateX(-6px);
				overflow: auto; }

		&__text {
			font-size: 16px; }

		&__input {
				max-width: 328px;
				margin: 20px auto; }

		&__btn {
				max-width: 328px;
				margin: 20px auto; }

		&__button {
				margin: 60px auto 0;

				@include max-screen(576px) {
						margin-top: 20px; } } }


.modal-sms {
		max-width: 640px;
		display: flex;
		justify-content: center;
		padding: 60px 80px;

		@include max-screen(576px) {
				display: flex;
				flex-direction: column;
				justify-content: center;
				min-height: 100vh;
				width: 100%;
				padding: 70px 20px;
				overflow: auto; }

		&__body {
				max-width: 328px;
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center; }

		&__error {
				color: #FF0000;
				font-size: 16px;
				text-align: center; }

		&__text {
				margin-bottom: 20px;
				font-size: 16px;
				max-width: 300px;
				line-height: 25px; }

		&__input {
				margin-bottom: 10px;
				max-width: 328px;
				width: 100%;
				cursor: auto; }

		&__number {
				display: block; }

		&__number,
		&__subtitle {
				font-size: 14px;
				line-height: 18px;
				color: $c-black;
				font-family: $ffs-m;
				text-align: center; }

		&__description {
				color: $c-dark-700;
				font-size: 13px;
				text-align: center;
				margin-bottom: 30px; }

		&__btn {
				margin: 0;
				display: contents;
				color: $c-blue-750; }

		&__btn-sms {
				margin: 10px auto 60px;

				@include max-screen(576px) {
						margin-bottom: 20px; }

				&--active {
						opacity: 1; } } }



.modal-redact {
		padding: 60px;
		max-width: 594px;
		width: 100%;

		@include max-screen(576px) {
				min-height: 100vh;
				padding: 60px 15px; }


		&__title {
				font-size: 20px;
				font-family: $ffs-m;
				margin-bottom: 20px;
				color: $c-black; }

		&__text-important {
			color: $c-red-900;
			font-size: 16px;
			line-height: 25px; }

		&__text {
				font-size: 16px;
				line-height: 25px;
				margin-bottom: 30px;
				color: $c-blue-800; }

		&__input {
				max-width: 220px;
				width: 100%;
				margin-bottom: 20px;

				@include max-screen(576px) {
						max-width: 100%; } } }


.upload {

		&__box {}

		&__inputfile {
				width: .1px;
				height: .1px;
				opacity: 0;
				overflow: hidden;
				position: absolute;
				z-index: -1; }

		&__icon {
				width: 19px;
				height: 19px; }


		&__btn {
				background: none;
				display: flex;
				cursor: pointer;
				align-items: center;
				gap: 13px;

				& p {
						margin: 0; }

				&:hover {
						background-color: unset;
						color: $c-blue-800;
						transition: all .3s ease; } }

		&__img {
				&-wrap {
						display: flex;
						gap: 10px;
						flex-wrap: wrap;
						margin-bottom: 20px;
						max-height: 180px;
						overflow: auto;
						padding-top: 10px; }

				&-box {
						width: 79px; }

				&-close {
						position: absolute;
						top: -10px;
						right: -10px;
						text-align: center;
						line-height: 24px;
						z-index: 1;
						cursor: pointer; } } }


.img-bg {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: relative;
		padding-bottom: 100%; }



.modal-personal-send {
		max-width: 640px;
		width: 100%;
		padding: 60px 108px;
		display: flex;
		justify-content: center;
		text-align: center;

		&__title {
				font-size: 20px;
				font-family: $ffs-m;
				margin-bottom: 20px;
				color: $c-black; }

		&__text {
				font-size: 16px;
				line-height: 25px;
				color: $c-black;
				margin-bottom: 60px; } }

.js-get-auth-phone,
.js-auth-get-email {
		& span {
				pointer-events: none; }
		& svg {
				pointer-events: none; } }
