@mixin fontFace($fontName) {
	@font-face {
		font-family: quote($fontName);
		src: url("../fonts/"+ $fontName +"/" + $fontName + ".eot");
		src: url("../fonts/"+ $fontName +"/" + $fontName + ".eot?#iefix") format("embedded-opentype"), url("../fonts/"+ $fontName +"/" + ".woff") format("woff"), url("../fonts/"+ $fontName +"/" + $fontName + ".ttf") format("truetype"), url("../fonts/"+ $fontName +"/" + $fontName + ".svg##{$fontName}") format("svg");
		font-weight: normal;
		font-style: normal; } }

@mixin fontFaceTtf($fontName) {
	@font-face {
		font-family: quote($fontName);
		src:  url("../fonts/"+ $fontName +"/" + $fontName + ".ttf") format("truetype");
		font-weight: normal;
		font-style: normal;
		font-display: fallback; } }

@mixin fontFaceOtf($fontName) {
	@font-face {
		font-family: quote($fontName);
		src:  url("../fonts/"+ $fontName +"/" + $fontName + ".otf") format("truetype");
		font-weight: normal;
		font-style: normal;
		font-display: fallback; } }

@mixin fontFaceWoff($fontName) {
	@font-face {
		font-family: quote($fontName);
		src:  url("../fonts/"+ $fontName +"/" + $fontName + ".woff") format("truetype");
		font-weight: normal;
		font-style: normal;
		font-display: fallback; } }





@include fontFaceOtf("TT Commons Pro Expanded Medium");

@include fontFaceOtf("TT Commons Pro Expanded Regular");


