.delivery-page {
		max-width: 1472px;
		padding-left: 16px;
		padding-right: 16px;

		& .container {
				padding: 0; }

		@include max-screen(1280px) {
				padding-left: 40px;
				padding-right: 40px; }

		@include max-screen(1024px) {
				padding-left: 32px;
				padding-right: 32px; }

		@include max-screen(976px) {
				padding-left: 15px;
				padding-right: 15px; }

		&__bread-crumbs {
				margin: 30px 0 40px; }

		&__title {
				text-align: center;
				margin-bottom: 57px; }

		&__content {
				display: flex;
				gap: 40px;
				justify-content: space-between;

				@include max-screen(1024px) {
					flex-direction: column;
					gap: 60px;
					margin-bottom: 60px; } }

		&__delivery-cards {
				max-width: 948px;
				width: 100%; }

		&__column {
				max-width: 456px;
				width: 100%; }

		&__cards-title {
				font-family: $ffs-m;
				font-size: 23px;
				margin-bottom: 27px; }

		&__card {
				margin-bottom: 40px; } }



.delivery-icon {
		display: flex;
		gap: 12px;
		font-size: 14px;

		&__icon {
				width: 19px;
				height: 19px;
				color: $c-blue-800; } }






.delivery-card {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		padding-bottom: 40px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);

		&:last-child {
				border-bottom: none;

				@include max-screen(1024px) {
						padding-bottom: 0;
						margin-bottom: 0; } }

		&__content {
				max-width: 543px;
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 13px; }

		&__text {
				//margin-top: 38px
				font-size: 18px;
				line-height: 31px;
				max-width: 543px;
				width: 100%;

				@include max-screen(1024px) {
					font-size: 16px; }

				@include max-screen(976px) {
					max-width: 400px; } }

		&__info {
				display: flex;
				justify-content: space-between;
				gap: 40px;
				margin-top: 38px;

				@include max-screen(576px) {
					flex-direction: column;
					margin-top: 30px; } }

		&__payment-methods {
			max-width: 353px;
			width: 100%; }

		&__pay {
				@include max-screen(976px) {
					max-width: 400px; } } }



.info-price {
		padding: 20px 48px 20px 20px;
		background: $c-dark-400;
		border-radius: 50px;
		@include flex-between;
		max-width: 455px;
		width: 100%;

		@include max-screen(976px) {
			max-width: 400px; }

		&__payment {
				color: $c-blue-800;
				max-width: 63px;
				width: 100%;
				text-align: left; } }



.article-list {
		max-width: 353px;

		&__title {
				font-size: 20px;
				font-family: $ffs-m;
				margin-bottom: 15px; }

		&__item {
				font-size: 18px;
				margin-left: 27px;
				list-style-type: disc;
				line-height: 31px;
				font-family: $ff-r; } }



.installment-card {
		background: $c-blue-900;
		padding: 55px 40px 50px 40px;
		height: fit-content;
		width: 58%;

		@include max-screen(1024px) {
				width: 100%;
				max-width: 370px; }

		@include max-screen(576px) {
				width: 100%; }

		&__text {
				font-size: 18px;
				line-height: 31px;
				margin-top: 30px;

				@include max-screen(1024px) {
						font-size: 16px; } } }
