.page-comparison {
		max-width: 1472px;
		padding-left: 16px;
		padding-right: 16px;

		& .container {
				padding: 0; }

		@include max-screen(1280px) {
				padding-left: 40px;
				padding-right: 40px; }

		@include max-screen(1024px) {
				padding-left: 32px;
				padding-right: 32px; }

		@include max-screen(976px) {
				padding-left: 15px;
				padding-right: 15px; }

		&__bread-crumbs {
				margin: 30px 0 60px; }

		&__title {
				margin-bottom: 60px;

				@include max-screen(976px) {
						margin-bottom: 30px; } }

		&__slider {
				margin: 0 60px 60px;
				position: relative;

				&-mobile-controll {
						position: relative;
						max-width: 110px;
						margin: 0 auto 37px; }

				&-mobile-prev {
						position: absolute;
						top: 50%;
						left: 0;
						transform: translateY(-50%);
						z-index: 2; }

				&-mobile-next {
						position: absolute;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
						z-index: 2; }

				@include max-screen(1280px) {
					margin: 0 0 60px;

					& .js-comparison-slider-btn-next {
							position: absolute;
							right: -25px;

							@include max-screen(976px) {
									right: -5px; } }

					& .js-comparison-slider-btn-prev {
							position: absolute;
							left: -25px;

							@include max-screen(976px) {
									left: -5px; } } }

				@include max-screen(576px) {
						display: none; } }

		&__sliders-mobile {
				display: none;
				grid-template-columns: repeat(2 ,1fr);
				gap: 16px;

				@include max-screen(576px) {
						display: grid; }

				& .swiper-pagination {
						left: 0;
						right: 0;
						bottom: -17px;
						display: flex;
						justify-content: center;
						gap: 5px;
						z-index: 1; } } }


.slider-fraction {
		display: flex;
		gap: 5px;
		justify-content: center;
		font-size: 14px;
		color: #808080; }



.comparison-card {
		//display: grid
		//grid-template-columns: repeat(5, 1fr)
 }		//gap: 20px
