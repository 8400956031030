.page-installment {
		max-width: 1472px;
		padding-left: 16px;
		padding-right: 16px;

		& .container {
				padding: 0; }

		@include max-screen(1280px) {
				padding-left: 40px;
				padding-right: 40px; }

		@include max-screen(1024px) {
				padding-left: 32px;
				padding-right: 32px; }

		@include max-screen(976px) {
				padding-left: 15px;
				padding-right: 15px; }

		&__bread-crumbs {
			margin: 30px 0 40px;

			@include max-screen(976px) {
					margin-bottom: 20px;
					padding-bottom: 10px; } }

		&__image {
			max-width: 1440px;
			width: 100%;
			object-fit: contain;
			margin-bottom: 60px; }

		&__img {
			width: 100%; }

		&__content {
			display: flex;
			gap: 36px;
			margin-bottom: 60px;

			@include max-screen(976px) {
					flex-direction: column; } }

		&__text-block {
			max-width: 948px;
			width: 100%; }

		&__partners {
				min-width: 280px; } }



.content-images {
		max-width: 280px;
		width: 100%; }


.partners-img {

		&__title {
				font-family: $ffs-m;
				color: $c-black;
				font-size: 25px;
				margin-bottom: 23px; } }
