.children-furniture-links {
		background: $c-dark-300;
		max-width: 392px;
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: 145px 41px 30px 41px;

		@include max-screen(1280px) {
			max-width: 100%;
			width: 50%; }

		@include max-screen(1024px) {
				padding: 85px 50px; }

		@include max-screen(576px) {
				padding: 30px 10px; }

		&__list {
				display: flex;
				flex-direction: column;
				gap: 37px;

				@include max-screen(576px) {
					gap: 30px; } }

		&__title {
				margin-bottom: 37px;
				max-width: 310px;
				font-family: $ffs-m;
				font-size: 30px;
				color: $c-black;

				@include max-screen(976px) {
					max-width: 200px;
					margin-bottom: 30px;
					font-size: 20px; }

				@include max-screen(576px) {
					font-size: 15px; } }

		&__link {
				color: $c-blue-800;
				text-decoration: underline; } }
