.page-guarantee {
		max-width: 1472px;
		padding-left: 16px;
		padding-right: 16px;

		& .container {
				padding: 0; }

		@include max-screen(1280px) {
				padding-left: 40px;
				padding-right: 40px; }

		@include max-screen(1024px) {
				padding-left: 32px;
				padding-right: 32px; }

		@include max-screen(976px) {
				padding-left: 15px;
				padding-right: 15px; }

		&__bread-crumbs {
				margin: 30px 0 40px; }

		&__content {
				margin-bottom: 60px; } }



