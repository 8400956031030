.reviews-grade {
		font-size: 40px;
		font-family: $ffs-m;
		max-width: 278px;
		width: 100%;

		&__stars {
				display: flex;
				flex-direction: column;
				gap: 11px;
				margin-bottom: 27px; }

		&__reviews-text {
				font-size: 14px; }

		&__description {
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				margin-bottom: 36px; }

		&__total {
				font-family: $ffs-m;
				text-align: center;
				margin-bottom: 10px; }

		&__button {
				font-size: 14px;
				text-align: center; } }



.item-rating {
		display: flex;
		align-items: center;
		gap: 10px;

		&__grade {
				font-size: 14px;
				margin-top: 1px;
				max-width: 12px;
				width: 100%; }

		&__icon {
				width: 18px;
				height: 18px; }

		&__line {
				width: 100%;
				height: 5px;
				background: #F6F5F5;
				position: relative;
				border-radius: 50px; }

		&__progress {
				position: absolute;
				top: 0;
				left: 0;
				height: 5px;
				//width: 34%
				background: $c-blue-800;
				border-radius: 50px; }

		&__icon {
				color: $c-yellow; } }
