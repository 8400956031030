.children-article {
		position: relative;

		&__img {
				max-width: 478px;
				width: 100%;
				object-fit: contain;

				.img {
						width: 100%; } }

		&__text {
				left: 43px;
				bottom: 24px; } }
