.product-delivery {
		padding: 20px;
		border: 1px solid rgba(0, 0, 0, 0.1);

		&__link {
				color: $c-black; }

		&__title {
				font-size: 16px;
				font-family: $ffs-m;
				//margin-top: 15px

				&--bottom {
						margin-top: 15px;

						@include max-screen(576px) {
								margin-top: 10px; } } }

		&__text {
				font-size: 14px;
				margin-top: 4px;

				&:not(:last-child):after {
						content: url("/img/icons/ball.svg");
						margin-left: 10px; } }

		&__span {
				text-decoration: underline; } }
