.children-articles-links {
		max-width: 480px;
		width: 100%;
		display: flex;
		flex-direction: column;

		@include max-screen(1280px) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				max-width: 100%; }

		@include max-screen(576px) {
			grid-template-columns: 1fr; }

		&__link-main {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 10;

				&:hover ~ .article-card__content .article-card__link {
						background: #F6F5F5;
						color: #24306B; } }

		&__item {
				@include max-screen(1024px) {
						& .article-card__content {
								justify-content: flex-start;

								@include max-screen(576px) {
										justify-content: center; } } } } }
