.custom-select {
	//max-width: 242px
	position: relative;

	&__img {
			display: none; }

	&__option {
			display: flex;
			justify-content: space-between;
			padding: 0 25px 0 0;
			cursor: pointer;

			//&:hover
			//	background: #D3D9EA
			//
			//	.custom-select__label
 }			//			background: #D3D9EA

	&--open {
		//border: 1px solid $c-blue-800
		//border-radius: 50px

		.custom-select__header {
				border-radius: 27px 27px 0 0;
				box-shadow: 0px 10px 31px rgba(0, 0, 0, 0.12);
				border: 1px solid $c-blue-800; }

		.custom-select {

			&__arrow {

				.icon {
					transform: rotate(180deg); } }

			&__body {
				//box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.21)
				transform: translateY(0px);
				opacity: 1;
				pointer-events: auto; } } }

	&__header {
		min-width: 155px;
		border-radius: 27px;
		background: #ffffff;
		//border: none
		cursor: pointer;
		outline: none;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 12px 15px;
		//min-height: 55px
		width: 100%;
		transition: background, border ease .2s;
		border: 1px solid $c-dark-450;

		&:hover {
				border: 1px solid $c-blue-800; }

		&--close {
				border-radius: 27px 27px 0px 0px; }

		&--big {
				padding: 19px 25px; }

		&--min {
				padding: 10px 15px; } }

	&__body {
		position: absolute;
		top: 53px;
		left: 0;
		right: 0;
		background: #fff;
		transition: box-shadow ease .3s, transform ease .3s, opacity ease .3s;
		z-index: 10;
		transform: translateY(15px);
		opacity: 0;
		pointer-events: none;
		overflow: hidden;
		overflow-y: auto;
		border-top: 1px solid transparent;
		border: 1px solid $c-blue-800;
		border-radius: 0px 0px 27px 27px;

		&--min {
				top: 35px;
				//border-top: transparent
				box-shadow: 0px 10px 31px rgba(0, 0, 0, 0.12);
				border: transparent; } }

	&__selected {
		line-height: 1;
		font-size: 14px;
		color: #000000;
		text-align: left;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&--double-text {
				padding-top: 15px; } }

	&__arrow {
		color: #000000;
		margin-left: 27px;
		display: flex;
		align-items: center;
		justify-content: flex-end; }

	.icon {
		font-size: 11px !important;
		transition: transform ease .3s; }

	&__label {
		padding: 12px 7px 15px 15px;
		font-size: 14px;
		line-height: 16px;
		color: #000000;
		background: #fff;
		transition: background ease .3s;
		cursor: pointer;
		display: block;
		user-select: none;

		&:hover {
			color: #3B4FB0; } }

	[type="checkbox"],
	[type="radio"] {
		display: none;

		&:checked {
			~ {
				.custom-select__img {
					display: block; }

				.custom-select__label {
					color: #3B4FB0; } } } }

	&__svg {
			width: 9px;
			height: 9px;
			color: #24306B; }

	&__description {
			font-family: $ff-r;
			font-size: 13px;
			line-height: 16px;
			color: $c-black;
			opacity: 0.5;
			position: absolute;
			top: 10px; } }
