.catalogue-page {

	&.container--min {
		padding-left: 16px;
		padding-right: 16px; }

	&__bread-crumbs {
		margin: 30px 0 60px; }

	&__wrapper {
			display: flex;
			justify-content: space-between;
			gap: 60px;

			@include max-screen(1280px) {
				gap: 30px; } }

	&__filter {
			max-width: 236px;

			@include max-screen(976px) {
					position: fixed;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					width: 100vw;
					max-width: 100vw;
					padding: 24px 15px;
					overflow: auto;
					z-index: 100;
					background-color: #fff;
					transform: translateX(100%);
					transition: transform .3s linear;

					&--active {
							transform: translateX(0); }

					& .select-section__buttons {
							position: sticky;
							bottom: 0; } } }

	&__content {
			width: calc(100% - 291px);

			@include max-screen(1280px) {
					width: calc(100% - 261px); }

			@include max-screen(976px) {
					width: 100%; } }

	&__questions {
			margin-bottom: 60px; }

	&__description {
		margin-bottom: 60px; } }



.filter-column {

		&__title {
				display: none;

				@include max-screen(976px) {
						display: block;
						margin-bottom: 20px;
						font-size: 16px;
						font-family: $ffs-m;
						text-align: center; } }

		& .btn-reset {
			@include max-screen(976px) {
					position: fixed;
					top: 20px;
					left: 15px;
					padding: 0;

					& svg {
							display: none; } } }

		&__close {
				display: none;

				@include max-screen(976px) {
						display: block;
						position: absolute;
						top: 20px;
						right: 15px;
						padding: 0; } }

		&__select-section {
				margin-bottom: 30px;

				@include max-screen(976px) {
						margin-bottom: 0; } } }


.select-section {
		display: flex;
		flex-direction: column;
		gap: 30px;

		@include max-screen(976px) {
			gap: 0; }

		& .smart-filter-parameters-box {
				@include max-screen(976px) {
					max-width: 330px; } }

		& .custom-select {
				@include max-screen(976px) {
					max-width: 330px; } }

		&__select {

				@include max-screen(976px) {
						padding-top: 40px;
						padding-bottom: 40px;
						border-top: 1px solid #EDEDED;

						&:first-child {
								padding-top: 25px; } } }

		&__title {
				font-size: 14px;
				font-family: $ffs-m;
				margin-bottom: 15px; }

		&__buttons {
				display: flex;
				flex-direction: column;
				gap: 25px; } }




.select-form {
		display: flex;
		flex-wrap: wrap;
		gap: 7px 0; }




.catalogue-title {
		display: flex;
		align-items: end;
		gap: 15px;

		&__subtitle {
				font-size: 40px;
				color: $c-black;
				font-family: $ffs-m;

				@include max-screen(976px) {
					font-size: 30px; }

				@include max-screen(576px) {
					font-size: 28px; } }

		&__text {
				font-size: 16px;
				color: $c-black;
				opacity: 0.5;
				padding-bottom: 5px; } }



.catalogue-content {

		&__filter-dropdown {
				display: none;

				@include max-screen(976px) {
						display: block; }

				&-btn {
						padding: 0;

						&:focus {
								box-shadow: none; } }

				&-icon {
						display: none; }

				& .dropdown-item--active + .catalogue-content__filter-dropdown-icon {
						display: block; }

				&-menu {
						width: 240px;
						flex-direction: column;
						padding: 20px;
						gap: 20px;
						border: 1px solid #F0F0F0;
						box-shadow: 0 10px 31px rgba(0, 0, 0, 0.12);
						border-radius: 27px;

						& li {
								display: flex;
								justify-content: space-between;
								align-items: center; }

						&.show {
								display: flex; }

						& .dropdown-item {
								padding: 0; } }

				&-title {
						font-size: 16px;
						font-weight: 700; } }

		&__title {
				margin-bottom: 30px;

				@include max-screen(976px) {
						margin-bottom: 25px; } }

		&__filter {
				display: flex;
				align-items: flex-start;
				flex-wrap: wrap;
				gap: 10px 0;
				margin-bottom: 30px; }

		&__filter-wrapp {
				display: flex;
				justify-content: space-between;
				align-items: center;

				@include max-screen(976px) {
					margin-bottom: 45px; } }

		&__open-filter {
				display: none;
				padding: 0;

				@include max-screen(976px) {
						display: block; } }

		&__sort {
				display: flex;
				justify-content: flex-start;
				gap: 23px;
				margin-bottom: 30px;

				@include max-screen(976px) {
						display: none; } }

		&__cards {
				margin-bottom: 40px; }

		&__btn-show {
				margin-bottom: 76px;

				@include max-screen(976px) {
						margin-bottom: 60px; } }

		&__pagination {
				justify-content: flex-start;
				margin-bottom: 60px; } }



.filter-item {
		padding: 10px 15px;
		border-radius: 50px;
		font-size: 14px;
		display: inline-block;
		margin: 0 5px 0 0;
		user-select: none;
		background: $c-dark-400; }



.item-card {
		height: 100%;
		max-width: 100%;
		opacity: 1;
		-webkit-animation: display-none-transition 1s both;
		animation: display-none-transition 1s both;

		@-webkit-keyframes display-none-transition {
				0% {
						opacity: 0; } }

		@keyframes display-none-transition {
				0% {
						opacity: 0; } }


		&--hide {
				display: none; }

		&__border {
				position: relative;
				width: calc(100% + 20px);
				height: 1px;
				margin-top: auto;
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				margin-bottom: 15px;

				@include max-screen(576px) {
						width: 100%; } }

		&__characteristics {
				position: relative;
				display: flex;
				flex-direction: column;
				min-height: 100px;
				margin-bottom: 15px;

				@include max-screen(576px) {
					min-height: 95px; } }

		&__comparison-title {
				//height: 30px
				font-size: 12px;
				color: $c-dark-700;
				margin-bottom: 10px;
				text-transform: uppercase; }

		&__comparison-text {
				position: relative;
 }				//height: 40px

		&__price {
				display: flex;
				align-items: center;
				min-height: 31px;
				gap: 15px;
				margin-bottom: 10px;

				@include max-screen(1280px) {
					flex-direction: column;
					align-items: flex-start;
					gap: 5px; } }

		&__slider {
				margin-bottom: 20px;

				@include max-screen(576px) {
						margin-bottom: 18px; } }

		&__title {
				margin-bottom: auto;

				@include max-screen(576px) {
					font-size: 15px; }

				&--wide {
						white-space: nowrap;
						text-overflow: ellipsis;
						margin-bottom: 10px;
						overflow: hidden; } }

		&__rating {

				&--wide {
						margin-bottom: 30px; } }

		&__advantages {
				gap: 7px;
				margin-bottom: 27px; }

		&__slider-size {
				max-width: 100%;
				overflow: hidden;
				margin-bottom: 13px; }

		&__img {
				position: relative;
				max-width: 270px;
				width: 100%;
				height: 162px;
				object-fit: contain;

				@include max-screen(1280px) {
						height: 130px; }

				@include max-screen(576px) {
						height: 95px; }

				& img {
						width: 100%;
						height: 100%;
						object-fit: cover; } }

		&__image {
				width: 100%;
				height: 100%; } }



.item-slider {

		&__pagination {
				position: absolute;
				bottom: 5px !important; }

		&__item {
				position: relative;

				&--wide {
						margin-bottom: 24px;
						display: flex;

						@include max-screen(576px) {
								margin-bottom: 20px; } } }

		&__new {
				position: absolute;
				display: flex;
				gap: 8px;
				top: 0;

				@include max-screen(976px) {
						left: 1px; } }

		&__img {
				width: 100%;
				height: 160px;

				@include max-screen(1280px) {
						height: 130px; }

				@include max-screen(976px) {
						height: 140px; }

				@include max-screen(576px) {
						height: 100px; } }

		& img {
				width: 100%;
				height: 100%;
				object-fit: cover; } }



.size-slider {

		&__item {
				color: rgba(0, 0, 0, 0.5);
				font-size: 14px;
				padding: 0;

				&:focus {
						box-shadow: none; }

				&--active {
						color: $c-black;
						text-decoration: underline; } } }



.catalogue-cards {
		display: flex;
		flex-wrap: wrap;
		gap: 50px 22px;

		@include max-screen(576px) {
			gap: 40px 15px; }

		& .catalogue-content__card {
				width: calc(25% - 17px);

				@include max-screen(1024px) {
						width: calc(33.33% - 15px); }

				@include max-screen(576px) {
						width: calc(50% - 8px);

						&:nth-child(2n) {
								order: 1; }

						&:nth-child(n+3) {
								order: 3; } } }

		&__link {
				width: calc(25% - 17px);

				@include max-screen(1024px) {
						width: calc(33.33% - 15px);
						padding: 16px;

						& .sofa-article-link__subtitle {
								font-size: 16px; } }

				@include max-screen(576px) {
						width: 100%;
						order: 2;
						padding: 40px 20px; } } }



.company-description {

		&__title {
				font-family: $ffs-m;
				font-size: 30px;
				color: $c-black;
				opacity: 0.5;
				margin-bottom: 20px; }

		&__text {
				font-size: 16px;
				color: $c-black;
				opacity: 0.5; } }


.product-item {
		display: flex;
		flex-direction: column;
		height: 100%; }
