.sleeping-top {
		display: flex;
		justify-content: space-between;

		@include max-screen(976px) {
			flex-direction: column;
			align-items: flex-start; }

		&__title {
				font-size: 35px;
				line-height: 44px;
				font-family: $ffs-m;
				color: $c-black;
				margin-bottom: 8px;

				@include max-screen(976px) {
					font-size: 30px;
					line-height: 37.5px; }

				@include max-screen(576px) {
					font-size: 28px; } }

		&__description {
				font-size: 16px;
				line-height: 20px;
				color: $c-black; }

		&__btn {
				margin-top: 20px; } }
