.review-like {
		max-width: 221px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center; }



.like {
		display: flex;
		border: none;
		cursor: pointer;
		max-width: 74px;
		width: 100%;
		background: #F6F5F5;
		border-radius: 50px;
		padding: 12px 17px;
		justify-content: space-between;

		&__icon {
				width: 20px;
				height: 20px;
				color: $c-blue-800; } }
