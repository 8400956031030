.order-formed {
		max-width: 440px;
		width: 100%;
		margin: 0 auto;

		&--wide {
				max-width: 524px; }

		&__title {
				text-align: center;
				margin-top: 60px;
				margin-bottom: 20px; }

		&__link {
				color: $c-blue-800; }

		&__span {
				font-family: $ffs-m; }

		&__number {
				font-size: 16px;
				font-family: $ffs-m;
				text-align: center;
				margin-bottom: 20px; }

		&__text {
				font-size: 16px;
				text-align: center;
				margin-bottom: 60px; }

		&__buttons {
				display: flex;
				gap: 10px;
				justify-content: center;
				margin-bottom: 365px; }

		&__button {
				max-width: 172px;
				width: 100%;
				padding: 18px 0px; } }

