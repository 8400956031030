.product-row {
		display: flex;
		gap: 5px;
		justify-content: flex-end;
		align-items: center;

		@include max-screen(576px) {
			justify-content: flex-start; }

		&__reviews {
				margin-right: 20px;
				font-size: 14px; }

		&__text {
				font-size: 14px; } }
