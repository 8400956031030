.page-favorites {
		max-width: 1472px;
		padding-left: 16px;
		padding-right: 16px;

		@include max-screen(1280px) {
				padding-left: 40px;
				padding-right: 40px; }

		@include max-screen(1024px) {
				padding-left: 32px;
				padding-right: 32px; }

		@include max-screen(976px) {
				padding-left: 15px;
				padding-right: 15px; }

		&__title {
				margin-bottom: 60px;

				@include max-screen(976px) {
						margin-bottom: 30px; } }

		&__cards {
				gap: 30px 20px;
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 60px;

				@include max-screen(976px) {
					gap: 30px 15px; } }

		&__card {
				width: calc(20% - 16px);

				@include max-screen(1024px) {
						width: calc(25% - 15px); }

				@include max-screen(976px) {
						width: calc(33% - 8px); }

				@include max-screen(576px) {
						width: calc(50% - 8px); } }

		&__bread-crumbs {
				margin: 30px 0 60px; } }
