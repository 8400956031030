.body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh; }



.page {
	flex: 1;

	&--background {
			background: $c-blue-900; } }
