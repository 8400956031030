.common-slider {
		max-width: 1490px;
		width: 100%;
		margin: 0 auto;
		overflow: hidden;
		position: relative;

		@include max-screen(976px) {
				width: calc(100% + 15px); }

		&__title {
				font-size: 35px;
				line-height: 44px;
				display: flex;
				justify-content: center;
				margin: 0 auto 19px;
				font-family: $ffs-m;

				@include max-screen(1024px) {
					font-size: 30px;
					line-height: 37.5px; }

				@include max-screen(576px) {
					font-size: 28px; } }

		&__container {
				max-width: 1440px;
				width: 100%;
				margin: 0 auto;
				overflow: hidden; }

		&__btn {
				width: 50px;
				height: 50px;
				border-radius: 50px;
				border: none;
				background: #F6F5F5;
				opacity: 0.8;
				display: flex;
				justify-content: center;
				align-items: center;

				@include max-screen(976px) {
						display: none; }

				&--prev {
						position: absolute;
						top: 162px;
						left: 0;
						z-index: 1;
						cursor: pointer; }


				&--next {
						position: absolute;
						top: 162px;
						right: 5px;
						z-index: 1;
						cursor: pointer; } }

		.swiper-button-disabled {
				opacity: 0.2; }

		&__btn-icon {
				width: 17px;
				height: 14px;

				&--right {
						margin-left: 4px; } } }



.check-favorite {
		margin-bottom: 5px;
		width: 40px;
		height: 40px;
		border-radius: 50px;
		margin-left: auto;
		display: flex;
		align-items: center;
		border: none;
		background: $c-white;
		cursor: pointer;

		&:hover .check-favorite__icon {
				color: $c-blue-800; }

		&--active {
				background: #24306B;

				&:hover .check-favorite__icon {
					color: $c-white; } }


		&__icon {
				width: 16px;
				margin: 0 auto;
				display: flex;
				color: rgba(0, 0, 0, 0.5);
				transition: .3s;

				&--active {
						color: $c-dark-400; } } }


.common-slide {

		&__item {
				text-decoration: none;
				color: $c-black;
				max-width: 270px; }

		&__img {
				margin-bottom: 24px;
				max-width: 270px;
				height: 162px;
				width: 100%;
				object-fit: contain; }

		&__img .img {
				width: 100%;
				height: 100%;
				object-fit: cover; }

		&__price {
				margin-bottom: 16px;
				font-family: $ffs-m;
				font-size: 18px;
				display: flex;
				padding: 4px;

				&--discount {
						padding: 0px;
						gap: 11px; } } }


.main-price {
		width: max-content;
		font-family: $ffs-m;
		font-size: 18px;
		padding: 4px;

		&--background {
				background: $c-yellow-600; } }



.old-price {
		padding: 4px;
		text-decoration: line-through; }
