.blog-card {

		&__title {
				margin-bottom: 30px; }

		&__links {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				gap: 36px 30px;
				margin-bottom: 60px;

				& .article-card:nth-child(n+5) {
						display: none !important; }

				& .blog-card__btn {
						display: none; }

				@include max-screen(1280px) {
					gap: 30px 24px; }

				@include max-screen(1024px) {
					grid-template-columns: repeat(3, 1fr);

					& .article-card:nth-child(n+4) {
						display: none !important; } }

				@include max-screen(976px) {
					gap: 20px; }

				@include max-screen(576px) {
					grid-template-columns: 1fr;
					gap: 40px; } }

		&__bread-crumbs {
				margin: 30px 0 60px; }

		&__btn {
				width: 100%; } }



.article-description {
		display: flex;
		gap: 70px;
		margin-bottom: 60px;

		@include max-screen(976px) {
			flex-direction: column;
			gap: 30px; }

		&__img {
				max-width: 575px;
				width: 100%;

				@include max-screen(1280px) {
					max-width: 475px; }

				@include max-screen(1024px) {
					max-width: 100%;
					max-height: 400px; }

				.img {
						width: 100%;
						object-fit: cover;

						@include max-screen(1024px) {
								height: 400px; }

						@include max-screen(576px) {
								height: 270px; } } } }


.text-article {

		&__link {
				display: inline-block;
				margin-bottom: 10px; }

		&__title {
				text-align: left;
				margin-bottom: 30px;

				@include max-screen(1024px) {
						margin-bottom: 20px; } }

		&__text {
				font-size: 18px;
				line-height: 34px;
				margin-bottom: 30px;

				@include max-screen(1024px) {
					font-size: 16px;
					line-height: 24px; } } }


.text-article {
		max-width: 794px;

		@include max-screen(1024px) {
			max-width: 460px; }

		@include max-screen(976px) {
			max-width: 100%; } }



.social-share {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 250px;

		&__text {
				padding-right: 10px; } }

