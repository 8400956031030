.children-link {
		position: relative;
		max-width: 570px;
		width: 100%;
		min-height: 603px;
		padding: 37px 42px;
		display: flex;
		align-items: end;

		@include max-screen(1280px) {
			max-width: 100%;
			width: 50%; }

		@include max-screen(1024px) {
				min-height: 470px; }

		@include max-screen(976px) {
			min-height: 370px; }

		@include max-screen(576px) {
			min-height: 300px;
			padding: 10px 9px; }


		&__link-main {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 10;

				&:hover ~ .children-link__link {
						background: #24306B;
						color: #fff; } }


		&__link {
				position: relative;
				max-width: max-content;
				width: 100%;
				font-size: 14px;
				z-index: 2;
				padding: 12px 69px;

				@include max-screen(576px) {
						padding: 10px;
						width: 100%;
						max-width: 100%; } }

		&__img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				display: flex;
				object-fit: contain;

				.img {
						width: 100%;
						object-fit: cover; } } }
