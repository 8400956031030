.bedding-item {
		position: relative;
		width: 100%;
		min-height: 360px;
		padding: 30px 25px;
		display: flex;
		align-items: end;

		&__button {
				display: inline-block;
				position: relative;
				max-width: 220px;
				width: 100%;
				font-weight: 500;
				font-size: 14px;
				line-height: 18px;
				z-index: 2;
				padding: 12px; }

		&__img {
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				display: flex;
				position: absolute; }

		&__img .img {
				width: 100%;
				object-fit: cover; }

		&__link {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 2;

				&:hover + .bedding-item__button {
						background: #24306B;
						color: #fff; } } }
