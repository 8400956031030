.faq-page {
		max-width: 1472px;
		padding-left: 16px;
		padding-right: 16px;

		& .container {
				padding: 0; }

		@include max-screen(1280px) {
				padding-left: 40px;
				padding-right: 40px; }

		@include max-screen(1024px) {
				padding-left: 32px;
				padding-right: 32px; }

		@include max-screen(976px) {
				padding-left: 15px;
				padding-right: 15px; }

		&__bread-crumbs {
				margin: 30px 0 40px; }

		&__title {
				text-align: center;
				margin-bottom: 40px; }

		&__accordion {
				margin: 0 auto; } }



.accordion {
		margin-bottom: 50px;
		max-width: 948px;
		width: 100%;

		&__icon {
				width: 14px;
				height: 14px;
				color: $c-blue-800;
				transition: .4s;

				&--active {
						transform: rotate(180deg); } }

		&__title {
				color: $c-blue-800;
				font-size: 18px;
				font-family: $ffs-m;

				@include max-screen(576px) {
					font-size: 16px; } }

		&__button {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				padding: 30px 10px;
				cursor: pointer;
				border-bottom: 1px solid rgba(0, 0, 0, 0.2);

				&--border {
						border-bottom: 1px solid transparent; } }

		&__body {
				display: none;
				padding: 0px 10px 30px 10px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.2);
				font-size: 18px;
				line-height: 31px;
				opacity: 1;
				-webkit-animation: display-none-transition 1s both;
				animation: display-none-transition 1s both;

				@include max-screen(576px) {
					font-size: 16px;
					line-height: 22px; }

				@-webkit-keyframes display-none-transition {
						0% {
								opacity: 0; } }

				@keyframes display-none-transition {
						0% {
								opacity: 0; } }

				&.active {
						display: block;
						overflow: initial; } } }
