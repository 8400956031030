.page-offer {

		&__bread-crumbs {
				margin: 30px 0 60px; }

		&__title {
				margin-bottom: 40px; }

		&__cards {
				display: flex;
				flex-wrap: wrap;
				gap: 50px 22px;
				margin-bottom: 60px;

				& [data-entity="items-row"] {
						width: calc(20% - 18px);

						@include max-screen(1024px) {
								width: calc(25% - 17px); }

						@include max-screen(976px) {
								width: calc(25% - 12px); }

						@include max-screen(576px) {
								width: calc(50% - 8px); } }

				@include max-screen(976px) {
						gap: 40px 15px; } } }


