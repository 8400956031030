body {
	margin: 0;
	padding: 0;
	font-family: $ff;
	line-height: 1.3;
	font-size: 15px;
	color: #000000;
	overflow-x: hidden;

	* {
		box-sizing: border-box;
		font-family: $ff; } }



.body--overlay {
		@include overlay(#333); }

.body--no-scroll {
		overflow: hidden; }

.body--fix {
	position: fixed;
	left: 0;
	right: 0; }


.container {
		max-width: 1748px;
		width: 100%;
		margin: 0 auto;
		overflow: hidden;

		@include max-screen(1280px) {
				padding-left: 40px;
				padding-right: 40px; }

		@include max-screen(1024px) {
				padding-left: 32px;
				padding-right: 32px; }

		@include max-screen(768px) {
				padding-left: 15px;
				padding-right: 15px; }

		&--min {
				max-width: 1440px;
				width: 100%;
				margin: 0 auto;
				padding-left: 0;
				padding-right: 0; }

		&--narrow {
				max-width: 947px;
				width: 100%;
				margin: 0 auto; }

		&--middle {
				max-width: 1560px;
				width: 100%;
				margin: 0 auto; } }

ul,
li {
		list-style-type: none;
		margin: 0;
		padding: 0; }


.page {
		flex: 1;
		position: relative;

		&:before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
				pointer-events: none;
				transition: background-color ease .2s; }


		&--hover:before {
				background-color: rgba(34,34,34,.3); } }



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
		-webkit-appearance: none; }

button {
		margin: 0;
		padding: 0; }

svg {
	pointer-events: none; }



input[type='checkbox'] {
		cursor: pointer; }


a {
		text-decoration: none;
		color: $c-black; }


.select-city__dropdown-wrap {
		top: 40px; }


.js-comparison-slider {
		width: calc(100% - 5px); }


.change-point-issue {
		max-width: 300px;
		margin-bottom: 20px; }

button svg,
a svg {
		pointer-events: none; }

.select-city__close {
		top: 20px;
		right: 20px; }



.popup-window-close-icon:after {
		width: 15px;
		height: 15px; }


.input-group--one-third.form-group.bx-soa-customer-field {
		& .input-group__error {
				display: none; }

		&.input-group--error .input-group__error {
				display: block; } }



.select-city__close {
		@include max-screen(576px) {
				width: 10px;
				height: 10px; } }
