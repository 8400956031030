.page-404 {
		max-width: 1472px;
		padding-left: 16px;
		padding-right: 16px;

		& .container {
				padding: 0; }

		@include max-screen(1280px) {
				padding-left: 40px;
				padding-right: 40px; }

		@include max-screen(1024px) {
				padding-left: 32px;
				padding-right: 32px; }

		@include max-screen(976px) {
				padding-left: 15px;
				padding-right: 15px; }

		&__title {
				font-size: 100px;
				color: $c-blue-800;
				font-family: $ffs-m;
				text-align: center;
				margin-top: 173px;

				@include max-screen(576px) {
						margin-top: 30px; } }

		&__subtitle {
				font-size: 18px;
				color: $c-black;
				font-family: $ffs-m;
				text-align: center;
				margin-bottom: 51px; }

		&__text {
				max-width: 755px;
				text-align: center;
				margin: 0 auto 36px;
				font-size: 18px;
				line-height: 31px; }

		&__button {
				width: fit-content;
				display: flex;
				padding: 15px 97px;
				margin: 0 auto 172px;

				@include max-screen(576px) {
						margin-bottom: 60px; } } }
