.product-review {
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);

		&__name {
				margin-bottom: 20px; }

		&__text {
				margin-bottom: 16px; }

		&__like {
				margin-bottom: 30px; } }



.review-top {
		width: 100%;
		display: flex;
		align-items: center;

		&__text,
		&__name {
				font-size: 16px;
				color: $c-black; }

		&__name {
				margin-right: 15px; }

		&__text {
				margin-right: 30px; }

		&__date {
				font-size: 16px;
				color: $c-blue-680; } }
