.input-group {

	&__error {
		color: #FC6464;
		font-size: 12px; }

	&--error {

		.input {
			background: #FFE1E1; }

		.input-group__error {
			padding-top: 10px; } } }



.input {
	&::-webkit-input-placeholder {
		color: rgba(140, 140, 140, 0.5);
		transition: color ease .3s; }

	&:-ms-input-placeholder {
		color: rgba(140, 140, 140, 0.5);
		transition: color ease .3s; }

	&::-moz-placeholder {
		color: rgba(140, 140, 140, 0.5);
		transition: color ease .3s; }

	&:-moz-placeholder {
		color: rgba(140, 140, 140, 0.5);
		transition: color ease .3s; } }



input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 32px #E7EAF4 inset !important;
	color: #8C8C8C; }



.input {
	background: #E7EAF4;
	color: #000000;
	padding: 19px 15px;
	min-height: 55px;
	border: none;
	box-shadow: none;
	outline: none;
	font-size: 14px;
	width: 100%;
	transition: background ease .3s;

	&:hover:not(:disabled) {
		background: #D3D9EA;

		&::-webkit-input-placeholder {
			color: #8C8C8C; }

		&:-ms-input-placeholder {
			color: #8C8C8C; }

		&::-moz-placeholder {
			color: #8C8C8C; }

		&:-moz-placeholder {
			color: #8C8C8C; } }

	&:focus:not(:disabled) {
		background: #D3D9EA;

		&::-webkit-input-placeholder {
			color: #8C8C8C; }

		&:-ms-input-placeholder {
			color: #8C8C8C; }

		&::-moz-placeholder {
			color: #8C8C8C; }

		&:-moz-placeholder {
			color: #8C8C8C; } }

	&:disabled {
		cursor: not-allowed;
		background: #F6F7F9 !important;
		border: 1px solid rgba(0, 0, 0, 0.1); } }



.checkbox {
	display: inline-flex;
	align-items: center;
	cursor: pointer;

	[type="checkbox"] {
		display: none;

		&:checked {
			~ {
				.checkbox__indicator {
					background: #000000;

					.icon {
						transform: scale(1); } } } }

		&:disabled {

			~ {
				.checkbox__indicator {
					cursor: not-allowed;
					background: #F6F7F9 !important;
					border: 1px solid rgba(0, 0, 0, 0.1); }

				.checkbox__description {
					color: rgba(0, 0, 0, 0.6);
					cursor: not-allowed; } } } }

	&__indicator {
		display: block;
		height: 19px;
		width: 19px;
		min-width: 19px;
		border: 1px solid #000000;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		overflow: hidden;
		transition: background ease .3s;

		.icon {
			transition: transform ease .3s;
			transform: scale(0);
			color: #ffffff !important;
			font-size: 7px; } }

	&__description {
		font-size: 12px;
		color: #000000;
		padding: 0 14px;
		flex: 1;
		user-select: none; } }



.radio {
	display: inline-flex;
	align-items: center;
	cursor: pointer;

	&--text {
			align-items: flex-start; }

	&__title {
			font-size: 16px; }

	&__text {
			opacity: 0.5;
			font-size: 13px; }


	[type="radio"],[type="checkbox"] {
		display: none;

		&:checked {
			~ {
				.radio__indicator:after {
					top: 5px;
					bottom: 5px;
					left: 5px;
					right: 5px; } } }

		&:disabled {
			~ {
				.radio__indicator {
					cursor: not-allowed;
					border: 1px solid rgba(0, 0, 0, 0.1) !important;
					background: rgba(0, 0, 0, 0.1);

					&:after {
						background: #F6F7F9 !important; } }

				.radio__description {
					color: rgba(0, 0, 0, 0.6);
					cursor: not-allowed; } } } }

	&__indicator {
		background: #000000;
		display: flex;
		height: 19px;
		width: 19px;
		min-width: 19px;
		border: 1px solid #000000;
		border-radius: 50%;
		overflow: hidden;
		position: relative;

		&:after {
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			border-radius: 50%;
			content: "";
			background: #ffffff;
			display: block;
			position: absolute;
			transition: top ease .3s, bottom ease .3s, left ease .3s, right ease .3s, } }

	&__description {
		display: flex;
		flex-direction: column;
		font-size: 12px;
		color: #000000;
		padding: 0 14px;
		flex: 1;
		user-select: none; } }



.textarea {
	min-height: 110px;
	min-width: 100%;
	max-width: 100%;
	width: 100% !important; }



.form-default {

	&__row {
		display: flex;
		margin: 0 -8px; }

	&__column {
		width: calc(100% / 2 - 16px);
		margin: 0 8px; }

	.input-group {
		margin-bottom: 27px; }

	&__send {
		margin-top: 35px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		button {
			margin-right: 50px;

			span {
				white-space: nowrap; } } }

	&__consent {
		display: flex;
		align-items: center;

		a {
			color: #000000;

			&:hover {
				text-decoration: none; } } } }


.textarea {
		padding: 20px;
		border: 1px solid #F0F0F0;
		resize: none;
		border-radius: 20px; }

.input-groups {
		display: flex;
		flex-direction: column;
		gap: 10px;

		&--order-comment {
			margin-top: 10px; } }
