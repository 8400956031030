.product-page {
		max-width: 1472px;
		padding-left: 16px;
		padding-right: 16px;

		@include max-screen(1280px) {
				padding-left: 40px;
				padding-right: 40px; }

		@include max-screen(1024px) {
				padding-left: 32px;
				padding-right: 32px; }

		@include max-screen(976px) {
				padding-left: 15px;
				padding-right: 15px; }

		&__bread-crumbs {
			margin: 30px 0 44px; }

		&__product-info {
				margin-bottom: 60px;

				@include max-screen(976px) {
						margin-bottom: 30px; } }

		&__product-advantages {
				margin-bottom: 60px;
				display: flex;
				flex-direction: column;
				gap: 10px;

				@include max-screen(976px) {
						margin-bottom: 30px; } }

		&__reviews {
				margin-bottom: 70px; }

		&__product-characteristics {
				gap: 40px;
				margin-bottom: 60px;

				@include max-screen(976px) {
					gap: 20px; } }

		&__product-description {
				margin-bottom: 60px; }

		&__similar-slider {
				margin-bottom: 60px; }

		&__related-slider {
				margin-bottom: 60px; }

		&__double-card {
				margin-bottom: 60px; }

		&__common-questions {
				margin-bottom: 60px; } }



.product-slider {
		display: none;

		@include max-screen(976px) {
				display: block; }

		@include max-screen(576px) {
				margin-bottom: 25px; }

		&__slide {
				& img {
						width: 100%;
						object-fit: cover; } } }

.hide-more {
		align-items: center;
		justify-content: center;
		gap: 10px;
		text-decoration: none;
		color: $c-blue-800;
		display: none;


		&--show {
				display: flex;
				opacity: 1;
				-webkit-animation: display-none-transition 1s both;
				animation: display-none-transition 1s both;

				@-webkit-keyframes display-none-transition {
						0% {
								opacity: 0; } }

				@keyframes display-none-transition {
						0% {
								opacity: 0; } } } }



.show-more {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		text-decoration: none;
		color: $c-blue-800;

		&--hide {
			display: none; }

		&__link-icon {
				color: $c-blue-800;
				width: 10px;
				height: 10px;

				&--up {
						transform: rotate(180deg); } } }



.more-cards {
		display: none;

		&--show {
				display: block;
				opacity: 1;
				-webkit-animation: display-none-transition 1s both;
				animation: display-none-transition 1s both;

				@-webkit-keyframes display-none-transition {
						0% {
							opacity: 0; } }

				@keyframes display-none-transition {
						0% {
							opacity: 0; } } }

		&__review {
				margin-top: 30px; } }



.js-hide-photo,
.js-product-card-hide,
.js-hide-review {
		display: none;
		opacity: 1;
		-webkit-animation: display-none-transition 1s both;
		animation: display-none-transition 1s both;

		@-webkit-keyframes display-none-transition {
				0% {
						opacity: 0; } }

		@keyframes display-none-transition {
				0% {
						opacity: 0; } } }
