.stock-section {
		max-width: 1472px;
		padding-left: 16px;
		padding-right: 16px;

		& .container {
				padding: 0; }

		@include max-screen(1280px) {
				padding-left: 40px;
				padding-right: 40px; }

		@include max-screen(1024px) {
				padding-left: 32px;
				padding-right: 32px; }

		@include max-screen(976px) {
				padding-left: 15px;
				padding-right: 15px; }

		&__title {
				text-align: center;
				margin-bottom: 39px; }

		&__content {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				gap: 36px 30px;
				margin-bottom: 60px;

				@include max-screen(1024px) {
						grid-template-columns: repeat(3, 1fr);
						gap: 25px 20px; }

				@include max-screen(976px) {
						grid-template-columns: repeat(2, 1fr); }

				@include max-screen(576px) {
						grid-template-columns: 1fr; } }

		&__bread-crumbs {
				margin: 30px 0 40px; }

		&__btn {
				width: 100%; } }
