.filters-price {

		&__inputs {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 30px; }

		&__text {
				font-size: 14px; }

		&__text:first-child {
				margin-right: 10px; }

		&__text:last-child {
				margin-left: 10px; }

		&__input {
				border: none;
				//color: $c-dark-900
				font-size: 15px;
				//opacity: 0.5
				outline: none;
				line-height: 15px;
				color: $c-blue-800;

				&:hover::placeholder {
						color: $c-blue-800; }

				&--dark {
						color: $c-dark-900;
						opacity: 0.5; } }

		&__title {
				margin-bottom: 20px; }


		&__slider {
				margin-bottom: 20px;
				padding: 0 17px 0 5px; }

		&__dash {
				width: 11px;
				color: $c-dark-450; } }


.noUi-connect {
		background-color: $c-blue-800; }


.noUi-target {
		background-color: $c-dark-450;
		border: none;
		border-radius: 100px;
		box-shadow: none;
		height: 3px; }


.noUi-handle::after,
.noUi-handle::before {
		display: none; }


.noUi-handle {
		box-shadow: none;
		border-radius: 100%;
		background-color: $c-blue-800; }

.noUi-horizontal .noUi-handle {
		width: 20px;
		height: 20px;
		top: -10px;
		cursor: pointer; }


.noUi-horizontal .noUi-handle-upper {
		right: -17px; }



.input-filter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 110px;
		border: 1px solid $c-dark-450;
		border-radius: 50px;
		padding: 16px; }
