.sofa-link {
		text-decoration: none;
		background: linear-gradient(166.72deg, #D5DFE9 57.72%, #98ACBE 91.26%);
		max-width: 340px;
		width: 100%;
		position: relative;
		max-height: 550px;
		padding: 122px 25px;

		@include max-screen(1280px) {
			max-width: 100%; }

		@include max-screen(1024px) {
				padding: 100px 20px;
				min-height: 340px; }

		@include max-screen(576px) {
				min-height: 0;

				& .sofa-article-link__subtitle {
						font-size: 20px;
						line-height: 25px; } }

		@include max-screen(976px) {
				padding: 60px 13px;

				& .sofa-article-link__subtitle {
						font-size: 16px;
						line-height: 20px; } }

		@include max-screen(576px) {
				height: 230px; }

		&__link-main {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 10;

				&:hover ~ .sofa-article-link .sofa-article-link__btn {
						background: #F6F5F5;
						color: #24306B; } }



		&--min {
				padding: 25px;
				display: flex;
				align-items: center; }

		&__img {
				position: absolute;
				bottom: -4px;
				right: 0;
				max-width: 280px;
				width: 100%;

				@include max-screen(576px) {
						width: 185px;
						height: 145px;
						bottom: 0; }


				.sofa-link__background-sofa {
						width: 100%;
						height: 100%;
						object-fit: cover; } } }
