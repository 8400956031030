.section-advantages {
		@include max-screen(976px) {
				width: calc(100% + 15px);
				overflow: auto; }
		&__inner {
				display: flex;
				justify-content: space-around;
				background: $c-dark-400;
				border-radius: 50px;
				padding: 18px 0;

				@include max-screen(1024px) {
						padding-left: 31px;
						padding-right: 31px;
						justify-content: space-between; }

				@include max-screen(976px) {
						width: 960px; }

				@include max-screen(576px) {
						padding: 10px 15px; } } }
