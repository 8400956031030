.header {

		&__burger-menu {
				display: none;
				overflow-y: auto;
				overflow-x: hidden;

				& .container {
						overflow: visible; }

				@include max-screen(976px) {
						display: block;
						position: fixed;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						height: 100vh;
						background-color: $c-white;
						z-index: 1000;
						transform: translateX(-100%);
						transition: transform .3s linear;

						&--visible {
								transform: none; } } }

		&__burger-menu-top {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-top: 22px;
				padding-bottom: 22px;
				border-bottom: 1px solid $c-dark-400; }

		&__burger-menu-bottom {
				position: relative;
				padding-top: 22px;
				padding-bottom: 25px;

				& .header-navigation__personal-button {
						display: flex;
						width: 300px;
						max-width: 300px;
						margin-bottom: 60px; }


				& .header-main__callback-btn {
						display: flex; } }

		&__burger-menu-bottom-start-tab {
				transition: transform .3s linear, opacity .3s linear;

				&--hidden {
						display: none;
						transform: translateX(-100%);
						opacity: 0; } }

		&__burger-menu-category-item {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				opacity: 0;
				transform: translateX(100%);
				transition: transform .3s linear, opacity .3s linear;

				&--visible {
						opacity: 1;
						transform: none; }

				&--none {
						display: none; }

				&-body {
						padding-bottom: 25px;

						& .header-main__callback-btn {
								margin-left: auto;
								margin-right: auto; }

						&-inner {
								display: flex;
								flex-direction: column;
								gap: 27px;
								margin-bottom: 25px; } }

				&-title {
						display: flex;
						align-items: center;
						padding: 21px 23px;
						margin-bottom: 22px;
						border-bottom: 1px solid $c-dark-400;

						& .btn {
								padding: 0; }

						& span {
								display: block;
								margin-left: auto;
								margin-right: auto;
								font-size: 16px;
								font-weight: 500; } } }


		&__burger-menu-adress {
				display: flex;
				flex-direction: column;
				gap: 27px;
				margin-bottom: 27px; }

		&__burger-menu-nav {
				padding-bottom: 27px;
				margin-bottom: 27px;
				border-bottom: 1px solid $c-dark-400; }

		&__burger-menu-personal-section {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 27px;
				padding-bottom: 27px;
				margin-bottom: 27px;
				border-bottom: 1px solid $c-dark-400;

				& .personal-entrance,
				& .personal-goods__favorites-link,
				& .personal-goods__compare-link {
						display: flex; } }

		&__burger-menu-category {
				display: flex;
				flex-direction: column;
				gap: 27px;
				margin-bottom: 27px;

				&-btn {
						display: flex;
						justify-content: space-between;
						width: 100%;

						& span {
								pointer-events: none; } }

				&-link {
						max-width: 330px;
						justify-content: space-between; } }

		&__burger-menu-stocks {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 20px;
				margin-bottom: 27px;

				@include max-screen(576px) {
					grid-template-columns: 1fr; } }


		&__burger {
				display: none;

				@include max-screen(976px) {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						width: 28px;
						height: 28px;
						padding: 5px 3px; }

				& span {
						display: block;
						width: 100%;
						height: 2px;
						border-radius: 5px;
						background-color: $c-blue-800;
						pointer-events: none; } }

		&__burger-close {
				width: 28px;
				height: 28px;
				padding: 5px 3px; }





		&--fixed {
				position: sticky;
				top: 0;
				left: 0;
				right: 0;
				background-color: #fff;
				z-index: 5; }

		&__top {
				padding: 10px 0;
				background: $c-dark-400;
				margin-bottom: 26px;

				@include max-screen(1000px) {
						display: none; } }

		&__navigation {
				position: relative; } }



.header-nav {
		display: flex;
		justify-content: space-between;
		align-items: center; }



.nav-city {
		display: flex;
		align-items: center;

		&__count {
				display: flex;
				align-items: center;
				gap: 3px; }

		&__area {
			display: flex; }

		.span {
				font-size: 14px;
				line-height: 18px;
				color: $c-black;
				opacity: 0.5; }

		&__city {
			@include reset_btn;
			padding: 0;
			margin-right: 15px; } }



.city-area {
		text-decoration: none;
		color: $c-black;

		&__img {
				height: 16px;
				width: 16px;
				color: $c-blue-800;
				margin-right: 5px; } }

.header-nav-links {
		display: flex;
		gap: 37px;

		@include max-screen(1280px) {
			gap: 30px; }

		@include max-screen(1140px) {
			gap: 10px; }

		@include max-screen(976px) {
				flex-direction: column;
				gap:	27px; }

		&__link {
				font-size: 14px;
				line-height: 18px;
				color: $c-black;
				text-decoration: none;

				@include max-screen(976px) {
						font-size: 16px; } } }


.logo {
		display: block;
		object-fit: contain;

		.img {
				width: 173px;
				height: 67px;

				@include max-screen(576px) {
						width: 104px;
						height: 22px; } } }


.search-string {
				position: relative;
				align-items: center;
				display: flex;
				padding: 9px 20px;
				gap: 14px;
				background: $c-dark-400;
				border-radius: 50px;
				max-width: 799px;
				width: 100%;

				@include max-screen(1289px) {
						max-width: 300px; }

				@include max-screen(1160px) {
						max-width: 126px;

						& form {
								padding-left: 0;
								padding-right: 0; } }

				@include max-screen(976px) {
						display: none;
						max-width: 100%; }


				&__icon {
						width: 16px;
						height: 16px;
						color: $c-blue-800; }

				&__input {
						max-width: 799px;
						width: 100%;
						outline: none;
						border: none;
						background: $c-dark-400; }

				&__content {
						position: absolute;
						width: 100%;
						top: 70px;
						left: 0;
						z-index: 3;
						background: $c-white; }

				&:hover .search-content {
						display: flex; } }




.search-content {
		display: none;
		flex-direction: column;
		padding: 20px;
		border-radius: 4px;
		box-shadow: 0px 10px 31px rgba(0, 0, 0, 0.12);

		&--open {
				display: flex; }

		&__title {
				font-size: 16px;
				font-family: $ffs-m;
				color: $c-black;
				margin-bottom: 33px; }

		&__item {
				margin-bottom: 25px; } }



.search-item {
		display: flex;
		gap: 19px;

		&__icon {
				width: 17px;
				height: 17px;
				color: $c-dark-700; }

		&__text {
				font-size: 16px; } }



.search-category {
		padding: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-decoration: none;
		border-top: 1px solid #C4C4C4;
		border-bottom: 1px solid #C4C4C4;

		&:hover .search-category__text {
				color: $c-blue-800; }

		&__text {
				color: $c-black;
				font-size: 16px;
				font-family: $ffs-m; }

		&__icon {
				width: 11px;
				height: 11px;
				color: $c-blue-800;
				transform: rotate(270deg); } }



.header-bottom {

		&__main {
				margin-bottom: 15px; } }



.header-navigation {
		display: flex;
		align-items: center;
		justify-content: space-between;


		@include max-screen(976px) {
				display: none; }

		&__personal-button {
				max-width: 340px;
				width: 100%;

				@include max-screen(1450px) {
					max-width: 134px; }

				@include max-screen(1200px) {
						display: none; } } }



.header-main {
		display: flex;
		justify-content: space-between;
		gap: 97px;

		@include max-screen(1100px) {
				gap: 45px; }

		@include max-screen(976px) {
				margin-top: 10px;
				margin-bottom: 10px;
				justify-content: space-between; }

		@include max-screen(576px) {
				margin-top: 22px;
				margin-bottom: 22px; }

		&__content-min {
				display: flex;
				max-width: 363px;
				width: 100%;
				gap: 27px; }

		&__personal-area {
				margin-top: 18px; }

		&__info {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 10px; }

		&__main-section {
				display: flex;
				gap: 32px;
				width: 100%;

				@include max-screen(976px) {
						width: auto; }

				&--top {
						margin-top: 14px;
						margin-bottom: 18px; } } }



.callback-btn {
		@include reset_btn;
		max-width: 256px;
		width: 100%;
		border: 1px solid $c-dark-450;
		border-radius: 50px;
		display: flex;
		justify-content: space-evenly;
		padding: 5px 20px 5px 13px;
		align-items: center;
		transition: .3s;

		@include max-screen(976px) {
				display: none; }

		&:hover {
				background: #24306B; }

		&:hover .callback-btn__img {
				color: $c-white; }

		&:hover .callback-btn__number {
				color: $c-white; }

		&:hover .callback-btn__text {
				color: $c-white; }

		&--footer {
				max-width: none; }

		&__image {
				width: 50px;
				height: 50px;
				background: $c-blue-800;
				border-radius: 50px; }

		&__img {
				width: 26px;
				color: #24306B;
				transition: .3s;

				&--bottom {
						width: 19px;
						color: $c-white; } }


		&__info {
				display: flex;
				flex-direction: column;
				text-align: left; }

		&__number {
				font-size: 18px;
				line-height: 22px;
				color: $c-black;
				transition: .3s; }

		&__text {
				font-size: 14px;
				line-height: 18px;
				color: $c-blue-800;
				transition: .3s; } }

//
//.nav-header-main
//
//		&__icon
//				width: 16px
//				height: 16px
//				color: $c-blue-800



.personal-entrance {
		display: flex;
		align-items: center;
		gap: 8px;
		text-decoration: none;
		color: $c-black;
		background: none;
		border: none;
		cursor: pointer;

		@include max-screen(976px) {
				display: none; } }



.personal-area {
		display: flex;
		justify-content: center;
		flex-direction: column;
		gap: 37px;
		max-width: 340px;
		width: 100%;

		@include max-screen(1024px) {
			max-width: 300px; }

		@include max-screen(976px) {
				width: auto; }

		&__personal-img {
				width: 24px;
				height: 24px;
				color: $c-blue-800; }

		&__personal-section {
				display: flex;
				justify-content: space-between;
				margin-right: 27px;

				@include max-screen(976px) {
					margin-right: 0; } }

		&__personal-button {
				margin-bottom: 17px; }

		&__personal-text {
				transition: .3s;

				&:hover {
						color: $c-blue-750; } } }



.personal-goods {
		display: flex;
		gap: 37px;

		@include max-screen(576px) {
			gap: 18px; }

		&__icon {
				position: relative;

				&:hover .icon {
					color: $c-blue-750; } }


		&__count {
				position: absolute;
				right: -21px;
				top: -13px;
				background: #F6F5F5;
				border-radius: 50px;
				font-size: 12px;
				line-height: 15px;
				color: $c-black;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 29px;
				height: 21px;
				padding-top: 2px; }

		&__compare,
		&__favorites,
		&__basket {
				width: 24px;
				height: 24px;
				color: $c-blue-800; }

		&__compare {
				@include max-screen(976px) {
						margin-right: 8px; } }

		&__favorites {
				@include max-screen(976px) {
						margin-right: 8px; } }

		&__search {
				display: none;
				padding: 0;

				@include max-screen(976px) {
						display: block; } }

		@include max-screen(976px) {
						&__compare-link,
						&__favorites-link {
								display: none; } } }



.button-enter {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		text-decoration: none;

		&--min {
				padding: 15px 70px;
				justify-content: center;
				align-items: center;
				gap: 10px;

				@include max-screen(576px) {
						width: 100%;
						font-size: 14px; } }


		&__icon {
				height: 17px;
				width: 17px; }

		&__span {
				font-size: 14px; } }



.header-links-img {

		&__links {
				display: flex; } }



.header-tab {
		border-bottom: 4px solid transparent;
		padding: 17px 0;

		&:hover {
				border-bottom: 4px solid $c-blue-800; }

		&:hover .header-tabs__tab {
				color: $c-blue-800; }

		&__wrapper {
				padding-top: 64px;
				border-top: 1px solid #EDEDED;
				transition: .3s;
				opacity: 0;
				width: 100vw;
				min-height: 536px;
				background: $c-white;
				position: absolute;
				top: 59px;
				left: 0;
				z-index: 3;
				pointer-events: none; } }



.header-tab:hover .header-tab__wrapper {
		opacity: 1;
		pointer-events: all; }



.header-tabs {
		max-width: 965px;
		width: 100%;

		@include max-screen(1280px) {
			max-width: 100%; }


		&__inner {
				display: flex;
				justify-content: space-between;
				gap: 10px;
				width: 100%;

				@include max-screen(1280px) {
						gap: 20px;
						justify-content: flex-start; }

				@include max-screen(1200px) {
					gap: 10px;
					justify-content: space-between; } }


		&__tab {
				text-decoration: none;
				font-family: $ff-r;
				font-size: 17px;
				line-height: 21px;
				color: $c-black;
				transition: .2s; } }



.tab-wrapper {
		//border-top: 1px solid #EDEDED
		display: flex;
		justify-content: space-between;
		//width: 100vw
		//background: $c-white
		//top: 50px
		//z-index: 234
		//display: grid
		//grid-template-columns: 1fr 1fr

		//&__promotions-link
		//		display: flex
		//		gap: 20px

		@include max-screen(1280px) {
			flex-wrap: wrap; }

		&__btn-close {
				margin-left: auto;
				margin-bottom: 20px;
				margin-top: 25px; }

		&__item {
				margin-bottom: 30px; }

		&__columns {
				display: flex;
				gap: 80px;
				width: 100%; }

		&__links {
				display: flex;
				width: 100%;
				max-width: 570px;
				justify-content: space-between;

				@include max-screen(1280px) {
						margin-top: 60px; } }

		&__categories {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				width: 100%; } }



.category-item {
		display: flex;
		flex-direction: column;
		gap: 20px;
		max-width: 486px;

		&__links {
				gap: 10px;
				display: flex;
				flex-wrap: wrap; } }



.btn-long {

		&__icon {
				color: $c-blue-800;
				width: 16px;
				height: 16px; } }



.button-close {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 14px;
		max-width: 96px;
		width: 100%;

		&__text {
				font-size: 14px;
				color: $c-blue-800; }

		&__icon {
				width: 11px;
				height: 11px;
				color: $c-blue-800; } }
