.ask-question {

		&__btn {
				max-width: max-content; }

		&__icon {
				width: 23px;
				height: 23px;
				color: $c-blue-800;
				margin-bottom: 28px; }

		&__title {
				margin-bottom: 24px;
				font-size: 20px;
				font-family: $ffs-m;
				color: $c-black;

				&--min {
						max-width: 279px;
						width: 100%; } } }
