.salons-page {
		max-width: 1472px;
		padding-left: 16px;
		padding-right: 16px;

		& .container {
				padding: 0; }

		@include max-screen(1280px) {
				padding-left: 40px;
				padding-right: 40px; }

		@include max-screen(1024px) {
				padding-left: 32px;
				padding-right: 32px; }

		@include max-screen(976px) {
				padding-left: 15px;
				padding-right: 15px; }

		&__title {
				margin-bottom: 60px;
				text-align: center; }

		&__list {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 80px 35px;
				margin-bottom: 80px;

				@include max-screen(976px) {
					gap: 50px 35px;
					grid-template-columns: repeat(2, 1fr); }

				@include max-screen(576px) {
					grid-template-columns: 1fr;
					gap: 40px; } }

		&__map {
				height: 480px;
				width: 100%;
				margin-bottom: 60px;

				@include max-screen(576px) {
						height: 300px;
						overflow: hidden; } }

		&__bread-crumbs {
				margin: 30px 0 40px; } }


.info-card {
		max-width: 333px;
		width: 100%;

		&--wide {
				max-width: 344px; }

		&__link {
				color: $c-blue-800;
				display: flex;
				font-size: 15px;
				margin-bottom: 10px; }

		&__title {
				font-size: 18px;
				color: $c-blue-800;
				font-family: $ffs-m;
				margin-bottom: 20px; }

		&__subtitle {
				margin-bottom: 20px; }

		&__text {
				font-size: 15px;
				display: flex;
				text-decoration: none;
				color: $c-black;

				&--usual {
						font-size: 14px;
						margin-bottom: 10px; }

				&--num {
						font-size: 18px; }

				&--grey {
						color: $c-dark-700;
						margin-bottom: 10px; } } }




.image-string {
		display: flex;
		gap: 14px;

		&__img:first-child {
				width: 20px;
				height: 20px; }

		&__img {
				color: #24306B;
				width: 17px;
				height: 17px; }

		&__text {
				font-size: 15px;
				min-height: 40px; } }


//.ymaps-2-1-79-zoom
//		height: 0 !important
