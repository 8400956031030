.tabs {
		$tabs: &;
		display: flex;
		flex-direction: column;
		position: relative;

		&-header {
				display: flex;
				align-items: center;
				justify-content: center;
				white-space: nowrap;
				position: relative;
				bottom: -1px;

				&--min {
						justify-content: flex-start;
						max-width: 314px;
						border: 1px solid rgba(0, 0, 0, 0.1);
						border-radius: 50px; }

				&__span {
						position: absolute;
						top: 0px;
						right: -40px;
						font-size: 12px;
						color: $c-white;
						padding: 0px 10px;
						background: $c-blue-800;
						border-radius: 50px; }



				&--main {
						max-width: 594px;
						width: 100%;
						margin: 0 auto;
						justify-content: space-between; }

				&:empty {
						display: none; }

				&__button {
						$btn: &;
						@include reset-btn;
						padding: 16px 32px;
						color: $c-primary;
						font-family: $ff-r;
						font-size: 13px;
						line-height: 20px;
						transition: color .3s $t-function, background-color .3s $t-function, box-shadow .3s $t-function;

						&-text {
								border-bottom: 1px dashed rgba($c-primary, .25); }

						&--active {
								color: #111111;
								background-color: $c-blue-300;

								#{$btn}-text {
										border-style: none !important; } } }

				&--hide {
						transform: scale(0);
						visibility: hidden;
						opacity: 0;
						position: absolute; } }

		&-content {
				//position: relative
				overflow: hidden;

				&:empty {
						display: none; }

				&__tab {
						display: none;
						overflow-y: auto;
						overflow-x: hidden;
						padding: 28px 32px;

						&--active {
								background-color: $c-blue-300;
								height: auto;
								width: 100%;
								display: block; } } }

		&--dashed {}

		#{$tabs} {

				&-header {
						display: flex;
						align-items: center;
						white-space: nowrap;
						gap: 28px;

						@include max-screen(576px) {
							gap: 15px;
							overflow: auto; }

						&:empty {
								display: none; }

						&__button {
								position: relative;
								font-size: 17px;
								line-height: 21px;
								color: #000000;
								@include reset-btn;
								font-family: $ffs-m;
								padding: 0;
								transition: color .3s $t-function, border-bottom-color .3s $t-function, box-shadow .3s $t-function;

								@include max-screen(576px) {
										&:last-child {
											width: calc(100% + 40px); } }

								&--active {
										color: $c-dark-900;
										border-bottom: none;
										box-shadow: inset 0px -2px 0px $c-blue-800; } }

						&--hide {
								transform: scale(0);
								visibility: hidden;
								opacity: 0;
								position: absolute; } }

				&-content {
						//position: relative
						overflow: hidden;
						margin-top: 40px;
						//border: 1px solid black
						left: calc( (100vw - 1708px)/(1980 - 1708) * ((-125) - 0) + 0px);
						//width: calc( (100vw - 480px)/(1980 - 480) * (1980 - 480) + 480px)
						width: 100%;
						opacity: 1;
						transition: .3s;

						@media (max-width: 1708px) {
								left: 0; }

						&--show {
								opacity: 1; }

						&:empty {
								display: none; }

						&__tab {
								display: none;
								overflow-y: auto;
								overflow-x: hidden;
								background-color: transparent;
								padding: 0;

								&--active {
										height: auto;
										width: 100%;
										display: block; } } } } }



.tabs-min {

		&__header {
				max-width: 315px;
				width: 100%;
				height: 60px;
				border-radius: 50px;
				border: 1px solid rgba(0, 0, 0, 0.1); }

		&__icon {
				height: 16px;
				width: 16px;
				color: $c-dark-700;
				transition: .3s; }

		&__button {
				$btn: &;
				@include reset-btn;
				padding: 10px 20px;
				color: $c-dark-700;
				font-family: $ff-r;
				font-size: 13px;
				line-height: 20px;
				transition: color .3s $t-function, background-color .3s $t-function, box-shadow .3s $t-function;
				display: flex;
				align-items: center;
				gap: 10px;
				border-radius: 50px;

				&-text {
						border-bottom: 1px dashed rgba($c-primary, .25); }

				&--active {
						color: $c-blue-800;
						background: #F6F5F5;
						border-radius: 50px; }

				&--active .tabs-min__icon {
						color: $c-blue-800; } }

		&__content {

				&:empty {
						display: none; }

				&__tab {
						display: none;
						overflow-y: auto;
						overflow-x: hidden;
						padding: 28px 32px;

						&--active {
								background-color: $c-blue-300;
								height: auto;
								width: 100%;
								display: block; } } } }



.tabs-modal {

		&__header {
				max-width: 328px;
				width: 100%;
				margin: 0 auto;
				display: flex;
				align-items: center;
				white-space: nowrap;
				justify-content: space-between;

				@include max-screen(576px) {
						max-width: 100%;
						margin-right: 0;
						margin-left: 0; } }

		&__registration {
				display: flex;
				flex-wrap: wrap;
				gap: 20px 11px;
				justify-content: center; }


		&__footer {
				display: flex;
				flex-direction: column;
				gap: 10px;
				max-width: 360px;

				@include max-screen(576px) {
					align-items: center; } }

		&__btn {
				margin-top: 25px;

				&--min {
						max-width: 220px;
						width: 100%;
						padding: 15px 20px;

						@include max-screen(576px) {
							max-width: 100%; } } }

		&__content {
				margin-top: 30px !important; }

		&__login {
				max-width: 328px;
				width: 100%;
				margin: 0 auto; }

		&__input {
				max-width: 220px;
				width: 100%;

				@include max-screen(576px) {
					max-width: 100%; } }

		&__button {
				$btn: &;
				@include reset-btn;
				color: $c-dark-700;
				font-family: $ffs-m;
				font-size: 20px;

				@include max-screen(576px) {
					font-size: 16px; }


				&--active {
						color: $c-blue-800; } } }







