.homepage-questions {
		@include max-screen(976px) {
				width: calc(100% + 15px);
				overflow: auto;

				@include max-screen(576px) {
						width: 100%; } }

		&__inner {
				display: flex;
				justify-content: space-between;
				gap: 5px;
				min-width: 960px;

				@include max-screen(576px) {
					min-width: 100%;
					flex-direction: column;
					gap: 10px; } }

		&__item {
				max-width: 477px;
				width: 100%;
				background: $c-dark-400;
				padding: 70px 70px 46px 65px;

				@include max-screen(1024px) {
						padding: 30px; } } }
