.product-info {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		gap: 50px;

		@include max-screen(1024px) {
			gap: 20px; }

		@include max-screen(976px) {
			flex-direction: column-reverse; }

		&__row {
				margin-bottom: 27px; }

		&__link {
				margin: 24px auto 0;
				color: $c-blue-800;
				display: flex;
				gap: 10px;
				width: 100%;
				justify-content: center;

				&:after {
						content: url('/img/icons/arrow-down.svg'); }

				&--open {
					&:after {
							transform: rotate(180deg); } } } }

.product-cards-slider {
		width: calc(100% - 510px);
		max-width: 1080px;

		@include max-screen(1280px) {
			width: calc(100% - 420px); }

		@include max-screen(978px) {
			width: 100%; }

		& .swiper-slide {
			background-size: cover;
			background-position: center;

			& img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: contain; } }

		&	.js-product-cards-slider2 {
				height: auto;
				width: 100%;
				border: 1px solid #EDEDED; }

		&	.js-product-cards-slider {
				width: 100%;
				height: 150px;
				box-sizing: border-box;
				padding: 10px 0;

				@include max-screen(1279px) {
						height: 30%; }

				& .swiper-slide {
						width: 25%;
						height: 100%;
						opacity: 0.4;
						cursor: pointer;
						border: 1px solid #EDEDED; }

				& .swiper-slide-thumb-active {
						opacity: 1;
						border: 1px solid #24306B; } } }
