.advantage-description {
		display: flex;
		align-items: center;
		gap: 6px;
		margin-top: 30px;

		@include max-screen(576px) {
				margin-top: 20px; }

		&__icon {
				width: 19px;
				height: 19px;
				color: $c-blue-800; }

		&__text {
				font-size: 13px;

				@include max-screen(576px) {
					font-size: 12px; } } }

