.article-card {
		$card: &;
		position: relative;
		display: flex;
		padding: 25px 40px;
		min-height: 300px;

		@include max-screen(1024px) {
				padding: 70px 40px; }

		@include max-screen(976px) {
				min-height: 230px;
				padding: 30px; }

		//&--big
		//		height: 100% !important

		&--little {
				height: 50%; }

		&--min {
				max-width: 333px;
				width: 100%;
				min-height: 470px;
				padding: 34px 25px;

				@include max-screen(1280px) {
					min-height: 380px; }

				@include max-screen(976px) {
					min-height: 310px; }

				@include max-screen(576px) {
					min-height: 200px;
					max-width: 100%; } }

		&--long {
				min-height: 550px;
				padding: 122px 25px; }

		&__preview {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				display: flex;

				&--grey:after,
				&--white:after,
				&--blue:after,
				&--pink:after {
						content: "";
						display: block;
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						background: transparent;
						opacity: 0.8; }

				&--pink:after {
						background: linear-gradient(360deg, rgba(205, 181, 185, 0.72) 31.47%, rgba(255, 255, 255, 0) 50.55%); }

				&--blue:after {
						background: linear-gradient(360deg, rgba(48, 64, 80, 0.72) 31.47%, rgba(255, 255, 255, 0) 50.55%); }

				&--white:after {
					background: linear-gradient(360deg, rgba(173, 187, 184, 0.72) 31.47%, rgba(255, 255, 255, 0) 50.55%); }

				&--grey:after {
					background: linear-gradient(360deg, rgba(135, 142, 148, 0.72) 31.47%, rgba(255, 255, 255, 0) 50.55%); }


				&-image {
						width: 100%;
						height: auto;
						object-fit: cover; } }



		&__content {
				position: relative;
				z-index: 2;
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				gap: 10px;
				justify-content: flex-end;

				&--right {
						align-items: flex-end; } }

		&__label {
				font-size: 14px;
				color: $c-dark-800;
				background: rgba(255, 255, 255, 0.5);
				padding: 4px 10px;
				border-radius: 4px;
				max-width: max-content;

				&--dark {
						background: rgba(154, 154, 154, 0.2); } }

		&__title {
				font-size: 23px;
				font-family: $ffs-m;
				max-width: 279px;

				@include max-screen(576px) {
					font-size: 16px; }

				&--white {
						color: $c-white; }

				&--big {
						color: $c-white;
						font-size: 30px;
						max-width: 100%;

						@include max-screen(576px) {
							font-size: 16px; } } }

		&__link {
				margin-top: 14px;
				max-width: max-content; }

		&--big {

				#{$card} {}

				&__content {
						justify-content: center;
						align-items: center; } } }
