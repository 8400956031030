.main-slider {
		overflow: hidden;
		cursor: pointer;
		position: relative;


		&--wide {
				max-width: 1503px; }

		&__btn {
				width: 50px;
				height: 50px;
				border-radius: 50px;
				border: none;
				background: #F6F5F5;
				opacity: 0.5;
				display: flex;
				justify-content: center;
				align-items: center;

				@include max-screen(576px) {
						display: none; }

				&--prev {
						position: absolute;
						top: calc(50% - 25px);
						left: 10px;
						z-index: 1;
						cursor: pointer; }

				&--prev-comparison {
						position: absolute;
						top: 0;
						height: 100%;
						left: -60px;
						z-index: 1;
						cursor: pointer;
						transition: .3s; }



				&--next {
						position: absolute;
						top: calc(50% - 25px);
						right: 10px;
						z-index: 1;
						cursor: pointer; }

				&--next-comparison {
						position: absolute;
						top: 0;
						height: 100%;
						right: -60px;
						z-index: 1;
						cursor: pointer;
						transition: .3s; } }

		&__btn-icon {
				width: 17px;
				height: 14px;

				&--right {
						margin-left: 4px; } }


		&__link {
				&:hover {
						& .sofa-article-link__btn {
								background: #F6F5F5;
								color: #24306B; } } } }


.swiper-button-disabled {
	opacity: 0; }



.section-slider__slider {
		width: 80%;

		@include max-screen(1280px) {
				width: 68%; }

		@include max-screen(576px) {
				width: 100%; } }
