.advantages-item {
		display: flex;
		align-items: center;
		gap: 13px;

		@include max-screen(1024px) {
			&:last-child {
					& .advantages-item__text {
							max-width: 180px; } } }

		@include max-screen(976px) {
			gap: 7px; }

		&__icon {
				color: $c-blue-800;
				width: 25px;
				height: 25px;
				mix-blend-mode: darken;

				@include max-screen(1024px) {
						width: 20px;
						height: 20px; } }

		&__text {
				max-width: 243px;

				@include max-screen(1024px) {
						font-size: 14px; } } }
