.sleeping-link {
		position: relative;
		min-height: 360px;
		padding: 77px 63px;
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;

		@include max-screen(1280px) {
				padding: 75px 40px; }

		@include max-screen(1024px) {
				min-height: 280px;
				padding: 40px; }

		@include max-screen(976px) {
				padding: 15px; }

		&__link-main {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 10;

				&:hover {
						& ~ .sleeping-link__description .sleeping-description__button {
								background: #F6F5F5;
								color: #24306B; } } }

		&--header {
				width: 100%;
				max-width: 275px;
				min-height: 395px;
				padding: 25px;
				align-items: end;
				justify-content: center;

				&-burger {
						padding-top: 320px;
						min-height: 480px;
						max-width: 100%;

						@include max-screen(576px) {
							min-height: 330px;
							align-items: flex-end;
							padding: 20px; } } }


		&__description {
				position: absolute;
				padding: 30px 15px;
				z-index: 2; }

		&__img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				display: flex;

				.img {
						width: 100%;
						object-fit: cover; } } }



.sleeping-description {
		position: relative;
		max-width: 192px;
		width: 100%;
		background: $c-white;

		&--header {
				max-width: none;
				padding: 20px 15px; }

		&__title {
				font-size: 14px;
				line-height: 18px;
				font-family: $ffs-m;
				color: $c-black;
				margin-bottom: 3px; }

		&__text {
				font-size: 12px;
				line-height: 15px;
				font-family: $ff-r;
				color: $c-black; }

		&__button {
				max-width: max-content;
				margin-top: 15px;
				display: inline-block; } }



.sleeping-bottom {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 30px;

		@include max-screen(976px) {
			gap: 20px; }

		@include max-screen(576px) {
				display: none; } }



.sleeping-bottom-slider {
		display: none;

		@include max-screen(576px) {
				display: block; } }


.sleeping-systems {

		&__top {
				margin-bottom: 34px; } }
