.checkbox-btn {
		display: inline-block;
		margin: 0 5px 0 0;
		user-select: none;
		position: relative;

		@include max-screen(976px) {
				margin-right: 15px; }

		&--brown {
				span {
						background: $c-dark-400; } }

		input[type=checkbox] {
				z-index: -1;
				opacity: 0;
				display: block;
				width: 0;
				height: 0; }

		span {
				display: inline-block;
				cursor: pointer;
				padding: 10px 13px;
				border: 1px solid $c-dark-450;
				border-radius: 10px;
				transition: 0.2s ease;
				font-size: 12px;
				line-height: 18px;
				color: #000000; }


		input[type=checkbox]:checked + span {
				color: $c-white;
				background:  $c-blue-800; } }

/* Checked */

/* Focus */

.focused span {
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

/* Hover */

.checkbox-btn {
		&:hover span {
				color: $c-blue-800; }
		input[type=checkbox] {
				&:active:not(:disabled) + span {
						color: $c-black; }

				&:disabled + span {
						background: $c-dark-500;
						color: $c-brown-700;
						cursor: not-allowed; } } }



.checkbox-form {
		max-width: 220px;
		display: flex;
		padding: 10px 20px;
		gap: 8px;

		@include max-screen(576px) {
			align-items: center;
			max-width: 100%;
			padding: 0; }

		&__input {
				width: 33px;
				height: 20px; }

		&__text {
				font-size: 14px;
				color: $c-black; } }



.custom-checkbox {
		display: flex;
		gap: 8px;
		cursor: pointer;

		&__input {
			width: 20px;
			height: 20px; }

		&__text {
				font-size: 14px; } }
