.about-page {
		max-width: 1472px;
		padding-left: 16px;
		padding-right: 16px;

		& .container {
				padding: 0; }

		@include max-screen(1280px) {
				padding-left: 40px;
				padding-right: 40px; }

		@include max-screen(1024px) {
				padding-left: 32px;
				padding-right: 32px; }

		@include max-screen(976px) {
				padding-left: 15px;
				padding-right: 15px; }

		&__top {
				margin-bottom: 41px; }

		&__title {
				margin-bottom: 60px;
				text-align: center;

				@include max-screen(976px) {
						margin-bottom: 40px; }

				@include max-screen(576px) {
						margin-bottom: 30px; } }

		&__review {
				margin-bottom: 56px; }

		&__text {
				margin-bottom: 60px; }

		&__cards {
				margin-bottom: 80px;

				@include max-screen(976px) {
						margin-bottom: 60px; } }

		&__amount-daily {
				margin-bottom: 123px;

				@include max-screen(976px) {
						margin-bottom: 60px; } }

		&__bottom {
				margin-bottom: 80px;

				@include max-screen(976px) {
						margin-bottom: 60px; } }

		&__bread-crumbs {
				margin: 30px 0 40px; } }

.section-double {
		display: flex;

		@include max-screen(976px) {
			flex-direction: column; }

		&__link {
				max-width: 861px;
				width: 100%; }

		&__link-city {
				max-width: 579px;
				width: 100%;

				@include max-screen(976px) {
					max-width: 100%;
					min-height: 375px;
					align-items: flex-end; }

				@include max-screen(576px) {
					min-height: 200px; } }

		&__background {
				&:after {
						content: "";
						display: block;
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						background: transparent;
						background: radial-gradient(65.35% 65.72% at 37.82% 21.54%, rgba(213, 223, 233, 0) 0%, #98ACBE 100%);
						opacity: 0.8; } } }


.usual-card {
		position: relative;
		min-height: 475px;
		display: flex;
		justify-content: center;
		align-items: center;

		@include max-screen(1024px) {
			min-height: 310px; }

		@include max-screen(976px) {
			min-height: 375px; }

		@include max-screen(576px) {
			min-height: 200px; }

		&__preview {
				position: absolute;
				top: 0;
				left: 0;
				max-width: 861px;
				width: 100%;
				height: 100%;
				z-index: 1;
				display: flex; }

		&__img {
				width: 100%;
				height: auto;
				object-fit: cover;

				& img {
						object-fit: cover; } }

		&__content {
				position: relative;
				z-index: 2; }

		&__text {
				font-size: 40px;
				font-family: $ffs-m;
				text-align: center;
				max-width: 530px;

				@include max-screen(1024px) {
						max-width: 400px;
						font-size: 30px; }

				@include max-screen(576px) {
						font-size: 20px; } } }



.text-block {
		background: $c-dark-300;
		border: 2px solid #FFFFFF;

		&--flex {
				display: flex;
				justify-content: center;
				align-items: center;
				border: none; }

		&__text {
				max-width: 1033px;
				width: 100%;
				font-size: 20px;
				padding: 65px 0;
				margin: 0 auto;

				@include max-screen(1024px) {
						padding: 40px; }

				@include max-screen(976px) {
						padding: 30px;
						font-size: 16px; }

				@include max-screen(576px) {
						padding: 20px; }

				&--min {
						max-width: 528px; } } }



.quadruple-card {
		display: grid;
		grid-template-columns: 1fr 1fr;

		@include max-screen(576px) {
			grid-template-columns: 1fr; }

		&__img .img {
				width: 100%;
				height: 100%; } }



.amount-goods {

		&__title {
				color: $c-black;
				font-family: $ffs-m;
				font-size: 35px;
				text-align: center;
				margin-bottom: 31px;

				@include max-screen(976px) {
						font-size: 30px; }

				@include max-screen(576px) {
						font-size: 28px; } }

		&__content {
				display: flex;
				justify-content: space-around;

				@include max-screen(976px) {
					flex-direction: column;
					gap: 60px; }

				@include max-screen(576px) {
					gap: 30px; } } }



.amount-item {

		&__num {
				color: $c-blue-800;
				font-size: 100px;
				font-family: $ffs-m;
				text-align: center;

				@include max-screen(576px) {
					font-size: 64px; } }

		&__description {
				text-align: center;
				color: $c-black;
				font-size: 20px;
				font-family: $ffs-m;

				@include max-screen(576px) {
					font-size: 16px; } } }
