.blog-page {
		max-width: 1472px;
		padding-left: 16px;
		padding-right: 16px;

		& .container {
				padding: 0; }

		@include max-screen(1280px) {
				padding-left: 40px;
				padding-right: 40px; }

		@include max-screen(1024px) {
				padding-left: 32px;
				padding-right: 32px; }

		@include max-screen(976px) {
				padding-left: 15px;
				padding-right: 15px; }

		&__title {
				margin-bottom: 40px;
				text-align: center; }

		&__bread-crumbs {
				margin: 30px 0 40px; }

		&__links {
				gap: 10px;
				display: flex;
				margin-bottom: 40px;

				@include max-screen(576px) {
						margin-bottom: 30px; } }

		&__title {
				margin-bottom: 20px;
				text-align: center; }

		&__content {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				gap: 36px 30px;
				margin-bottom: 40px;

				@include max-screen(1024px) {
					grid-template-columns: repeat(3, 1fr);
					gap: 25px 20px; }

				@include max-screen(576px) {
					grid-template-columns: 1fr; } }

		&__catalog {
				@include max-screen(976px) {
						grid-template-columns: repeat(2, 1fr);

						& .article-card--min {
								max-width: 100%; } }

				@include max-screen(576px) {
						grid-template-columns: 1fr; }

				& .article-card__title {
						text-align: center; } }

		&__bread-crumbs {
				margin: 30px 0 40px; }

		&__btn {
				width: 100%; } }



.link-item {
		background: rgba(213, 223, 233, 0.47);
		padding: 10px;
		text-decoration: none;
		color: $c-black;

		@include max-screen(576px) {
			font-size: 12px; }

		&--min {
				padding: 4px 10px;
				background: rgba(154, 154, 154, 0.2); } }
