.product-description {
		font-size: 16px;
		color: $c-black;

		li {
				list-style-type: disc;
				margin-left: 25px; }

		&__text {
				gap: 5px;
				display: flex;
				flex-direction: column; } }



.product-characteristics {
		display: flex;
		justify-content: space-between;

		@include max-screen(976px) {
			flex-direction: column; }

		&__column {
				max-width: 702px;
				width: 100%; } }



.product-characteristics-column {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		max-width: 702px;
		width: 100%;
		padding: 0;
		gap: 20px;

		li {
				align-items: baseline;
				display: flex;
				font-size: 16px;
				color: $c-dark-700; }

		li:before {
				content: '';
				border-bottom: 1px dashed #ccc;
				flex-grow: 1;
				order: 2;
				margin: 0 5px; }

		.product-characteristics-column__span {
				order: 3;
				font-size: 16px;
				color: $c-black; }

		&__total,
		&__column {
				display: flex;
				flex-direction: column;
				align-items: flex-end; } }
