.page-cart {
		max-width: 1472px;
		padding-left: 16px;
		padding-right: 16px;

		& .container {
				padding: 0; }

		@include max-screen(1280px) {
				padding-left: 40px;
				padding-right: 40px; }

		@include max-screen(1024px) {
				padding-left: 32px;
				padding-right: 32px; }

		@include max-screen(976px) {
				padding-left: 15px;
				padding-right: 15px; }

		&__title {
				margin-bottom: 20px; }

		&__basket {
				margin-bottom: 30px; }

		&__form {
				margin-bottom: 25px; }

		&__personal {
				margin-bottom: 20px; }

		&__payment {
				margin-bottom: 20px; }

		&__total {
				margin-bottom: 25px; }

		&__bread-crumbs {
				margin: 30px 0 60px; }

		&__payment {
				display: flex;
				flex-direction: column;
				gap: 10px; } }


.title-goods {

		&__title {
				font-size: 40px;
				color: $c-black;
				font-family: $ffs-m;

				@include max-screen(1024px) {
					font-size: 30px; }

				@include max-screen(976px) {
					font-size: 28px; } }

		&__text {
				font-size: 16px;
				color: $c-black;
				opacity: 0.5;
				margin-left: 15px; } }



.personal-form {
		display: flex;
		flex-direction: column;
		gap: 10px; }



.price-basket {
		background: #D5DFE9;
		padding: 30px 20px;
		border-radius: 5px;
		max-width: 410px;
		width: 100%; }


.send-form {
		display: flex;
		flex-direction: column;
		gap: 25px;

		&__button {
				max-width: 275px; }

		&__checkbox {
				max-width: 347px; } }



.basket-section {
		background: $c-white;
		border-radius: 10px;

		&__content {
				max-width: 410px; }

		&__payment {
				gap: 15px;
				display: flex;
				flex-direction: column; }

		&__home-input {
				max-width: 215px;
				padding: 20px; }

		&--padd {
				padding: 40px;

				@include max-screen(976px) {
						padding: 30px 20px; } }

		&--padd-min {
				padding: 40px 22px;
				display: flex;
				gap: 65px;

				@include max-screen(976px) {
					gap: 20px; }

				@include max-screen(576px) {
						flex-direction: column;
						gap: 25px; } }

		&__title {
				font-size: 20px;
				color: $c-black;
				font-family: $ffs-m;
				margin-bottom: 21px; }

		&__goods {
				padding: 40px;
				display: flex;
				flex-direction: column;
				gap: 50px;

				@include max-screen(976px) {
						padding: 20px; }

				@include max-screen(576px) {
						padding: 25px 15px; } }

		&__total {
				padding: 40px;
				display: flex;
				justify-content: space-between;

				@include max-screen(976px) {
						padding: 20px; }

				@include max-screen(576px) {
						flex-direction: column; } }

		&__street-input,
		&__promo {
				max-width: 410px;
				padding: 20px; }

		&__frame-input {
				max-width: 215px;
				padding: 20px; }

		&__form {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				gap: 10px 0;
				margin-bottom: 20px; }

		&__select {
				width: 100%; }

		&__flat-input {
				max-width: 280px;
				padding: 20px; }

		&__location-delivery {
				& .form-cart__payer {
						display: flex;
						flex-direction: column;
						gap: 20px; }

				& .form-cart__sity {
						display: none; }

				& .form-cart__index {
						display: flex;
						flex-direction: column; }

				& .form-cart__index-label {
						margin-bottom: 20px; }

				& .dropdown-block {
						width: 100%;
						padding: 15px 40px;
						border-radius: 50px;
						border: 1px solid #f0f0f0; }

				& .bx-sls .dropdown-icon {
						top: 24px;
						left: 15px; }

				& .bx-sls .bx-ui-sls-clear {
						top: 15px;
						right: 10px; }

				& .dropdown-fade2white {
						display: none; }

				& .bx-sls .bx-ui-sls-pane {
						top: 66px; }

				& .bx-sls .quick-location-tag {
						color: #000; } } }


.basket-item {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include max-screen(576px) {
			position: relative;
			flex-wrap: wrap; }

		&__link {
				display: flex;
				gap: 30px;
				text-decoration: none;
				width: 40%;

				@include max-screen(576px) {
						width: 100%;
						gap: 15px;
						margin-bottom: 15px; } }

		&__img {
				max-width: 110px;
				height: 70px;
				object-fit: contain;

				.img {
						width: 100%;
						height: 100%; } }

		&__count {
				display: flex;
				align-items: flex-end; }

		&__btn-close {
				@include max-screen(576px) {
					position: absolute;
					top: 0; } } }



.item-description {
		display: flex;
		flex-direction: column;
		max-width: 303px;
		gap: 11px;

		&__title {
				font-size: 16px;
				color: $c-black;
				font-family: $ffs-m;

				@include max-screen(576px) {
						font-size: 14px; } }

		&__text {
				font-size: 14px;
				color: rgba(0, 0, 0, 0.5); } }



.quantity {
		padding: 14px 20px;
		border: 1px solid #EDEDED;
		border-radius: 50px;
		height: 50px;

		&__field {
				display: flex; }

		&__btn {
				background: none;
				border: none;
				cursor: pointer;

				&:disabled {
						opacity: 0.5;
						cursor: auto; } }

		&__input {
				text-align: center;
				width: 60px;
				border: none;
				outline: none; }

		&__icon {
				width: 9px;
				height: 9px;
				color: $c-blue-800;

				&--left {
						transform: rotate(180deg); } } }



.section-items {
		background: $c-blue-900;
		margin: 0 20px;
		border-radius: 5px;
		padding: 20px;

		&__top {
				display: flex;
				justify-content: space-between; }

		&__goods {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between; }

		&__title {
				font-size: 16px;
				color: $c-black;
				font-family: $ffs-m;
				margin-bottom: 16px; } }


.small-card {
		max-width: 200px;

		&__title {
				display: flex;
				gap: 15px;
				margin-bottom: 16px; }

		&__img {
				max-width: 110px;
				width: 100%;
				height: 70px;
				object-fit: contain;
				margin-bottom: 14px;

				.img {
						width: 100%;
						height: 100%; } } }


.promo-input {
		width: 100%;

		&__input {
				padding: 20px !important; }

		&__enter {
				margin-bottom: 10px; }

		&__message {
				display: flex;
				flex-direction: column;
				gap: 10px; }

		&__success {
				top: 60px;
				background: $c-blue-800;
				color: $c-white;
				padding: 10px 20px;
				border-radius: 50px;
				font-size: 14px;
				width: fit-content; }

		&__error {
				top: 100px;
				background: $c-blue-900;
				color: $c-black;
				padding: 10px 20px;
				border-radius: 50px;
				font-size: 14px;
				width: fit-content; } }



.main-input {
		width: 100%;
		height: 55px;
		//padding: 5px 8px
		border: 1px solid $c-blue-400;
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		outline: none;
		padding: 20px;

		&--promo {
				padding: 9px; }

		&__enter {
				width: 100%;
				height: 55px;
				padding: 5px 8px;
				border: 1px solid $c-blue-400;
				border-radius: 50px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: relative;
				margin-bottom: 10px; }

		&__span {
				position: absolute;
				background: white;
				color: rgba(0, 0, 0, 0.5);
				font-size: 15px;
				margin-left: 10px; }


		&__message {
				display: flex;
				flex-direction: column;
				gap: 10px; }


		&__input {
				border: none;
				outline: none;
				//max-width: 308px
				width: 100%;
				height: 20px;
				padding: 10px;
				resize: none;
				//min-height: 100px

				&::-webkit-calendar-picker-indicator {
					opacity: 0; }

				&:before {
						content: url("/img/icons/calendar.svg");
						display: inline-block;
						font: normal normal normal 14px/1 FontAwesome;
						font-size: inherit;
						text-rendering: auto;
						position: absolute;
						right: 20px;
						top: 50%;
						transform: translateY(-50%); }
 }				//

		&__icon {
				width: 17px;
				height: 17px;
				color: $c-white; }

		&__button {
				width: 80px;
				height: 44px;
				border: none;
				background: $c-blue-800;
				opacity: 0.5;
				border-radius: 50px;
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				transition: opacity .3s;

				&:hover {
						opacity: 1; } } }





.total-price {
		padding: 0;

		&__span {}


		&__item {
				display: flex;
				justify-content: space-between; }

		& li {
				align-items: baseline;
				display: flex;
				margin-bottom: 18px; }

		& li::before {
				content: '';
				border-bottom: 1px dashed #ccc;
				flex-grow: 1;
				order: 2;
				margin: 0 5px; }

		&  .total-price__span {
				order: 3; } }



.total-goods {
		max-width: 382px;
		width: 100%;

		&__price {
				margin-top: 30px;
				font-family: $ffs-m;
				margin-bottom: 0 !important; }

		&__span {
				font-family: $ffs-m;
				font-size: 20px; } }




.form-radio {
		display: flex;
		flex-direction: column;
		gap: 20px;

		&__text {
				font-size: 16px;
				font-family: $ffs-m; }

		&__span {
				color: $c-blue-800;
				font-family: $ffs-m;
				display: contents; } }



.card-min {
		display: flex;
		gap: 40px;

		&__content {
				max-width: 303px;
				width: 100%;
				display: flex;
				flex-direction: column; }

		&__title {
				font-size: 16px;
				font-family: $ffs-m;
				color: $c-black;
				margin-bottom: 11px; }

		&__text {
				font-size: 14px;
				color: $c-black; }

		&__img {
				max-width: 110px;
				width: 100%;
				height: 100px;
				object-fit: contain;

				.img {
						width: 100%;
						height: 100%; } } }



.manage-basket-item-remove {
		transform: translateY(-25px);

		@include max-screen(576px) {
				position: absolute;
				top: 25px;
				right: 10px; } }

.cart-note {
		margin-top: 25px; }


.pickup {

		& .bx-soa-pickup-subTitle {
				margin-bottom: 20px;
				font-size: 20px; }

		& .bx-soa-pickup-l-item-name {
				margin-bottom: 20px;
				font-size: 18px;
				color: #24306B;
				font-family: $ffs-m; }

		& .bx-soa-pickup-l-item-adress {
				display: none; }

		& .bx-soa-pickup-list-item:not(:last-child) {
				margin-bottom: 30px; }

		& .btn-choice-point {
				display: inline-block;
				margin-top: 10px; }

		& .bx-pagination-container {
				& ul {
						display: flex;
						align-items: center;
						gap: 20px; } }

		& .bx-pag-prev,
		& .bx-pag-next {
				& a {
					color: #fff; } } }


.cart-main {
		&__properties-title,
		&__delivery-title,
		&__paysystem-title,
		&__region-title {
				margin-top: 0;
				margin-bottom: 20px; } }
