.product-cards {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 20px;

		@include max-screen(1279px) {
			gap: 10px; }

		@include max-screen(978px) {
			gap: 0; } }

