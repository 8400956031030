.useful-articles {

		&__link-main {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				z-index: 10;

				&:hover ~ .article-card__content .article-card__link {
						background: #F6F5F5;
						color: #24306B; } }

		&__bottom {
				display: flex; }

		&__head {
				display: flex;
				justify-content: space-between;
				margin-bottom: 27px;

				@include max-screen(576px) {
					flex-direction: column;
					align-items: flex-start;
					gap: 20px; } }

		&__cards {
				display: flex;

				@include max-screen(976px) {
					flex-direction: column; }

				&-column {
						max-width: 480px;
						width: 100%;
						display: flex;
						flex-direction: column;

						@include max-screen(976px) {
							flex-direction: row;
							max-width: 100%; }

						@include max-screen(576px) {
							flex-direction: column; } } }

		&__title {
				font-size: 35px;
				font-family: $ffs-m;

				@include max-screen(1024px) {
						font-size: 30px; }

				@include max-screen(576px) {
						font-size: 28px; } }

		&__card {
				width: 100%;
				//height: 50%

				@include max-screen(976px) {
					min-height: 240px;
					padding: 43px 30px;

					& .article-card__content {
						justify-content: flex-start; }

					&.article-card--big {
							justify-content: center;
							align-items: center;
							min-height: 645px;

							@include max-screen(576px) {
								min-height: 240px; }

							& .article-card__content {
									align-items: center;

									@include max-screen(576px) {
										align-items: flex-start; } } } } } }
