.footer {
		background: $c-blue-900;

		&__container {
				max-width: 1437px;
				width: 100%;
				margin: 0 auto; }

		&__top {
				margin-top: 37px; }

		&__middle {
				margin-top: 44px;
				margin-bottom: 57px; }

		&__bottom {
				margin-bottom: 42px;

				@include max-screen(576px) {
						margin-bottom: 0;
						padding-bottom: 30px; } } }


.footer-top {
		display: flex;
		justify-content: space-between;
		margin-bottom: 40px;

		@include max-screen(976px) {
			flex-direction: column {
				gap: 45px; } }

		& picture {
			display: block; }

		&__img {
				min-height: 40px;
				mix-blend-mode: darken;

				@include max-screen(976px) {
					margin-bottom: 30px; }

				@include max-screen(576px) {
					width: 105px;
					height: 22px;
					margin-bottom: 40px; } }

		&__social-icon {
				width: 20px;
				height: 20px;
				color: $c-blue-800;
				transition: .3s; }


		&__social-title {
				font-size: 16px;
				line-height: 20px;
				color: $c-black; }

		&__social-links {
				max-width: 190px;
				width: 100%;
				@include flex-between; }

		&__social-networks {
				max-width: 467px;
				width: 100%;
				@include flex-between;

				@include max-screen(576px) {
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					gap: 20px; } } }


.social-link {
		width: 50px;
		height: 50px;
		border-radius: 50px;
		background: $c-white;
		@include flex-center;
		transition: .3s;
		cursor: pointer;

		&--brown {
				background: $c-dark-400; }

		&:hover {
				background: $c-blue-800; }

		&:hover .footer-top__social-icon {
				color: $c-white; } }


.footer-middle {
		display: flex;
		justify-content: space-between;

		@include max-screen(976px) {
			flex-direction: column; }

		&__columns {
				display: flex;
				max-width: 769px;
				width: 100%;
				justify-content: space-between;

				@include max-screen(1024px) {
						max-width: 550px; }

				@include max-screen(976px) {
						max-width: 100%;
						flex-direction: column; } }

		&__callback-btn {
				background: $c-white;
				border: $c-white;
				gap: 10px; }

		&__callback {
				gap: 23px;
				display: flex;
				flex-direction: column;
				align-items: end;

				& .callback-btn {
						display: flex;
						max-width: 270px;
						padding-top: 10px;
						padding-bottom: 10px; }

				@include max-screen(976px) {
						margin-top: 30px;
						align-items: flex-start; } } }



.footer-column {
		display: flex;
		flex-direction: column;

		@include max-screen(976px) {
				padding-top: 15px;
				padding-bottom: 15px;
				border-top: 1px solid #808080;

				&:last-child {
						border-bottom: 1px solid #808080; } }

		&__title-link {
				position: relative;
				font-family: $ffs-m;
				font-size: 22px;
				line-height: 28px;
				color: $c-black;
				text-decoration: none;
				margin-bottom: 30px;
				transition: margin-bottom .3s linear;

				@include max-screen(976px) {
						margin-bottom: 0;

						&:after {
								content: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='24' width='24' viewBox='0 0 24 24'> <path d='M7.00001 5.17217L11.95 0.222168L13.364 1.63617L7.00001 8.00017L0.636013 1.63617L2.05001 0.222167L7.00001 5.17217Z' fill='%2324306B'/></svg>");
								position: absolute;
								top: 0;
								right: 0;
								width: 24px;
								height: 24px;
								transform: translateY(50%);
								line-height: 24px; }

						&--visible {
								margin-bottom: 20px;

								&:after {
										transform: translate(-10px, 0) rotate(180deg); } } } }

		&__link {
				font-family: $ff-r;
				font-size: 16px;
				line-height: 20px;
				color: $c-black;
				text-decoration: none;
				margin-bottom: 20px;
				transition:  .3s;

				&:hover {
						color: $c-blue-800; }

				@include max-screen(1024px) {
						max-width: 200px; }

				@include max-screen(976px) {
						max-width: 100%;
						max-height: 0;
						margin-bottom: 0;
						overflow: hidden;
						transition: max-height .3s linear, margin .3s linear;

						&--visible {
								max-height: 100px;
								margin-bottom: 10px; } } } }


.footer-middle {

		&__mail {
				display: flex;
				gap: 9px; }

		&__mail-text {
				font-size: 16px;
				line-height: 20px;
				color: #000000; }

		&__mail-link {
				font-size: 16px;
				line-height: 20px;
				text-align: right;
				text-decoration-line: underline;
				color: #24306B; } }


.footer-bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include max-screen(976px) {
			flex-direction: column;
			align-items: flex-start;
			gap: 30px; }

		&__links {
				display: flex;
				max-width: 665px;
				justify-content: space-between;
				width: 100%;

				@include max-screen(976px) {
					flex-direction: column;
					gap: 30px; } }

		&__link {
				transition: .3s;

				&:hover {
						color: $c-blue-800; } }

		&__link,
		&__webest-text {
				font-size: 14px;
				line-height: 18px;
				color: $c-black;
				opacity: 0.5;
				text-decoration: none;

				@include max-screen(576px) {
						font-size: 12px; } }

		&__webest {
				display: flex;
				align-items: center;
				gap: 10px;
				text-decoration: none; }

		&__webest-img {
				color: $c-dark-650;
				width: 98px;
				height: 19px;
				mix-blend-mode: darken;
				margin-bottom: 8px; } }
