.double-card {
		display: flex;

		&__item {
				background: linear-gradient(166.72deg, #D5DFE9 57.72%, #98ACBE 91.26%);
				max-width: 720px;
				width: 100%;
				min-height: 376px;
				position: relative; }

		&__image {
				position: absolute;
				bottom: 0;
				right: 0; }


		&__description {
				background: #BACEE1;
				position: relative;
				padding: 97px 73px;
				width: 100%;
				max-width: 720px; }

		&__title {
				font-size: 28px; } }
