/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide, .swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative; }

/* Auto Height */
.swiper-container-autoheight {
  height: auto; }
  .swiper-container-autoheight .swiper-slide {
    height: auto; }
  .swiper-container-autoheight .swiper-wrapper {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-transition-property: -webkit-transform, height;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -ms-transition-property: -ms-transform;
    transition-property: transform, height; }

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

/* Arrows */
.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat; }

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none; }

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto; }

.swiper-button-prev.swiper-button-black, .swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-prev.swiper-button-white, .swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto; }

.swiper-button-next.swiper-button-black, .swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-white, .swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10; }
  .swiper-pagination.swiper-pagination-hidden {
    opacity: 0; }

/* Common Styles */
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

/* Bullets */
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2; }

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #24306B; }

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff; }

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000; }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  -moz-transform: translate3d(0px, -50%, 0);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0); }
  .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 5px 0;
    display: block; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px; }

/* Progress */
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }
  .swiper-pagination-progress .swiper-pagination-progressbar {
    background: #007aff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top; }

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top; }

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0; }

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0; }

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5); }
  .swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
    background: #fff; }

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000; }

/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px; }
  .swiper-container-3d .swiper-wrapper, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-cube-shadow {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d; }
  .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10; }
  .swiper-container-3d .swiper-slide-shadow-left {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-right {
    background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-top {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-coverflow .swiper-wrapper, .swiper-container-flip .swiper-wrapper {
  -ms-perspective: 1200px; }

.swiper-container-cube, .swiper-container-flip {
  overflow: visible; }

.swiper-container-cube .swiper-slide, .swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1; }

.swiper-container-cube .swiper-slide .swiper-slide, .swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-cube .swiper-slide-active, .swiper-container-flip .swiper-slide-active, .swiper-container-cube .swiper-slide-active .swiper-slide-active, .swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube .swiper-slide-shadow-top, .swiper-container-flip .swiper-slide-shadow-top, .swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right, .swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden; }

/* Cube */
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%; }

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0; }

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-prev, .swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible; }

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0; }

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity; }
  .swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none; }

.swiper-container-fade .swiper-slide-active {
  pointer-events: auto; }
  .swiper-container-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto; }

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center; }
  .swiper-zoom-container img, .swiper-zoom-container svg, .swiper-zoom-container canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; }

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1); }

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%; }

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite; }
  .swiper-lazy-preloader:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 50%;
    -webkit-background-size: 100%;
    background-size: 100%;
    background-repeat: no-repeat; }

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: rgba(0, 0, 0, 0.5); }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0;
  box-sizing: border-box; }
  .mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }
  .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader, .mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  display: none; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent;
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.noUi-target {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative; }
  .noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden; }

/* Wrapper for all connect elements. */
.noUi-connect, .noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat; }

.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto; }

.noUi-vertical .noUi-origin {
  top: -100%;
  width: 0; }

.noUi-horizontal .noUi-origin {
  height: 0; }

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute; }

.noUi-touch-area {
  height: 100%;
  width: 100%; }

.noUi-state-tap .noUi-connect, .noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

.noUi-horizontal {
  height: 18px; }
  .noUi-horizontal .noUi-handle {
    width: 34px;
    height: 28px;
    right: -17px;
    top: -6px; }

.noUi-vertical {
  width: 18px; }
  .noUi-vertical .noUi-handle {
    width: 28px;
    height: 34px;
    right: -6px;
    bottom: -17px; }

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -14px;
  right: auto; }

.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB; }

.noUi-connects {
  border-radius: 3px; }

.noUi-connect {
  background: #3FB8AF; }

/* Handles and cursors; */
.noUi-draggable {
  cursor: ew-resize; }

.noUi-vertical .noUi-draggable {
  cursor: ns-resize; }

.noUi-handle {
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB; }

.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB; }

/* Handle stripes; */
.noUi-handle:before {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px; }

.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px;
  left: 17px; }

.noUi-vertical .noUi-handle:before {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px; }

.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
  top: 17px; }

/* Disabled state; */
[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled].noUi-target, [disabled].noUi-handle, [disabled] .noUi-handle {
  cursor: not-allowed; }

.noUi-pips {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  color: #999; }
  .noUi-pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center; }

.noUi-value-sub {
  color: #ccc;
  font-size: 10px; }

.noUi-marker {
  position: absolute;
  background: #CCC; }

.noUi-marker-sub, .noUi-marker-large {
  background: #AAA; }

.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%; }

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%); }

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%); }

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px; }

.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%; }

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px; }

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%); }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap; }

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%; }

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%; }

.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px; }

.noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px; }

@font-face {
  font-family: "TT Commons Pro Expanded Medium";
  src: url("../fonts/TT Commons Pro Expanded Medium/TT Commons Pro Expanded Medium.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback; }

@font-face {
  font-family: "TT Commons Pro Expanded Regular";
  src: url("../fonts/TT Commons Pro Expanded Regular/TT Commons Pro Expanded Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback; }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor; }

#SVG_container {
  position: absolute;
  height: 0;
  width: 0; }

#SVG_container svg {
  position: absolute;
  height: 0;
  width: 0; }

.icon-arrow-down {
  font-size: 1.2rem;
  width: 1em; }

.icon-arrow-enter {
  font-size: 1.4rem;
  width: 1.21429em; }

.icon-arrow-input {
  font-size: 0.9rem;
  width: 1em; }

.icon-arrow-left {
  font-size: 1.4rem;
  width: 1.21429em; }

.icon-arrow-right {
  font-size: 1.4rem;
  width: 1.21429em; }

.icon-arrow-select {
  font-size: 0.9rem;
  width: 1em; }

.icon-arrow-size {
  font-size: 1.6rem;
  width: 1.8125em; }

.icon-ball {
  font-size: 0.8rem;
  width: 1em; }

.icon-basket {
  font-size: 2.8rem;
  width: 1em; }

.icon-bed {
  font-size: 3.4rem;
  width: 1em; }

.icon-bread-crumb-arrow {
  font-size: 0.9rem;
  width: 0.77778em; }

.icon-callback {
  font-size: 3.2rem;
  width: 1em; }

.icon-chart-price {
  font-size: 1.2rem;
  width: 1.5em; }

.icon-check {
  font-size: 0.7rem;
  width: 1.28571em; }

.icon-check-marks {
  font-size: 1.5rem;
  width: 1.86667em; }

.icon-check2 {
  font-size: 0.8rem;
  width: 1.375em; }

.icon-close {
  font-size: 1.3rem;
  width: 1em; }

.icon-compare {
  font-size: 2.3rem;
  width: 1.13043em; }

.icon-dash {
  font-size: 0.2rem;
  width: 7.5em; }

.icon-delivery {
  font-size: 2.2rem;
  width: 1.09091em; }

.icon-dif-hard {
  font-size: 1.9rem;
  width: 1em; }

.icon-down {
  font-size: 0.9rem;
  width: 1.55556em; }

.icon-eurostandart {
  font-size: 2.6rem;
  width: 0.96154em; }

.icon-favorites {
  font-size: 2.2rem;
  width: 1.09091em; }

.icon-favorites-2 {
  font-size: 1.6rem;
  width: 1.125em; }

.icon-hard {
  font-size: 1.9rem;
  width: 1em; }

.icon-hight {
  font-size: 1.9rem;
  width: 0.89474em; }

.icon-insta {
  font-size: 2rem;
  width: 1em; }

.icon-left {
  font-size: 1.4rem;
  width: 0.64286em; }

.icon-list {
  font-size: 1.3rem;
  width: 1.07692em; }

.icon-logo {
  font-size: 6.7rem;
  width: 2.79104em; }

.icon-loupe {
  font-size: 1.7rem;
  width: 1.05882em; }

.icon-menu {
  font-size: 9.6rem;
  width: 1em; }

.icon-ok {
  font-size: 1.9rem;
  width: 1.05263em; }

.icon-pay-card {
  font-size: 3rem;
  width: 1em; }

.icon-percent {
  font-size: 2rem;
  width: 1em; }

.icon-personal {
  font-size: 2.3rem;
  width: 0.86957em; }

.icon-phone {
  font-size: 2.4rem;
  width: 1em; }

.icon-point-map {
  font-size: 2rem;
  width: 1em; }

.icon-quality {
  font-size: 2.6rem;
  width: 0.96154em; }

.icon-quest {
  font-size: 2.3rem;
  width: 1em; }

.icon-right {
  font-size: 1.4rem;
  width: 0.64286em; }

.icon-rigidity {
  font-size: 1.9rem;
  width: 1em; }

.icon-spring {
  font-size: 1.9rem;
  width: 0.78947em; }

.icon-star {
  font-size: 2.6rem;
  width: 1.03846em; }

.icon-star-empty {
  font-size: 1.8rem;
  width: 1.05556em; }

.icon-star-fill {
  font-size: 1.8rem;
  width: 1.05556em; }

.icon-subway {
  font-size: 1.2rem;
  width: 1.41667em; }

.icon-table {
  font-size: 2.8rem;
  width: 1em; }

.icon-top {
  font-size: 0.9rem;
  width: 1.55556em; }

.icon-vk {
  font-size: 1.2rem;
  width: 1.83333em; }

.icon-webest {
  font-size: 1.9rem;
  width: 5.15789em; }

.icon-youtube {
  font-size: 1.4rem;
  width: 1.42857em; }

body {
  margin: 0;
  padding: 0;
  font-family: "TT Commons Pro Expanded Regular";
  line-height: 1.3;
  font-size: 15px;
  color: #000000;
  overflow-x: hidden; }
  body * {
    box-sizing: border-box;
    font-family: "TT Commons Pro Expanded Regular"; }

.body--overlay {
  position: relative;
  z-index: 0; }
  .body--overlay:before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    height: 100%;
    margin: 0 0 0 -50vw;
    background: #333; }

.body--no-scroll {
  overflow: hidden; }

.body--fix {
  position: fixed;
  left: 0;
  right: 0; }

.container {
  max-width: 1748px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden; }
  @media only screen and (max-width: 1280px) {
    .container {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 1024px) {
    .container {
      padding-left: 32px;
      padding-right: 32px; } }
  @media only screen and (max-width: 768px) {
    .container {
      padding-left: 15px;
      padding-right: 15px; } }
  .container--min {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0; }
  .container--narrow {
    max-width: 947px;
    width: 100%;
    margin: 0 auto; }
  .container--middle {
    max-width: 1560px;
    width: 100%;
    margin: 0 auto; }

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.page {
  flex: 1;
  position: relative; }
  .page:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;
    transition: background-color ease .2s; }
  .page--hover:before {
    background-color: rgba(34, 34, 34, 0.3); }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

button {
  margin: 0;
  padding: 0; }

svg {
  pointer-events: none; }

input[type='checkbox'] {
  cursor: pointer; }

a {
  text-decoration: none;
  color: #000; }

.select-city__dropdown-wrap {
  top: 40px; }

.js-comparison-slider {
  width: calc(100% - 5px); }

.change-point-issue {
  max-width: 300px;
  margin-bottom: 20px; }

button svg,
a svg {
  pointer-events: none; }

.select-city__close {
  top: 20px;
  right: 20px; }

.popup-window-close-icon:after {
  width: 15px;
  height: 15px; }

.input-group--one-third.form-group.bx-soa-customer-field .input-group__error {
  display: none; }

.input-group--one-third.form-group.bx-soa-customer-field.input-group--error .input-group__error {
  display: block; }

@media only screen and (max-width: 576px) {
  .select-city__close {
    width: 10px;
    height: 10px; } }

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh; }

.page {
  flex: 1; }
  .page--background {
    background: #D5DFE9; }

.btn {
  text-decoration: none;
  border: none;
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  box-shadow: none; }
  .btn--long {
    display: flex;
    justify-content: space-between;
    max-width: 476px;
    width: 100%;
    border-radius: 50px;
    background: #F6F5F5;
    padding: 25px 30px;
    color: #24306B;
    font-size: 14px; }
  .btn:disabled {
    background: #EDEDED;
    cursor: not-allowed; }
    .btn:disabled:hover {
      border: 1px solid transparent; }
  .btn--empty {
    color: #24306B;
    display: flex;
    overflow: hidden; }
  .btn--primary {
    font-family: "TT Commons Pro Expanded Medium";
    font-style: normal;
    border-radius: 50px;
    background: #fff;
    color: #000;
    transition: .3s;
    text-align: center;
    padding: 12px 0; }
    .btn--primary:hover {
      background: #24306B;
      color: #fff; }
  .btn--min {
    font-family: "TT Commons Pro Expanded Regular";
    color: #fff;
    padding: 10px 20px;
    background: #24306B;
    border-radius: 50px;
    text-align: center;
    transition: .3s; }
    @media only screen and (max-width: 576px) {
      .btn--min {
        padding: 10px 15px; } }
    .btn--min:hover {
      background: #F6F5F5;
      color: #24306B; }
  .btn--dark {
    background: #F6F5F5;
    font-family: "TT Commons Pro Expanded Regular";
    color: #24306B;
    padding: 10px 20px;
    border-radius: 50px;
    text-align: center; }
  .btn--white-main {
    background: #fff;
    border-radius: 50px;
    color: #24306B;
    transition: .3s; }
    .btn--white-main:hover {
      background: #24306B;
      color: #fff; }
  .btn--white {
    text-decoration: none;
    font-family: "TT Commons Pro Expanded Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    padding: 10px 15px;
    border: 1px solid #EDEDED;
    border-radius: 50px;
    transition: .3s; }
    .btn--white:hover {
      border: 1px solid #24306B; }
  .btn--sort {
    font-size: 14px;
    color: #000;
    opacity: 0.5;
    max-width: max-content;
    display: flex;
    align-items: center;
    padding: 0;
    gap: 8px; }
  .btn--active {
    opacity: 1; }
  .btn__icon {
    width: 18px;
    height: 12px; }
  .btn--material {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 5px; }
    .btn--material:hover .btn__material-img {
      border: 1px solid #808080; }
  .btn__material-text {
    font-size: 12px;
    text-align: center; }
  .btn__material-img {
    padding: 5px;
    width: 68px;
    height: 68px;
    border: 1px solid transparent;
    border-radius: 50px;
    transition: border .2s; }
    .btn__material-img--active {
      border: 1px solid #000; }

.btn-reset {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px; }
  .btn-reset__icon {
    width: 11px;
    height: 11px;
    color: #24306B; }
  .btn-reset__text {
    font-size: 14px;
    color: #24306B; }

.button-show-more {
  width: 100%;
  padding: 15px 0;
  background: #F6F5F5;
  border-radius: 50px;
  color: #24306B;
  font-size: 14px;
  transition: .3s; }
  .button-show-more:hover {
    background: #24306B;
    color: #F6F5F5; }
  .button-show-more--hide {
    display: none; }

.btn-show-card {
  padding: 15px 89px;
  margin: 0 auto 60px;
  display: flex; }

.btn-close {
  background: none;
  border: none;
  display: flex;
  cursor: pointer; }
  .btn-close__icon {
    width: 11px;
    height: 11px;
    color: #24306B; }

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-primary {
  background-color: #0d6efd;
  border-color: #0d6efd; }

.input-group__error {
  color: #FC6464;
  font-size: 12px; }

.input-group--error .input {
  background: #FFE1E1; }

.input-group--error .input-group__error {
  padding-top: 10px; }

.input::-webkit-input-placeholder {
  color: rgba(140, 140, 140, 0.5);
  transition: color ease .3s; }

.input:-ms-input-placeholder {
  color: rgba(140, 140, 140, 0.5);
  transition: color ease .3s; }

.input::-moz-placeholder {
  color: rgba(140, 140, 140, 0.5);
  transition: color ease .3s; }

.input:-moz-placeholder {
  color: rgba(140, 140, 140, 0.5);
  transition: color ease .3s; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 32px #E7EAF4 inset !important;
  color: #8C8C8C; }

.input {
  background: #E7EAF4;
  color: #000000;
  padding: 19px 15px;
  min-height: 55px;
  border: none;
  box-shadow: none;
  outline: none;
  font-size: 14px;
  width: 100%;
  transition: background ease .3s; }
  .input:hover:not(:disabled) {
    background: #D3D9EA; }
    .input:hover:not(:disabled)::-webkit-input-placeholder {
      color: #8C8C8C; }
    .input:hover:not(:disabled):-ms-input-placeholder {
      color: #8C8C8C; }
    .input:hover:not(:disabled)::-moz-placeholder {
      color: #8C8C8C; }
    .input:hover:not(:disabled):-moz-placeholder {
      color: #8C8C8C; }
  .input:focus:not(:disabled) {
    background: #D3D9EA; }
    .input:focus:not(:disabled)::-webkit-input-placeholder {
      color: #8C8C8C; }
    .input:focus:not(:disabled):-ms-input-placeholder {
      color: #8C8C8C; }
    .input:focus:not(:disabled)::-moz-placeholder {
      color: #8C8C8C; }
    .input:focus:not(:disabled):-moz-placeholder {
      color: #8C8C8C; }
  .input:disabled {
    cursor: not-allowed;
    background: #F6F7F9 !important;
    border: 1px solid rgba(0, 0, 0, 0.1); }

.checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer; }
  .checkbox [type="checkbox"] {
    display: none; }
    .checkbox [type="checkbox"]:checked ~ .checkbox__indicator {
      background: #000000; }
      .checkbox [type="checkbox"]:checked ~ .checkbox__indicator .icon {
        transform: scale(1); }
    .checkbox [type="checkbox"]:disabled ~ .checkbox__indicator {
      cursor: not-allowed;
      background: #F6F7F9 !important;
      border: 1px solid rgba(0, 0, 0, 0.1); }
    .checkbox [type="checkbox"]:disabled ~ .checkbox__description {
      color: rgba(0, 0, 0, 0.6);
      cursor: not-allowed; }
  .checkbox__indicator {
    display: block;
    height: 19px;
    width: 19px;
    min-width: 19px;
    border: 1px solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    transition: background ease .3s; }
    .checkbox__indicator .icon {
      transition: transform ease .3s;
      transform: scale(0);
      color: #ffffff !important;
      font-size: 7px; }
  .checkbox__description {
    font-size: 12px;
    color: #000000;
    padding: 0 14px;
    flex: 1;
    user-select: none; }

.radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer; }
  .radio--text {
    align-items: flex-start; }
  .radio__title {
    font-size: 16px; }
  .radio__text {
    opacity: 0.5;
    font-size: 13px; }
  .radio [type="radio"], .radio [type="checkbox"] {
    display: none; }
    .radio [type="radio"]:checked ~ .radio__indicator:after, .radio [type="checkbox"]:checked ~ .radio__indicator:after {
      top: 5px;
      bottom: 5px;
      left: 5px;
      right: 5px; }
    .radio [type="radio"]:disabled ~ .radio__indicator, .radio [type="checkbox"]:disabled ~ .radio__indicator {
      cursor: not-allowed;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
      background: rgba(0, 0, 0, 0.1); }
      .radio [type="radio"]:disabled ~ .radio__indicator:after, .radio [type="checkbox"]:disabled ~ .radio__indicator:after {
        background: #F6F7F9 !important; }
    .radio [type="radio"]:disabled ~ .radio__description, .radio [type="checkbox"]:disabled ~ .radio__description {
      color: rgba(0, 0, 0, 0.6);
      cursor: not-allowed; }
  .radio__indicator {
    background: #000000;
    display: flex;
    height: 19px;
    width: 19px;
    min-width: 19px;
    border: 1px solid #000000;
    border-radius: 50%;
    overflow: hidden;
    position: relative; }
    .radio__indicator:after {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      content: "";
      background: #ffffff;
      display: block;
      position: absolute;
      transition: top ease 0.3s, bottom ease 0.3s, left ease 0.3s, right ease 0.3s; }
  .radio__description {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #000000;
    padding: 0 14px;
    flex: 1;
    user-select: none; }

.textarea {
  min-height: 110px;
  min-width: 100%;
  max-width: 100%;
  width: 100% !important; }

.form-default__row {
  display: flex;
  margin: 0 -8px; }

.form-default__column {
  width: calc(100% / 2 - 16px);
  margin: 0 8px; }

.form-default .input-group {
  margin-bottom: 27px; }

.form-default__send {
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .form-default__send button {
    margin-right: 50px; }
    .form-default__send button span {
      white-space: nowrap; }

.form-default__consent {
  display: flex;
  align-items: center; }
  .form-default__consent a {
    color: #000000; }
    .form-default__consent a:hover {
      text-decoration: none; }

.textarea {
  padding: 20px;
  border: 1px solid #F0F0F0;
  resize: none;
  border-radius: 20px; }

.input-groups {
  display: flex;
  flex-direction: column;
  gap: 10px; }
  .input-groups--order-comment {
    margin-top: 10px; }

.seo-title {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0; }

.animated {
  animation-duration: .3s;
  animation-fill-mode: both; }

.fadeInUp {
  animation-name: fadeInUp; }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 10px, 0); }
  100% {
    opacity: 1;
    transform: none; } }

.fadeOutUp {
  animation-name: fadeOutUp; }

@keyframes fadeOutUp {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translate3d(0, -20px, 0); } }

.fadeInDown {
  animation-name: fadeInDown; }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -30px, 0); }
  100% {
    opacity: 1;
    transform: none; } }

.header__burger-menu {
  display: none;
  overflow-y: auto;
  overflow-x: hidden; }
  .header__burger-menu .container {
    overflow: visible; }
  @media only screen and (max-width: 976px) {
    .header__burger-menu {
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100vh;
      background-color: #fff;
      z-index: 1000;
      transform: translateX(-100%);
      transition: transform .3s linear; }
      .header__burger-menu--visible {
        transform: none; } }

.header__burger-menu-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 22px;
  padding-bottom: 22px;
  border-bottom: 1px solid #F6F5F5; }

.header__burger-menu-bottom {
  position: relative;
  padding-top: 22px;
  padding-bottom: 25px; }
  .header__burger-menu-bottom .header-navigation__personal-button {
    display: flex;
    width: 300px;
    max-width: 300px;
    margin-bottom: 60px; }
  .header__burger-menu-bottom .header-main__callback-btn {
    display: flex; }

.header__burger-menu-bottom-start-tab {
  transition: transform .3s linear, opacity .3s linear; }
  .header__burger-menu-bottom-start-tab--hidden {
    display: none;
    transform: translateX(-100%);
    opacity: 0; }

.header__burger-menu-category-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transform: translateX(100%);
  transition: transform .3s linear, opacity .3s linear; }
  .header__burger-menu-category-item--visible {
    opacity: 1;
    transform: none; }
  .header__burger-menu-category-item--none {
    display: none; }
  .header__burger-menu-category-item-body {
    padding-bottom: 25px; }
    .header__burger-menu-category-item-body .header-main__callback-btn {
      margin-left: auto;
      margin-right: auto; }
    .header__burger-menu-category-item-body-inner {
      display: flex;
      flex-direction: column;
      gap: 27px;
      margin-bottom: 25px; }
  .header__burger-menu-category-item-title {
    display: flex;
    align-items: center;
    padding: 21px 23px;
    margin-bottom: 22px;
    border-bottom: 1px solid #F6F5F5; }
    .header__burger-menu-category-item-title .btn {
      padding: 0; }
    .header__burger-menu-category-item-title span {
      display: block;
      margin-left: auto;
      margin-right: auto;
      font-size: 16px;
      font-weight: 500; }

.header__burger-menu-adress {
  display: flex;
  flex-direction: column;
  gap: 27px;
  margin-bottom: 27px; }

.header__burger-menu-nav {
  padding-bottom: 27px;
  margin-bottom: 27px;
  border-bottom: 1px solid #F6F5F5; }

.header__burger-menu-personal-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 27px;
  padding-bottom: 27px;
  margin-bottom: 27px;
  border-bottom: 1px solid #F6F5F5; }
  .header__burger-menu-personal-section .personal-entrance,
  .header__burger-menu-personal-section .personal-goods__favorites-link,
  .header__burger-menu-personal-section .personal-goods__compare-link {
    display: flex; }

.header__burger-menu-category {
  display: flex;
  flex-direction: column;
  gap: 27px;
  margin-bottom: 27px; }
  .header__burger-menu-category-btn {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .header__burger-menu-category-btn span {
      pointer-events: none; }
  .header__burger-menu-category-link {
    max-width: 330px;
    justify-content: space-between; }

.header__burger-menu-stocks {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 27px; }
  @media only screen and (max-width: 576px) {
    .header__burger-menu-stocks {
      grid-template-columns: 1fr; } }

.header__burger {
  display: none; }
  @media only screen and (max-width: 976px) {
    .header__burger {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 28px;
      height: 28px;
      padding: 5px 3px; } }
  .header__burger span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 5px;
    background-color: #24306B;
    pointer-events: none; }

.header__burger-close {
  width: 28px;
  height: 28px;
  padding: 5px 3px; }

.header--fixed {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 5; }

.header__top {
  padding: 10px 0;
  background: #F6F5F5;
  margin-bottom: 26px; }
  @media only screen and (max-width: 1000px) {
    .header__top {
      display: none; } }

.header__navigation {
  position: relative; }

.header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.nav-city {
  display: flex;
  align-items: center; }
  .nav-city__count {
    display: flex;
    align-items: center;
    gap: 3px; }
  .nav-city__area {
    display: flex; }
  .nav-city .span {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    opacity: 0.5; }
  .nav-city__city {
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    margin-right: 15px; }

.city-area {
  text-decoration: none;
  color: #000; }
  .city-area__img {
    height: 16px;
    width: 16px;
    color: #24306B;
    margin-right: 5px; }

.header-nav-links {
  display: flex;
  gap: 37px; }
  @media only screen and (max-width: 1280px) {
    .header-nav-links {
      gap: 30px; } }
  @media only screen and (max-width: 1140px) {
    .header-nav-links {
      gap: 10px; } }
  @media only screen and (max-width: 976px) {
    .header-nav-links {
      flex-direction: column;
      gap: 27px; } }
  .header-nav-links__link {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    text-decoration: none; }
    @media only screen and (max-width: 976px) {
      .header-nav-links__link {
        font-size: 16px; } }

.logo {
  display: block;
  object-fit: contain; }
  .logo .img {
    width: 173px;
    height: 67px; }
    @media only screen and (max-width: 576px) {
      .logo .img {
        width: 104px;
        height: 22px; } }

.search-string {
  position: relative;
  align-items: center;
  display: flex;
  padding: 9px 20px;
  gap: 14px;
  background: #F6F5F5;
  border-radius: 50px;
  max-width: 799px;
  width: 100%; }
  @media only screen and (max-width: 1289px) {
    .search-string {
      max-width: 300px; } }
  @media only screen and (max-width: 1160px) {
    .search-string {
      max-width: 126px; }
      .search-string form {
        padding-left: 0;
        padding-right: 0; } }
  @media only screen and (max-width: 976px) {
    .search-string {
      display: none;
      max-width: 100%; } }
  .search-string__icon {
    width: 16px;
    height: 16px;
    color: #24306B; }
  .search-string__input {
    max-width: 799px;
    width: 100%;
    outline: none;
    border: none;
    background: #F6F5F5; }
  .search-string__content {
    position: absolute;
    width: 100%;
    top: 70px;
    left: 0;
    z-index: 3;
    background: #fff; }
  .search-string:hover .search-content {
    display: flex; }

.search-content {
  display: none;
  flex-direction: column;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 10px 31px rgba(0, 0, 0, 0.12); }
  .search-content--open {
    display: flex; }
  .search-content__title {
    font-size: 16px;
    font-family: "TT Commons Pro Expanded Medium";
    color: #000;
    margin-bottom: 33px; }
  .search-content__item {
    margin-bottom: 25px; }

.search-item {
  display: flex;
  gap: 19px; }
  .search-item__icon {
    width: 17px;
    height: 17px;
    color: #808080; }
  .search-item__text {
    font-size: 16px; }

.search-category {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  border-top: 1px solid #C4C4C4;
  border-bottom: 1px solid #C4C4C4; }
  .search-category:hover .search-category__text {
    color: #24306B; }
  .search-category__text {
    color: #000;
    font-size: 16px;
    font-family: "TT Commons Pro Expanded Medium"; }
  .search-category__icon {
    width: 11px;
    height: 11px;
    color: #24306B;
    transform: rotate(270deg); }

.header-bottom__main {
  margin-bottom: 15px; }

.header-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media only screen and (max-width: 976px) {
    .header-navigation {
      display: none; } }
  .header-navigation__personal-button {
    max-width: 340px;
    width: 100%; }
    @media only screen and (max-width: 1450px) {
      .header-navigation__personal-button {
        max-width: 134px; } }
    @media only screen and (max-width: 1200px) {
      .header-navigation__personal-button {
        display: none; } }

.header-main {
  display: flex;
  justify-content: space-between;
  gap: 97px; }
  @media only screen and (max-width: 1100px) {
    .header-main {
      gap: 45px; } }
  @media only screen and (max-width: 976px) {
    .header-main {
      margin-top: 10px;
      margin-bottom: 10px;
      justify-content: space-between; } }
  @media only screen and (max-width: 576px) {
    .header-main {
      margin-top: 22px;
      margin-bottom: 22px; } }
  .header-main__content-min {
    display: flex;
    max-width: 363px;
    width: 100%;
    gap: 27px; }
  .header-main__personal-area {
    margin-top: 18px; }
  .header-main__info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px; }
  .header-main__main-section {
    display: flex;
    gap: 32px;
    width: 100%; }
    @media only screen and (max-width: 976px) {
      .header-main__main-section {
        width: auto; } }
    .header-main__main-section--top {
      margin-top: 14px;
      margin-bottom: 18px; }

.callback-btn {
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  box-shadow: none;
  max-width: 256px;
  width: 100%;
  border: 1px solid #EDEDED;
  border-radius: 50px;
  display: flex;
  justify-content: space-evenly;
  padding: 5px 20px 5px 13px;
  align-items: center;
  transition: .3s; }
  @media only screen and (max-width: 976px) {
    .callback-btn {
      display: none; } }
  .callback-btn:hover {
    background: #24306B; }
  .callback-btn:hover .callback-btn__img {
    color: #fff; }
  .callback-btn:hover .callback-btn__number {
    color: #fff; }
  .callback-btn:hover .callback-btn__text {
    color: #fff; }
  .callback-btn--footer {
    max-width: none; }
  .callback-btn__image {
    width: 50px;
    height: 50px;
    background: #24306B;
    border-radius: 50px; }
  .callback-btn__img {
    width: 26px;
    color: #24306B;
    transition: .3s; }
    .callback-btn__img--bottom {
      width: 19px;
      color: #fff; }
  .callback-btn__info {
    display: flex;
    flex-direction: column;
    text-align: left; }
  .callback-btn__number {
    font-size: 18px;
    line-height: 22px;
    color: #000;
    transition: .3s; }
  .callback-btn__text {
    font-size: 14px;
    line-height: 18px;
    color: #24306B;
    transition: .3s; }

.personal-entrance {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: #000;
  background: none;
  border: none;
  cursor: pointer; }
  @media only screen and (max-width: 976px) {
    .personal-entrance {
      display: none; } }

.personal-area {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 37px;
  max-width: 340px;
  width: 100%; }
  @media only screen and (max-width: 1024px) {
    .personal-area {
      max-width: 300px; } }
  @media only screen and (max-width: 976px) {
    .personal-area {
      width: auto; } }
  .personal-area__personal-img {
    width: 24px;
    height: 24px;
    color: #24306B; }
  .personal-area__personal-section {
    display: flex;
    justify-content: space-between;
    margin-right: 27px; }
    @media only screen and (max-width: 976px) {
      .personal-area__personal-section {
        margin-right: 0; } }
  .personal-area__personal-button {
    margin-bottom: 17px; }
  .personal-area__personal-text {
    transition: .3s; }
    .personal-area__personal-text:hover {
      color: #3B4FB0; }

.personal-goods {
  display: flex;
  gap: 37px; }
  @media only screen and (max-width: 576px) {
    .personal-goods {
      gap: 18px; } }
  .personal-goods__icon {
    position: relative; }
    .personal-goods__icon:hover .icon {
      color: #3B4FB0; }
  .personal-goods__count {
    position: absolute;
    right: -21px;
    top: -13px;
    background: #F6F5F5;
    border-radius: 50px;
    font-size: 12px;
    line-height: 15px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 29px;
    height: 21px;
    padding-top: 2px; }
  .personal-goods__compare, .personal-goods__favorites, .personal-goods__basket {
    width: 24px;
    height: 24px;
    color: #24306B; }
  @media only screen and (max-width: 976px) {
    .personal-goods__compare {
      margin-right: 8px; } }
  @media only screen and (max-width: 976px) {
    .personal-goods__favorites {
      margin-right: 8px; } }
  .personal-goods__search {
    display: none;
    padding: 0; }
    @media only screen and (max-width: 976px) {
      .personal-goods__search {
        display: block; } }
  @media only screen and (max-width: 976px) {
    .personal-goods__compare-link, .personal-goods__favorites-link {
      display: none; } }

.button-enter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none; }
  .button-enter--min {
    padding: 15px 70px;
    justify-content: center;
    align-items: center;
    gap: 10px; }
    @media only screen and (max-width: 576px) {
      .button-enter--min {
        width: 100%;
        font-size: 14px; } }
  .button-enter__icon {
    height: 17px;
    width: 17px; }
  .button-enter__span {
    font-size: 14px; }

.header-links-img__links {
  display: flex; }

.header-tab {
  border-bottom: 4px solid transparent;
  padding: 17px 0; }
  .header-tab:hover {
    border-bottom: 4px solid #24306B; }
  .header-tab:hover .header-tabs__tab {
    color: #24306B; }
  .header-tab__wrapper {
    padding-top: 64px;
    border-top: 1px solid #EDEDED;
    transition: .3s;
    opacity: 0;
    width: 100vw;
    min-height: 536px;
    background: #fff;
    position: absolute;
    top: 59px;
    left: 0;
    z-index: 3;
    pointer-events: none; }

.header-tab:hover .header-tab__wrapper {
  opacity: 1;
  pointer-events: all; }

.header-tabs {
  max-width: 965px;
  width: 100%; }
  @media only screen and (max-width: 1280px) {
    .header-tabs {
      max-width: 100%; } }
  .header-tabs__inner {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%; }
    @media only screen and (max-width: 1280px) {
      .header-tabs__inner {
        gap: 20px;
        justify-content: flex-start; } }
    @media only screen and (max-width: 1200px) {
      .header-tabs__inner {
        gap: 10px;
        justify-content: space-between; } }
  .header-tabs__tab {
    text-decoration: none;
    font-family: "TT Commons Pro Expanded Regular";
    font-size: 17px;
    line-height: 21px;
    color: #000;
    transition: .2s; }

.tab-wrapper {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 1280px) {
    .tab-wrapper {
      flex-wrap: wrap; } }
  .tab-wrapper__btn-close {
    margin-left: auto;
    margin-bottom: 20px;
    margin-top: 25px; }
  .tab-wrapper__item {
    margin-bottom: 30px; }
  .tab-wrapper__columns {
    display: flex;
    gap: 80px;
    width: 100%; }
  .tab-wrapper__links {
    display: flex;
    width: 100%;
    max-width: 570px;
    justify-content: space-between; }
    @media only screen and (max-width: 1280px) {
      .tab-wrapper__links {
        margin-top: 60px; } }
  .tab-wrapper__categories {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%; }

.category-item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 486px; }
  .category-item__links {
    gap: 10px;
    display: flex;
    flex-wrap: wrap; }

.btn-long__icon {
  color: #24306B;
  width: 16px;
  height: 16px; }

.button-close {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  max-width: 96px;
  width: 100%; }
  .button-close__text {
    font-size: 14px;
    color: #24306B; }
  .button-close__icon {
    width: 11px;
    height: 11px;
    color: #24306B; }

.footer {
  background: #D5DFE9; }
  .footer__container {
    max-width: 1437px;
    width: 100%;
    margin: 0 auto; }
  .footer__top {
    margin-top: 37px; }
  .footer__middle {
    margin-top: 44px;
    margin-bottom: 57px; }
  .footer__bottom {
    margin-bottom: 42px; }
    @media only screen and (max-width: 576px) {
      .footer__bottom {
        margin-bottom: 0;
        padding-bottom: 30px; } }

.footer-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px; }
  @media only screen and (max-width: 976px) {
    .footer-top {
      flex-direction: column;
        flex-direction-gap: 45px; } }
  .footer-top picture {
    display: block; }
  .footer-top__img {
    min-height: 40px;
    mix-blend-mode: darken; }
    @media only screen and (max-width: 976px) {
      .footer-top__img {
        margin-bottom: 30px; } }
    @media only screen and (max-width: 576px) {
      .footer-top__img {
        width: 105px;
        height: 22px;
        margin-bottom: 40px; } }
  .footer-top__social-icon {
    width: 20px;
    height: 20px;
    color: #24306B;
    transition: .3s; }
  .footer-top__social-title {
    font-size: 16px;
    line-height: 20px;
    color: #000; }
  .footer-top__social-links {
    max-width: 190px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .footer-top__social-networks {
    max-width: 467px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 576px) {
      .footer-top__social-networks {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px; } }

.social-link {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
  cursor: pointer; }
  .social-link--brown {
    background: #F6F5F5; }
  .social-link:hover {
    background: #24306B; }
  .social-link:hover .footer-top__social-icon {
    color: #fff; }

.footer-middle {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 976px) {
    .footer-middle {
      flex-direction: column; } }
  .footer-middle__columns {
    display: flex;
    max-width: 769px;
    width: 100%;
    justify-content: space-between; }
    @media only screen and (max-width: 1024px) {
      .footer-middle__columns {
        max-width: 550px; } }
    @media only screen and (max-width: 976px) {
      .footer-middle__columns {
        max-width: 100%;
        flex-direction: column; } }
  .footer-middle__callback-btn {
    background: #fff;
    border: #fff;
    gap: 10px; }
  .footer-middle__callback {
    gap: 23px;
    display: flex;
    flex-direction: column;
    align-items: end; }
    .footer-middle__callback .callback-btn {
      display: flex;
      max-width: 270px;
      padding-top: 10px;
      padding-bottom: 10px; }
    @media only screen and (max-width: 976px) {
      .footer-middle__callback {
        margin-top: 30px;
        align-items: flex-start; } }

.footer-column {
  display: flex;
  flex-direction: column; }
  @media only screen and (max-width: 976px) {
    .footer-column {
      padding-top: 15px;
      padding-bottom: 15px;
      border-top: 1px solid #808080; }
      .footer-column:last-child {
        border-bottom: 1px solid #808080; } }
  .footer-column__title-link {
    position: relative;
    font-family: "TT Commons Pro Expanded Medium";
    font-size: 22px;
    line-height: 28px;
    color: #000;
    text-decoration: none;
    margin-bottom: 30px;
    transition: margin-bottom .3s linear; }
    @media only screen and (max-width: 976px) {
      .footer-column__title-link {
        margin-bottom: 0; }
        .footer-column__title-link:after {
          content: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='24' width='24' viewBox='0 0 24 24'> <path d='M7.00001 5.17217L11.95 0.222168L13.364 1.63617L7.00001 8.00017L0.636013 1.63617L2.05001 0.222167L7.00001 5.17217Z' fill='%2324306B'/></svg>");
          position: absolute;
          top: 0;
          right: 0;
          width: 24px;
          height: 24px;
          transform: translateY(50%);
          line-height: 24px; }
        .footer-column__title-link--visible {
          margin-bottom: 20px; }
          .footer-column__title-link--visible:after {
            transform: translate(-10px, 0) rotate(180deg); } }
  .footer-column__link {
    font-family: "TT Commons Pro Expanded Regular";
    font-size: 16px;
    line-height: 20px;
    color: #000;
    text-decoration: none;
    margin-bottom: 20px;
    transition: .3s; }
    .footer-column__link:hover {
      color: #24306B; }
    @media only screen and (max-width: 1024px) {
      .footer-column__link {
        max-width: 200px; } }
    @media only screen and (max-width: 976px) {
      .footer-column__link {
        max-width: 100%;
        max-height: 0;
        margin-bottom: 0;
        overflow: hidden;
        transition: max-height .3s linear, margin .3s linear; }
        .footer-column__link--visible {
          max-height: 100px;
          margin-bottom: 10px; } }

.footer-middle__mail {
  display: flex;
  gap: 9px; }

.footer-middle__mail-text {
  font-size: 16px;
  line-height: 20px;
  color: #000000; }

.footer-middle__mail-link {
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  text-decoration-line: underline;
  color: #24306B; }

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media only screen and (max-width: 976px) {
    .footer-bottom {
      flex-direction: column;
      align-items: flex-start;
      gap: 30px; } }
  .footer-bottom__links {
    display: flex;
    max-width: 665px;
    justify-content: space-between;
    width: 100%; }
    @media only screen and (max-width: 976px) {
      .footer-bottom__links {
        flex-direction: column;
        gap: 30px; } }
  .footer-bottom__link {
    transition: .3s; }
    .footer-bottom__link:hover {
      color: #24306B; }
  .footer-bottom__link, .footer-bottom__webest-text {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    opacity: 0.5;
    text-decoration: none; }
    @media only screen and (max-width: 576px) {
      .footer-bottom__link, .footer-bottom__webest-text {
        font-size: 12px; } }
  .footer-bottom__webest {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none; }
  .footer-bottom__webest-img {
    color: #C7C7C7;
    width: 98px;
    height: 19px;
    mix-blend-mode: darken;
    margin-bottom: 8px; }

.modals {
  display: none; }

.mfp-close {
  display: none !important; }

.mfp-bg {
  background: rgba(39, 62, 92, 0.45); }

.modal {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 55px 76px 40px;
  background-color: #fff;
  color: #000000;
  position: relative;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.32);
  background-image: url("/img/11.png"); }
  .modal--message {
    max-width: 520px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 20px 70px;
    overflow: hidden; }
    .modal--message .modal__description {
      max-width: 325px;
      margin-top: 25px;
      text-align: center; }
  .modal__close {
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
    box-shadow: none;
    position: absolute;
    top: 17px;
    right: 17px;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center; }
    @media only screen and (max-width: 576px) {
      .modal__close {
        top: 10px;
        right: 10px; } }
    .modal__close svg {
      pointer-events: none; }
    .modal__close .icon {
      font-size: 10px;
      color: #24306B;
      width: 11px;
      height: 11px; }
  .modal__title {
    font-size: 20px;
    line-height: 25px;
    color: #24306B;
    margin-bottom: 20px;
    font-family: "TT Commons Pro Expanded Medium";
    text-align: start; }
  .modal__description {
    font-size: 15px;
    margin: 0 auto;
    line-height: 1.5; }
  .modal__content {
    margin-top: 45px; }
  .modal .form-default .input-group {
    margin-bottom: 20px; }
  .modal .form-default .input-group--help {
    height: 30px; }
  .modal .form-default__send {
    margin-bottom: 20px; }
  .modal .form-default__consent .input-group {
    display: flex;
    justify-content: center; }

.error-review {
  max-width: 640px;
  width: 100%;
  padding: 60px 108px; }
  .error-review__description {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000;
    margin-bottom: 60px; }
  .error-review__button {
    max-width: 278px;
    margin: 0 auto; }

.modal-cities {
  max-width: 645px;
  width: 100%;
  padding: 60px 95px; }
  @media only screen and (max-width: 576px) {
    .modal-cities {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 100vh;
      width: calc( 100% + 12px);
      padding: 70px 20px;
      transform: translateX(-6px);
      overflow: auto; } }
  .modal-cities__items {
    display: flex;
    flex-wrap: wrap;
    gap: 9px 0;
    justify-content: space-between; }
  .modal-cities__item {
    display: flex;
    max-width: 152px;
    width: 100%;
    font-size: 16px;
    color: #000;
    text-decoration: none;
    transition: .3s; }
    .modal-cities__item:hover {
      color: #24306B;
      text-decoration: underline; }
  .modal-cities__body {
    width: 100%; }
  .modal-cities__title {
    margin-bottom: 28px; }
  .modal-cities__search {
    width: 100%;
    margin-bottom: 31px; }
  .modal-cities__btn {
    margin: 60px auto 0; }
    @media only screen and (max-width: 576px) {
      .modal-cities__btn {
        margin-top: 20px; } }

.modal-callback {
  max-width: 640px;
  width: 100%;
  padding: 60px; }
  @media only screen and (max-width: 1024px) {
    .modal-callback {
      padding: 40px; } }
  @media only screen and (max-width: 976px) {
    .modal-callback {
      padding: 30px; } }
  @media only screen and (max-width: 576px) {
    .modal-callback {
      padding: 20px; }
      .modal-callback .page-feedback__title {
        font-size: 20px;
        line-height: 1;
        margin-top: 0;
        margin-bottom: 20px; }
      .modal-callback .page-feedback__card {
        flex-direction: column; }
      .modal-callback .page-feedback__card {
        width: 100%; }
      .modal-callback .card-min__img {
        max-width: 100%; }
        .modal-callback .card-min__img img {
          object-fit: cover; }
      .modal-callback .page-feedback__card {
        margin-bottom: 20px; } }
  .modal-callback__body {
    width: 100%; }
  .modal-callback__inputs {
    display: flex;
    gap: 10px;
    margin-bottom: 28px; }
    @media only screen and (max-width: 576px) {
      .modal-callback__inputs {
        flex-direction: column; } }
  .modal-callback__input {
    width: 100% !important;
    background: none;
    outline: none; }
  @media only screen and (max-width: 576px) {
    .modal-callback__send {
      flex-direction: column; } }

.callback-send {
  display: flex;
  gap: 10px; }
  @media only screen and (max-width: 576px) {
    .callback-send {
      flex-direction: column; } }

.privacy-policy {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer; }
  .privacy-policy__span {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5); }
    .privacy-policy__span a {
      color: rgba(0, 0, 0, 0.5); }
  .privacy-policy__checkbox {
    height: 21px;
    width: 29px;
    margin-bottom: 12px;
    border: 1px solid #24306B; }

.modal-material {
  margin-left: auto;
  margin-right: unset;
  max-width: 513px;
  width: 100%;
  height: 100vh;
  padding: 0;
  justify-content: flex-start; }
  .modal-material__show-close {
    position: absolute;
    top: 20px;
    right: 35px;
    padding: 0; }
  .modal-material__top {
    padding: 30px 35px 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 576px) {
      .modal-material__top {
        padding: 24px 15px; } }
  .modal-material .modal__title {
    text-align: center;
    font-size: 16px; }
  .modal-material__bottom {
    padding: 30px 35px;
    height: calc(100vh - 153px);
    overflow: auto; }
    @media only screen and (max-width: 576px) {
      .modal-material__bottom {
        padding: 15px; } }
    .modal-material__bottom::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #24306B; }
    .modal-material__bottom::-webkit-scrollbar {
      width: 5px;
      background-color: #f9f9fd; }
    .modal-material__bottom--active {
      padding-bottom: 250px; }
  .modal-material__body {
    position: relative;
    width: 100%; }
  .modal-material__button {
    top: 23px;
    z-index: 1; }
  .modal-material__show {
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0; }
    @media only screen and (max-width: 576px) {
      .modal-material__show {
        position: fixed; } }
  .modal-material__select {
    display: flex;
    justify-content: center;
    gap: 14px; }
    @media only screen and (max-width: 576px) {
      .modal-material__select {
        flex-direction: column; } }
    .modal-material__select .custom-select {
      max-width: 215px; }
      @media only screen and (max-width: 576px) {
        .modal-material__select .custom-select {
          max-width: 100%; } }
  .modal-material__item {
    margin-bottom: 30px; }

.material-selection__title {
  margin-bottom: 15px;
  font-family: "TT Commons Pro Expanded Medium";
  font-size: 15px; }

.material-selection__body {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px 12px;
  flex-wrap: wrap; }
  @media only screen and (max-width: 576px) {
    .material-selection__body {
      grid-template-columns: repeat(3, 1fr); } }

.show-material {
  background: #F6F5F5;
  padding: 20px 35px;
  display: none;
  opacity: 1;
  -webkit-animation: display-none-transition 0.5s both;
  animation: display-none-transition 0.5s both; }
  @media only screen and (max-width: 576px) {
    .show-material {
      padding: 15px; } }

@-webkit-keyframes display-none-transition {
  0% {
    opacity: 0; } }

@keyframes display-none-transition {
  0% {
    opacity: 0; } }
  .show-material--active {
    display: block; }
  .show-material__img {
    background-image: url("/img/materialLoop.jpg");
    cursor: pointer;
    max-width: 150px;
    width: 100%;
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 576px) {
      .show-material__img {
        max-width: 100px;
        height: 100px;
        min-height: 100px; } }
    .show-material__img:hover .show-material__icon {
      opacity: 1; }
  .show-material__icon {
    color: #F6F5F5;
    width: 17px;
    height: 17px;
    opacity: 0;
    transition: opacity .2s; }
  .show-material__info {
    display: flex;
    gap: 25px;
    margin-bottom: 15px; }
  .show-material__bottom {
    display: flex;
    justify-content: space-between;
    gap: 29px; }
    @media only screen and (max-width: 576px) {
      .show-material__bottom {
        flex-direction: column;
        gap: 20px; } }
  .show-material__price {
    display: flex;
    align-items: center;
    gap: 15px; }
  .show-material__btn {
    padding: 15px 38px; }

.material-description {
  display: flex;
  flex-direction: column;
  gap: 8px; }
  .material-description__title {
    font-size: 20px;
    color: #000;
    font-family: "TT Commons Pro Expanded Medium"; }
  .material-description__row {
    color: rgba(0, 0, 0, 0.5); }
  .material-description__span {
    color: #000; }

#modal-material {
  display: none;
  opacity: 1;
  -webkit-animation: display-none-transition 0.5s both;
  animation: display-none-transition 0.5s both;
  position: fixed;
  z-index: 6;
  top: 0;
  right: 0; }

@-webkit-keyframes display-none-transition {
  0% {
    opacity: 0; } }

@keyframes display-none-transition {
  0% {
    opacity: 0; } }

.modal-background {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); }

.modal-show-color__body {
  width: 100%; }

.modal-show-color__image {
  width: 100%; }

.modal-show-color {
  padding: 45px 20px 20px; }

.modal-email {
  max-width: 640px;
  display: flex;
  justify-content: center;
  padding: 60px 80px; }
  @media only screen and (max-width: 576px) {
    .modal-email {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 100vh;
      width: calc( 100% + 12px);
      padding: 70px 20px;
      transform: translateX(-6px);
      overflow: auto; } }
  .modal-email__text {
    font-size: 16px; }
  .modal-email__input {
    max-width: 328px;
    margin: 20px auto; }
  .modal-email__btn {
    max-width: 328px;
    margin: 20px auto; }
  .modal-email__button {
    margin: 60px auto 0; }
    @media only screen and (max-width: 576px) {
      .modal-email__button {
        margin-top: 20px; } }

.modal-sms {
  max-width: 640px;
  display: flex;
  justify-content: center;
  padding: 60px 80px; }
  @media only screen and (max-width: 576px) {
    .modal-sms {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 100vh;
      width: 100%;
      padding: 70px 20px;
      overflow: auto; } }
  .modal-sms__body {
    max-width: 328px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .modal-sms__error {
    color: #FF0000;
    font-size: 16px;
    text-align: center; }
  .modal-sms__text {
    margin-bottom: 20px;
    font-size: 16px;
    max-width: 300px;
    line-height: 25px; }
  .modal-sms__input {
    margin-bottom: 10px;
    max-width: 328px;
    width: 100%;
    cursor: auto; }
  .modal-sms__number {
    display: block; }
  .modal-sms__number, .modal-sms__subtitle {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-family: "TT Commons Pro Expanded Medium";
    text-align: center; }
  .modal-sms__description {
    color: #808080;
    font-size: 13px;
    text-align: center;
    margin-bottom: 30px; }
  .modal-sms__btn {
    margin: 0;
    display: contents;
    color: #3B4FB0; }
  .modal-sms__btn-sms {
    margin: 10px auto 60px; }
    @media only screen and (max-width: 576px) {
      .modal-sms__btn-sms {
        margin-bottom: 20px; } }
    .modal-sms__btn-sms--active {
      opacity: 1; }

.modal-redact {
  padding: 60px;
  max-width: 594px;
  width: 100%; }
  @media only screen and (max-width: 576px) {
    .modal-redact {
      min-height: 100vh;
      padding: 60px 15px; } }
  .modal-redact__title {
    font-size: 20px;
    font-family: "TT Commons Pro Expanded Medium";
    margin-bottom: 20px;
    color: #000; }
  .modal-redact__text-important {
    color: #FF0000;
    font-size: 16px;
    line-height: 25px; }
  .modal-redact__text {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 30px;
    color: #24306B; }
  .modal-redact__input {
    max-width: 220px;
    width: 100%;
    margin-bottom: 20px; }
    @media only screen and (max-width: 576px) {
      .modal-redact__input {
        max-width: 100%; } }

.upload__inputfile {
  width: .1px;
  height: .1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.upload__icon {
  width: 19px;
  height: 19px; }

.upload__btn {
  background: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 13px; }
  .upload__btn p {
    margin: 0; }
  .upload__btn:hover {
    background-color: unset;
    color: #24306B;
    transition: all .3s ease; }

.upload__img-wrap {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  max-height: 180px;
  overflow: auto;
  padding-top: 10px; }

.upload__img-box {
  width: 79px; }

.upload__img-close {
  position: absolute;
  top: -10px;
  right: -10px;
  text-align: center;
  line-height: 24px;
  z-index: 1;
  cursor: pointer; }

.img-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-bottom: 100%; }

.modal-personal-send {
  max-width: 640px;
  width: 100%;
  padding: 60px 108px;
  display: flex;
  justify-content: center;
  text-align: center; }
  .modal-personal-send__title {
    font-size: 20px;
    font-family: "TT Commons Pro Expanded Medium";
    margin-bottom: 20px;
    color: #000; }
  .modal-personal-send__text {
    font-size: 16px;
    line-height: 25px;
    color: #000;
    margin-bottom: 60px; }

.js-get-auth-phone span,
.js-auth-get-email span {
  pointer-events: none; }

.js-get-auth-phone svg,
.js-auth-get-email svg {
  pointer-events: none; }

.grade-stars {
  position: relative; }
  .grade-stars__background {
    display: flex;
    gap: 2px; }
    .grade-stars__background-img {
      width: 23px;
      height: 23px; }
      @media only screen and (max-width: 576px) {
        .grade-stars__background-img {
          width: 19px;
          height: 19px; } }
  .grade-stars__active {
    position: absolute;
    top: 0;
    display: flex;
    gap: 2px; }
    .grade-stars__active-img {
      width: 23px;
      height: 23px; }
      @media only screen and (max-width: 576px) {
        .grade-stars__active-img {
          width: 19px;
          height: 19px; } }

.double-card {
  display: flex; }
  .double-card__item {
    background: linear-gradient(166.72deg, #D5DFE9 57.72%, #98ACBE 91.26%);
    max-width: 720px;
    width: 100%;
    min-height: 376px;
    position: relative; }
  .double-card__image {
    position: absolute;
    bottom: 0;
    right: 0; }
  .double-card__description {
    background: #BACEE1;
    position: relative;
    padding: 97px 73px;
    width: 100%;
    max-width: 720px; }
  .double-card__title {
    font-size: 28px; }

.review-like {
  max-width: 221px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.like {
  display: flex;
  border: none;
  cursor: pointer;
  max-width: 74px;
  width: 100%;
  background: #F6F5F5;
  border-radius: 50px;
  padding: 12px 17px;
  justify-content: space-between; }
  .like__icon {
    width: 20px;
    height: 20px;
    color: #24306B; }

.reviews-grade {
  font-size: 40px;
  font-family: "TT Commons Pro Expanded Medium";
  max-width: 278px;
  width: 100%; }
  .reviews-grade__stars {
    display: flex;
    flex-direction: column;
    gap: 11px;
    margin-bottom: 27px; }
  .reviews-grade__reviews-text {
    font-size: 14px; }
  .reviews-grade__description {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 36px; }
  .reviews-grade__total {
    font-family: "TT Commons Pro Expanded Medium";
    text-align: center;
    margin-bottom: 10px; }
  .reviews-grade__button {
    font-size: 14px;
    text-align: center; }

.item-rating {
  display: flex;
  align-items: center;
  gap: 10px; }
  .item-rating__grade {
    font-size: 14px;
    margin-top: 1px;
    max-width: 12px;
    width: 100%; }
  .item-rating__icon {
    width: 18px;
    height: 18px; }
  .item-rating__line {
    width: 100%;
    height: 5px;
    background: #F6F5F5;
    position: relative;
    border-radius: 50px; }
  .item-rating__progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    background: #24306B;
    border-radius: 50px; }
  .item-rating__icon {
    color: #F0DA64; }

.product-review {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
  .product-review__name {
    margin-bottom: 20px; }
  .product-review__text {
    margin-bottom: 16px; }
  .product-review__like {
    margin-bottom: 30px; }

.review-top {
  width: 100%;
  display: flex;
  align-items: center; }
  .review-top__text, .review-top__name {
    font-size: 16px;
    color: #000; }
  .review-top__name {
    margin-right: 15px; }
  .review-top__text {
    margin-right: 30px; }
  .review-top__date {
    font-size: 16px;
    color: #9A9A9A; }

.product-delivery {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1); }
  .product-delivery__link {
    color: #000; }
  .product-delivery__title {
    font-size: 16px;
    font-family: "TT Commons Pro Expanded Medium"; }
    .product-delivery__title--bottom {
      margin-top: 15px; }
      @media only screen and (max-width: 576px) {
        .product-delivery__title--bottom {
          margin-top: 10px; } }
  .product-delivery__text {
    font-size: 14px;
    margin-top: 4px; }
    .product-delivery__text:not(:last-child):after {
      content: url("/img/icons/ball.svg");
      margin-left: 10px; }
  .product-delivery__span {
    text-decoration: underline; }

.advantage-description {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 30px; }
  @media only screen and (max-width: 576px) {
    .advantage-description {
      margin-top: 20px; } }
  .advantage-description__icon {
    width: 19px;
    height: 19px;
    color: #24306B; }
  .advantage-description__text {
    font-size: 13px; }
    @media only screen and (max-width: 576px) {
      .advantage-description__text {
        font-size: 12px; } }

.product-row {
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  align-items: center; }
  @media only screen and (max-width: 576px) {
    .product-row {
      justify-content: flex-start; } }
  .product-row__reviews {
    margin-right: 20px;
    font-size: 14px; }
  .product-row__text {
    font-size: 14px; }

.rating-stock {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px; }
  .rating-stock__icon {
    width: 23px;
    height: 23px;
    opacity: .3;
    color: white;
    stroke: #ebc914;
    stroke-width: 2px; }
    .rating-stock__icon--min {
      width: 19px;
      height: 19px; }
    .rating-stock__icon--current {
      color: #FFC700;
      opacity: 1; }
    .rating-stock__icon:nth-child(6n) {
      display: none !important; }

.product-popup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 374px;
  width: 100%;
  z-index: 2;
  opacity: 0;
  transition: .3s; }
  .product-popup:hover {
    opacity: 1; }
  .product-popup--appear {
    opacity: 0; }
  .product-popup__icon {
    width: 11px;
    height: 11px;
    cursor: pointer; }

.product-card {
  position: relative;
  max-width: 456px;
  width: calc(50% - 10px);
  min-height: 414px;
  padding: 18px;
  text-decoration: none; }
  @media only screen and (max-width: 1280px) {
    .product-card {
      min-height: 320px; } }
  @media only screen and (max-width: 1024px) {
    .product-card {
      min-height: 260px; } }
  .product-card:hover .product-popup {
    opacity: 1; }
  .product-card__image {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 456px;
    width: 100%;
    height: 100%; }
    .product-card__image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .product-card__info {
    position: relative;
    display: flex;
    gap: 8px; }
  .product-card__popup {
    position: absolute;
    top: 50%;
    right: -33%;
    padding: 15px 20px;
    background: #7FA392;
    color: #fff;
    font-size: 14px; }

.novelty {
  color: #fff;
  padding: 5px 10px;
  background: #7FA392;
  font-size: 14px; }

.hit {
  color: #fff;
  padding: 5px 10px;
  background: #24306B;
  font-size: 14px; }

.article-card {
  position: relative;
  display: flex;
  padding: 25px 40px;
  min-height: 300px; }
  @media only screen and (max-width: 1024px) {
    .article-card {
      padding: 70px 40px; } }
  @media only screen and (max-width: 976px) {
    .article-card {
      min-height: 230px;
      padding: 30px; } }
  .article-card--little {
    height: 50%; }
  .article-card--min {
    max-width: 333px;
    width: 100%;
    min-height: 470px;
    padding: 34px 25px; }
    @media only screen and (max-width: 1280px) {
      .article-card--min {
        min-height: 380px; } }
    @media only screen and (max-width: 976px) {
      .article-card--min {
        min-height: 310px; } }
    @media only screen and (max-width: 576px) {
      .article-card--min {
        min-height: 200px;
        max-width: 100%; } }
  .article-card--long {
    min-height: 550px;
    padding: 122px 25px; }
  .article-card__preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex; }
    .article-card__preview--grey:after, .article-card__preview--white:after, .article-card__preview--blue:after, .article-card__preview--pink:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: transparent;
      opacity: 0.8; }
    .article-card__preview--pink:after {
      background: linear-gradient(360deg, rgba(205, 181, 185, 0.72) 31.47%, rgba(255, 255, 255, 0) 50.55%); }
    .article-card__preview--blue:after {
      background: linear-gradient(360deg, rgba(48, 64, 80, 0.72) 31.47%, rgba(255, 255, 255, 0) 50.55%); }
    .article-card__preview--white:after {
      background: linear-gradient(360deg, rgba(173, 187, 184, 0.72) 31.47%, rgba(255, 255, 255, 0) 50.55%); }
    .article-card__preview--grey:after {
      background: linear-gradient(360deg, rgba(135, 142, 148, 0.72) 31.47%, rgba(255, 255, 255, 0) 50.55%); }
    .article-card__preview-image {
      width: 100%;
      height: auto;
      object-fit: cover; }
  .article-card__content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-end; }
    .article-card__content--right {
      align-items: flex-end; }
  .article-card__label {
    font-size: 14px;
    color: #010101;
    background: rgba(255, 255, 255, 0.5);
    padding: 4px 10px;
    border-radius: 4px;
    max-width: max-content; }
    .article-card__label--dark {
      background: rgba(154, 154, 154, 0.2); }
  .article-card__title {
    font-size: 23px;
    font-family: "TT Commons Pro Expanded Medium";
    max-width: 279px; }
    @media only screen and (max-width: 576px) {
      .article-card__title {
        font-size: 16px; } }
    .article-card__title--white {
      color: #fff; }
    .article-card__title--big {
      color: #fff;
      font-size: 30px;
      max-width: 100%; }
      @media only screen and (max-width: 576px) {
        .article-card__title--big {
          font-size: 16px; } }
  .article-card__link {
    margin-top: 14px;
    max-width: max-content; }
  .article-card--big__content {
    justify-content: center;
    align-items: center; }

.ask-question__btn {
  max-width: max-content; }

.ask-question__icon {
  width: 23px;
  height: 23px;
  color: #24306B;
  margin-bottom: 28px; }

.ask-question__title {
  margin-bottom: 24px;
  font-size: 20px;
  font-family: "TT Commons Pro Expanded Medium";
  color: #000; }
  .ask-question__title--min {
    max-width: 279px;
    width: 100%; }

.sleeping-link {
  position: relative;
  min-height: 360px;
  padding: 77px 63px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start; }
  @media only screen and (max-width: 1280px) {
    .sleeping-link {
      padding: 75px 40px; } }
  @media only screen and (max-width: 1024px) {
    .sleeping-link {
      min-height: 280px;
      padding: 40px; } }
  @media only screen and (max-width: 976px) {
    .sleeping-link {
      padding: 15px; } }
  .sleeping-link__link-main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10; }
    .sleeping-link__link-main:hover ~ .sleeping-link__description .sleeping-description__button {
      background: #F6F5F5;
      color: #24306B; }
  .sleeping-link--header {
    width: 100%;
    max-width: 275px;
    min-height: 395px;
    padding: 25px;
    align-items: end;
    justify-content: center; }
    .sleeping-link--header-burger {
      padding-top: 320px;
      min-height: 480px;
      max-width: 100%; }
      @media only screen and (max-width: 576px) {
        .sleeping-link--header-burger {
          min-height: 330px;
          align-items: flex-end;
          padding: 20px; } }
  .sleeping-link__description {
    position: absolute;
    padding: 30px 15px;
    z-index: 2; }
  .sleeping-link__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex; }
    .sleeping-link__img .img {
      width: 100%;
      object-fit: cover; }

.sleeping-description {
  position: relative;
  max-width: 192px;
  width: 100%;
  background: #fff; }
  .sleeping-description--header {
    max-width: none;
    padding: 20px 15px; }
  .sleeping-description__title {
    font-size: 14px;
    line-height: 18px;
    font-family: "TT Commons Pro Expanded Medium";
    color: #000;
    margin-bottom: 3px; }
  .sleeping-description__text {
    font-size: 12px;
    line-height: 15px;
    font-family: "TT Commons Pro Expanded Regular";
    color: #000; }
  .sleeping-description__button {
    max-width: max-content;
    margin-top: 15px;
    display: inline-block; }

.sleeping-bottom {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px; }
  @media only screen and (max-width: 976px) {
    .sleeping-bottom {
      gap: 20px; } }
  @media only screen and (max-width: 576px) {
    .sleeping-bottom {
      display: none; } }

.sleeping-bottom-slider {
  display: none; }
  @media only screen and (max-width: 576px) {
    .sleeping-bottom-slider {
      display: block; } }

.sleeping-systems__top {
  margin-bottom: 34px; }

.children-link {
  position: relative;
  max-width: 570px;
  width: 100%;
  min-height: 603px;
  padding: 37px 42px;
  display: flex;
  align-items: end; }
  @media only screen and (max-width: 1280px) {
    .children-link {
      max-width: 100%;
      width: 50%; } }
  @media only screen and (max-width: 1024px) {
    .children-link {
      min-height: 470px; } }
  @media only screen and (max-width: 976px) {
    .children-link {
      min-height: 370px; } }
  @media only screen and (max-width: 576px) {
    .children-link {
      min-height: 300px;
      padding: 10px 9px; } }
  .children-link__link-main {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10; }
    .children-link__link-main:hover ~ .children-link__link {
      background: #24306B;
      color: #fff; }
  .children-link__link {
    position: relative;
    max-width: max-content;
    width: 100%;
    font-size: 14px;
    z-index: 2;
    padding: 12px 69px; }
    @media only screen and (max-width: 576px) {
      .children-link__link {
        padding: 10px;
        width: 100%;
        max-width: 100%; } }
  .children-link__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    object-fit: contain; }
    .children-link__img .img {
      width: 100%;
      object-fit: cover; }

.sleeping-top {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 976px) {
    .sleeping-top {
      flex-direction: column;
      align-items: flex-start; } }
  .sleeping-top__title {
    font-size: 35px;
    line-height: 44px;
    font-family: "TT Commons Pro Expanded Medium";
    color: #000;
    margin-bottom: 8px; }
    @media only screen and (max-width: 976px) {
      .sleeping-top__title {
        font-size: 30px;
        line-height: 37.5px; } }
    @media only screen and (max-width: 576px) {
      .sleeping-top__title {
        font-size: 28px; } }
  .sleeping-top__description {
    font-size: 16px;
    line-height: 20px;
    color: #000; }
  .sleeping-top__btn {
    margin-top: 20px; }

.bedding-item {
  position: relative;
  width: 100%;
  min-height: 360px;
  padding: 30px 25px;
  display: flex;
  align-items: end; }
  .bedding-item__button {
    display: inline-block;
    position: relative;
    max-width: 220px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    z-index: 2;
    padding: 12px; }
  .bedding-item__img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    position: absolute; }
  .bedding-item__img .img {
    width: 100%;
    object-fit: cover; }
  .bedding-item__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2; }
    .bedding-item__link:hover + .bedding-item__button {
      background: #24306B;
      color: #fff; }

.advantages-item {
  display: flex;
  align-items: center;
  gap: 13px; }
  @media only screen and (max-width: 1024px) {
    .advantages-item:last-child .advantages-item__text {
      max-width: 180px; } }
  @media only screen and (max-width: 976px) {
    .advantages-item {
      gap: 7px; } }
  .advantages-item__icon {
    color: #24306B;
    width: 25px;
    height: 25px;
    mix-blend-mode: darken; }
    @media only screen and (max-width: 1024px) {
      .advantages-item__icon {
        width: 20px;
        height: 20px; } }
  .advantages-item__text {
    max-width: 243px; }
    @media only screen and (max-width: 1024px) {
      .advantages-item__text {
        font-size: 14px; } }

.children-articles-links {
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column; }
  @media only screen and (max-width: 1280px) {
    .children-articles-links {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      max-width: 100%; } }
  @media only screen and (max-width: 576px) {
    .children-articles-links {
      grid-template-columns: 1fr; } }
  .children-articles-links__link-main {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10; }
    .children-articles-links__link-main:hover ~ .article-card__content .article-card__link {
      background: #F6F5F5;
      color: #24306B; }
  @media only screen and (max-width: 1024px) {
    .children-articles-links__item .article-card__content {
      justify-content: flex-start; } }
  @media only screen and (max-width: 1024px) and (max-width: 576px) {
    .children-articles-links__item .article-card__content {
      justify-content: center; } }

.review-item {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .review-item__title {
    font-size: 20px;
    font-family: "TT Commons Pro Expanded Medium";
    margin-bottom: 15px;
    color: #000;
    max-width: 363px; }
  .review-item__text {
    max-width: 363px;
    text-align: center;
    font-size: 16px;
    color: #000; }
  .review-item__img {
    margin-bottom: 29px; }
    .review-item__img img {
      width: 48px;
      height: 48px;
      object-fit: contain; }

.children-article {
  position: relative; }
  .children-article__img {
    max-width: 478px;
    width: 100%;
    object-fit: contain; }
    .children-article__img .img {
      width: 100%; }
  .children-article__text {
    left: 43px;
    bottom: 24px; }

.children-furniture-links {
  background: #F9F9F9;
  max-width: 392px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 145px 41px 30px 41px; }
  @media only screen and (max-width: 1280px) {
    .children-furniture-links {
      max-width: 100%;
      width: 50%; } }
  @media only screen and (max-width: 1024px) {
    .children-furniture-links {
      padding: 85px 50px; } }
  @media only screen and (max-width: 576px) {
    .children-furniture-links {
      padding: 30px 10px; } }
  .children-furniture-links__list {
    display: flex;
    flex-direction: column;
    gap: 37px; }
    @media only screen and (max-width: 576px) {
      .children-furniture-links__list {
        gap: 30px; } }
  .children-furniture-links__title {
    margin-bottom: 37px;
    max-width: 310px;
    font-family: "TT Commons Pro Expanded Medium";
    font-size: 30px;
    color: #000; }
    @media only screen and (max-width: 976px) {
      .children-furniture-links__title {
        max-width: 200px;
        margin-bottom: 30px;
        font-size: 20px; } }
    @media only screen and (max-width: 576px) {
      .children-furniture-links__title {
        font-size: 15px; } }
  .children-furniture-links__link {
    color: #24306B;
    text-decoration: underline; }

.sofa-link {
  text-decoration: none;
  background: linear-gradient(166.72deg, #D5DFE9 57.72%, #98ACBE 91.26%);
  max-width: 340px;
  width: 100%;
  position: relative;
  max-height: 550px;
  padding: 122px 25px; }
  @media only screen and (max-width: 1280px) {
    .sofa-link {
      max-width: 100%; } }
  @media only screen and (max-width: 1024px) {
    .sofa-link {
      padding: 100px 20px;
      min-height: 340px; } }
  @media only screen and (max-width: 576px) {
    .sofa-link {
      min-height: 0; }
      .sofa-link .sofa-article-link__subtitle {
        font-size: 20px;
        line-height: 25px; } }
  @media only screen and (max-width: 976px) {
    .sofa-link {
      padding: 60px 13px; }
      .sofa-link .sofa-article-link__subtitle {
        font-size: 16px;
        line-height: 20px; } }
  @media only screen and (max-width: 576px) {
    .sofa-link {
      height: 230px; } }
  .sofa-link__link-main {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10; }
    .sofa-link__link-main:hover ~ .sofa-article-link .sofa-article-link__btn {
      background: #F6F5F5;
      color: #24306B; }
  .sofa-link--min {
    padding: 25px;
    display: flex;
    align-items: center; }
  .sofa-link__img {
    position: absolute;
    bottom: -4px;
    right: 0;
    max-width: 280px;
    width: 100%; }
    @media only screen and (max-width: 576px) {
      .sofa-link__img {
        width: 185px;
        height: 145px;
        bottom: 0; } }
    .sofa-link__img .sofa-link__background-sofa {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.rating-area {
  overflow: hidden;
  max-width: 115px;
  width: 100%; }
  .rating-area__icon {
    width: 23px;
    height: 23px; }

.rating-area:not(:checked) > input {
  display: none; }

.rating-area:not(:checked) > label {
  float: right;
  padding: 0;
  cursor: pointer;
  font-size: 32px;
  line-height: 32px;
  color: lightgrey; }

.rating-area > input:checked ~ label {
  color: gold; }

.rating-area:not(:checked) > label:hover,
.rating-area:not(:checked) > label:hover ~ label {
  color: gold; }

.rating-area > input:checked + label:hover,
.rating-area > input:checked + label:hover ~ label,
.rating-area > input:checked ~ label:hover,
.rating-area > input:checked ~ label:hover ~ label,
.rating-area > label:hover ~ input:checked ~ label {
  color: gold; }

.rate-area > label:active {
  position: relative; }

.bread-crumbs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: no-wrap;
  overflow: hidden;
  overflow-x: auto; }

.bread-crumb {
  font-size: 16px;
  text-decoration: none;
  font-family: "TT Commons Pro Expanded Regular";
  line-height: 18px;
  white-space: nowrap;
  color: #808080; }
  .bread-crumb span {
    transition: .3s; }
  .bread-crumb:hover span {
    color: #24306B; }
  .bread-crumb:after {
    content: url("/img/icons/bread-crumb-arrow.svg");
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none !important; }
  .bread-crumb:not(a) {
    color: #848E99; }
    .bread-crumb:not(a):after {
      display: none; }
  .bread-crumb--current {
    text-decoration: underline; }

.pagination {
  display: flex;
  align-items: stretch;
  justify-content: center;
  min-width: 24px; }
  .pagination__icon {
    width: 16px;
    height: 16px;
    transition: .3s; }
  .pagination__control {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    transition: background ease .3s;
    margin: 0 4px;
    width: 50px;
    height: 50px;
    background: #F6F5F5;
    border-radius: 50%; }
    .pagination__control:hover .pagination__icon {
      color: #24306B; }
    .pagination__control .icon {
      font-size: 12px; }
    .pagination__control--disabled {
      opacity: 0.5;
      cursor: auto; }
  .pagination__list {
    margin: 0 10px;
    display: flex;
    align-items: center; }
  .pagination__link {
    margin: 0 4px;
    width: 50px;
    height: 50px;
    border: 1px solid #EDEDED;
    display: flex;
    align-items: center;
    justify-content: center;
    background: trasnsparent;
    color: #000000;
    text-decoration: none;
    font-family: "TT Commons Pro Expanded Regular";
    font-size: 15px;
    border-radius: 50%;
    transition: .3s; }
    .pagination__link:hover:not(.pagination__link--not-hover) {
      border: 1px solid #808080; }
    .pagination__link--current {
      border: 1px solid #24306B; }

.wrap-preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(26, 34, 41, 0.5);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center; }
  .wrap-preloader .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); }

.section-preloader {
  position: relative; }
  .section-preloader__wrapper {
    position: absolute;
    top: 0;
    left: -1000px;
    right: -1000px;
    bottom: 0;
    background: transparent;
    z-index: -1;
    display: flex;
    z-index: 99999;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    transition: background ease .3s; }
  .section-preloader .loader {
    position: relative;
    transform: scale(0);
    transition: transform ease .3s; }
  .section-preloader--show .section-preloader__wrapper {
    pointer-events: auto;
    background: rgba(26, 34, 41, 0.6); }
  .section-preloader--show .loader {
    transform: scale(1); }

.loader {
  height: 10rem;
  width: 10rem; }
  .loader .circle {
    position: absolute;
    height: inherit;
    width: inherit;
    background: #FBC35F;
    border-radius: 50%;
    animation: animate-circle 2s cubic-bezier(0.9, 0.24, 0.62, 0.79) infinite; }
    .loader .circle:nth-of-type(1) {
      animation-delay: 0; }
    .loader .circle:nth-of-type(2) {
      animation-delay: calc(2s / -3); }
    .loader .circle:nth-of-type(3) {
      animation-delay: calc(2s / -6); }

@-webkit-keyframes animate-circle {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0.5; }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0; } }

.tabs {
  display: flex;
  flex-direction: column;
  position: relative; }
  .tabs-header {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    position: relative;
    bottom: -1px; }
    .tabs-header--min {
      justify-content: flex-start;
      max-width: 314px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 50px; }
    .tabs-header__span {
      position: absolute;
      top: 0px;
      right: -40px;
      font-size: 12px;
      color: #fff;
      padding: 0px 10px;
      background: #24306B;
      border-radius: 50px; }
    .tabs-header--main {
      max-width: 594px;
      width: 100%;
      margin: 0 auto;
      justify-content: space-between; }
    .tabs-header:empty {
      display: none; }
    .tabs-header__button {
      cursor: pointer;
      outline: none;
      background: none;
      border: none;
      box-shadow: none;
      padding: 16px 32px;
      color: #24306B;
      font-family: "TT Commons Pro Expanded Regular";
      font-size: 13px;
      line-height: 20px;
      transition: color 0.3s cubic-bezier(0.49, 0.31, 0.68, 0.81), background-color 0.3s cubic-bezier(0.49, 0.31, 0.68, 0.81), box-shadow 0.3s cubic-bezier(0.49, 0.31, 0.68, 0.81); }
      .tabs-header__button-text {
        border-bottom: 1px dashed rgba(36, 48, 107, 0.25); }
      .tabs-header__button--active {
        color: #111111;
        background-color: #F6F8F9; }
        .tabs-header__button--active .tabs-header__button-text {
          border-style: none !important; }
    .tabs-header--hide {
      transform: scale(0);
      visibility: hidden;
      opacity: 0;
      position: absolute; }
  .tabs-content {
    overflow: hidden; }
    .tabs-content:empty {
      display: none; }
    .tabs-content__tab {
      display: none;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 28px 32px; }
      .tabs-content__tab--active {
        background-color: #F6F8F9;
        height: auto;
        width: 100%;
        display: block; }
  .tabs .tabs-header {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 28px; }
    @media only screen and (max-width: 576px) {
      .tabs .tabs-header {
        gap: 15px;
        overflow: auto; } }
    .tabs .tabs-header:empty {
      display: none; }
    .tabs .tabs-header__button {
      position: relative;
      font-size: 17px;
      line-height: 21px;
      color: #000000;
      cursor: pointer;
      outline: none;
      background: none;
      border: none;
      box-shadow: none;
      font-family: "TT Commons Pro Expanded Medium";
      padding: 0;
      transition: color 0.3s cubic-bezier(0.49, 0.31, 0.68, 0.81), border-bottom-color 0.3s cubic-bezier(0.49, 0.31, 0.68, 0.81), box-shadow 0.3s cubic-bezier(0.49, 0.31, 0.68, 0.81); }
      @media only screen and (max-width: 576px) {
        .tabs .tabs-header__button:last-child {
          width: calc(100% + 40px); } }
      .tabs .tabs-header__button--active {
        color: #111111;
        border-bottom: none;
        box-shadow: inset 0px -2px 0px #24306B; }
    .tabs .tabs-header--hide {
      transform: scale(0);
      visibility: hidden;
      opacity: 0;
      position: absolute; }
  .tabs .tabs-content {
    overflow: hidden;
    margin-top: 40px;
    left: calc( (100vw - 1708px)/(1980 - 1708) * ((-125) - 0) + 0px);
    width: 100%;
    opacity: 1;
    transition: .3s; }
    @media (max-width: 1708px) {
      .tabs .tabs-content {
        left: 0; } }
    .tabs .tabs-content--show {
      opacity: 1; }
    .tabs .tabs-content:empty {
      display: none; }
    .tabs .tabs-content__tab {
      display: none;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: transparent;
      padding: 0; }
      .tabs .tabs-content__tab--active {
        height: auto;
        width: 100%;
        display: block; }

.tabs-min__header {
  max-width: 315px;
  width: 100%;
  height: 60px;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1); }

.tabs-min__icon {
  height: 16px;
  width: 16px;
  color: #808080;
  transition: .3s; }

.tabs-min__button {
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  box-shadow: none;
  padding: 10px 20px;
  color: #808080;
  font-family: "TT Commons Pro Expanded Regular";
  font-size: 13px;
  line-height: 20px;
  transition: color 0.3s cubic-bezier(0.49, 0.31, 0.68, 0.81), background-color 0.3s cubic-bezier(0.49, 0.31, 0.68, 0.81), box-shadow 0.3s cubic-bezier(0.49, 0.31, 0.68, 0.81);
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 50px; }
  .tabs-min__button-text {
    border-bottom: 1px dashed rgba(36, 48, 107, 0.25); }
  .tabs-min__button--active {
    color: #24306B;
    background: #F6F5F5;
    border-radius: 50px; }
  .tabs-min__button--active .tabs-min__icon {
    color: #24306B; }

.tabs-min__content:empty {
  display: none; }

.tabs-min__content__tab {
  display: none;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 28px 32px; }
  .tabs-min__content__tab--active {
    background-color: #F6F8F9;
    height: auto;
    width: 100%;
    display: block; }

.tabs-modal__header {
  max-width: 328px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-between; }
  @media only screen and (max-width: 576px) {
    .tabs-modal__header {
      max-width: 100%;
      margin-right: 0;
      margin-left: 0; } }

.tabs-modal__registration {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 11px;
  justify-content: center; }

.tabs-modal__footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 360px; }
  @media only screen and (max-width: 576px) {
    .tabs-modal__footer {
      align-items: center; } }

.tabs-modal__btn {
  margin-top: 25px; }
  .tabs-modal__btn--min {
    max-width: 220px;
    width: 100%;
    padding: 15px 20px; }
    @media only screen and (max-width: 576px) {
      .tabs-modal__btn--min {
        max-width: 100%; } }

.tabs-modal__content {
  margin-top: 30px !important; }

.tabs-modal__login {
  max-width: 328px;
  width: 100%;
  margin: 0 auto; }

.tabs-modal__input {
  max-width: 220px;
  width: 100%; }
  @media only screen and (max-width: 576px) {
    .tabs-modal__input {
      max-width: 100%; } }

.tabs-modal__button {
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  box-shadow: none;
  color: #808080;
  font-family: "TT Commons Pro Expanded Medium";
  font-size: 20px; }
  @media only screen and (max-width: 576px) {
    .tabs-modal__button {
      font-size: 16px; } }
  .tabs-modal__button--active {
    color: #24306B; }

.main-slider {
  overflow: hidden;
  cursor: pointer;
  position: relative; }
  .main-slider--wide {
    max-width: 1503px; }
  .main-slider__btn {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: none;
    background: #F6F5F5;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 576px) {
      .main-slider__btn {
        display: none; } }
    .main-slider__btn--prev {
      position: absolute;
      top: calc(50% - 25px);
      left: 10px;
      z-index: 1;
      cursor: pointer; }
    .main-slider__btn--prev-comparison {
      position: absolute;
      top: 0;
      height: 100%;
      left: -60px;
      z-index: 1;
      cursor: pointer;
      transition: .3s; }
    .main-slider__btn--next {
      position: absolute;
      top: calc(50% - 25px);
      right: 10px;
      z-index: 1;
      cursor: pointer; }
    .main-slider__btn--next-comparison {
      position: absolute;
      top: 0;
      height: 100%;
      right: -60px;
      z-index: 1;
      cursor: pointer;
      transition: .3s; }
  .main-slider__btn-icon {
    width: 17px;
    height: 14px; }
    .main-slider__btn-icon--right {
      margin-left: 4px; }
  .main-slider__link:hover .sofa-article-link__btn {
    background: #F6F5F5;
    color: #24306B; }

.swiper-button-disabled {
  opacity: 0; }

.section-slider__slider {
  width: 80%; }
  @media only screen and (max-width: 1280px) {
    .section-slider__slider {
      width: 68%; } }
  @media only screen and (max-width: 576px) {
    .section-slider__slider {
      width: 100%; } }

.common-slider {
  max-width: 1490px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative; }
  @media only screen and (max-width: 976px) {
    .common-slider {
      width: calc(100% + 15px); } }
  .common-slider__title {
    font-size: 35px;
    line-height: 44px;
    display: flex;
    justify-content: center;
    margin: 0 auto 19px;
    font-family: "TT Commons Pro Expanded Medium"; }
    @media only screen and (max-width: 1024px) {
      .common-slider__title {
        font-size: 30px;
        line-height: 37.5px; } }
    @media only screen and (max-width: 576px) {
      .common-slider__title {
        font-size: 28px; } }
  .common-slider__container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden; }
  .common-slider__btn {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: none;
    background: #F6F5F5;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 976px) {
      .common-slider__btn {
        display: none; } }
    .common-slider__btn--prev {
      position: absolute;
      top: 162px;
      left: 0;
      z-index: 1;
      cursor: pointer; }
    .common-slider__btn--next {
      position: absolute;
      top: 162px;
      right: 5px;
      z-index: 1;
      cursor: pointer; }
  .common-slider .swiper-button-disabled {
    opacity: 0.2; }
  .common-slider__btn-icon {
    width: 17px;
    height: 14px; }
    .common-slider__btn-icon--right {
      margin-left: 4px; }

.check-favorite {
  margin-bottom: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin-left: auto;
  display: flex;
  align-items: center;
  border: none;
  background: #fff;
  cursor: pointer; }
  .check-favorite:hover .check-favorite__icon {
    color: #24306B; }
  .check-favorite--active {
    background: #24306B; }
    .check-favorite--active:hover .check-favorite__icon {
      color: #fff; }
  .check-favorite__icon {
    width: 16px;
    margin: 0 auto;
    display: flex;
    color: rgba(0, 0, 0, 0.5);
    transition: .3s; }
    .check-favorite__icon--active {
      color: #F6F5F5; }

.common-slide__item {
  text-decoration: none;
  color: #000;
  max-width: 270px; }

.common-slide__img {
  margin-bottom: 24px;
  max-width: 270px;
  height: 162px;
  width: 100%;
  object-fit: contain; }

.common-slide__img .img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.common-slide__price {
  margin-bottom: 16px;
  font-family: "TT Commons Pro Expanded Medium";
  font-size: 18px;
  display: flex;
  padding: 4px; }
  .common-slide__price--discount {
    padding: 0px;
    gap: 11px; }

.main-price {
  width: max-content;
  font-family: "TT Commons Pro Expanded Medium";
  font-size: 18px;
  padding: 4px; }
  .main-price--background {
    background: #F0DA64; }

.old-price {
  padding: 4px;
  text-decoration: line-through; }

.filters-price__inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px; }

.filters-price__text {
  font-size: 14px; }

.filters-price__text:first-child {
  margin-right: 10px; }

.filters-price__text:last-child {
  margin-left: 10px; }

.filters-price__input {
  border: none;
  font-size: 15px;
  outline: none;
  line-height: 15px;
  color: #24306B; }
  .filters-price__input:hover::placeholder {
    color: #24306B; }
  .filters-price__input--dark {
    color: #111111;
    opacity: 0.5; }

.filters-price__title {
  margin-bottom: 20px; }

.filters-price__slider {
  margin-bottom: 20px;
  padding: 0 17px 0 5px; }

.filters-price__dash {
  width: 11px;
  color: #EDEDED; }

.noUi-connect {
  background-color: #24306B; }

.noUi-target {
  background-color: #EDEDED;
  border: none;
  border-radius: 100px;
  box-shadow: none;
  height: 3px; }

.noUi-handle::after,
.noUi-handle::before {
  display: none; }

.noUi-handle {
  box-shadow: none;
  border-radius: 100%;
  background-color: #24306B; }

.noUi-horizontal .noUi-handle {
  width: 20px;
  height: 20px;
  top: -10px;
  cursor: pointer; }

.noUi-horizontal .noUi-handle-upper {
  right: -17px; }

.input-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 110px;
  border: 1px solid #EDEDED;
  border-radius: 50px;
  padding: 16px; }

.product-reviews {
  display: flex;
  justify-content: space-between;
  gap: 115px; }
  @media only screen and (max-width: 1280px) {
    .product-reviews {
      gap: 100px; } }
  @media only screen and (max-width: 1024px) {
    .product-reviews {
      gap: 60px; } }
  @media only screen and (max-width: 976px) {
    .product-reviews {
      flex-direction: column;
      gap: 50px; } }
  .product-reviews__select {
    max-width: 193px;
    width: 100%; }
  .product-reviews__reviews {
    width: 100%; }
  .product-reviews__review {
    margin-top: 30px; }
  .product-reviews__title {
    display: flex;
    justify-content: space-between; }
  .product-reviews__subtitle {
    font-size: 28px;
    color: #000;
    font-family: "TT Commons Pro Expanded Medium"; }
    @media only screen and (max-width: 576px) {
      .product-reviews__subtitle {
        font-size: 20px; } }
  .product-reviews__button {
    max-width: 954px;
    width: 100%;
    margin-top: 40px; }

.product-description {
  font-size: 16px;
  color: #000; }
  .product-description li {
    list-style-type: disc;
    margin-left: 25px; }
  .product-description__text {
    gap: 5px;
    display: flex;
    flex-direction: column; }

.product-characteristics {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 976px) {
    .product-characteristics {
      flex-direction: column; } }
  .product-characteristics__column {
    max-width: 702px;
    width: 100%; }

.product-characteristics-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 702px;
  width: 100%;
  padding: 0;
  gap: 20px; }
  .product-characteristics-column li {
    align-items: baseline;
    display: flex;
    font-size: 16px;
    color: #808080; }
  .product-characteristics-column li:before {
    content: '';
    border-bottom: 1px dashed #ccc;
    flex-grow: 1;
    order: 2;
    margin: 0 5px; }
  .product-characteristics-column .product-characteristics-column__span {
    order: 3;
    font-size: 16px;
    color: #000; }
  .product-characteristics-column__total, .product-characteristics-column__column {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }

.product-configuration {
  max-width: 455px;
  width: 100%; }
  @media only screen and (max-width: 1280px) {
    .product-configuration {
      max-width: 400px; } }
  @media only screen and (max-width: 976px) {
    .product-configuration {
      max-width: 100%; } }
  .product-configuration__compare {
    margin-top: 40px;
    padding-bottom: 27px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 23px; }
    @media only screen and (max-width: 976px) {
      .product-configuration__compare {
        margin-top: 0;
        margin-bottom: 15px;
        padding-bottom: 10px; } }
  .product-configuration__title {
    font-size: 35px;
    color: #000;
    font-family: "TT Commons Pro Expanded Medium";
    margin-bottom: 19px; }
    @media only screen and (max-width: 976px) {
      .product-configuration__title {
        max-width: 300px;
        font-size: 30px; } }
    @media only screen and (max-width: 576px) {
      .product-configuration__title {
        font-size: 20px;
        max-width: 100%; } }
  .product-configuration__selects {
    flex-direction: column;
    margin-bottom: 18px; }
    @media only screen and (max-width: 576px) {
      .product-configuration__selects {
        margin-bottom: 0; } }
  .product-configuration__link {
    color: #000;
    font-size: 14px;
    margin-bottom: 28px;
    display: inline-block; }
    @media only screen and (max-width: 976px) {
      .product-configuration__link {
        margin-bottom: 15px; } }
  .product-configuration__price {
    display: flex;
    align-items: center;
    gap: 19px;
    margin-bottom: 30px; }
    @media only screen and (max-width: 976px) {
      .product-configuration__price {
        margin-bottom: 15px; } }
  .product-configuration__advantages {
    margin-bottom: 31px; }
  .product-configuration__basket {
    margin-bottom: 10px; }
  .product-configuration__basket-installment {
    margin-bottom: 25px; }
    @media only screen and (max-width: 976px) {
      .product-configuration__basket-installment {
        max-width: 100%; } }
  .product-configuration__link-order {
    margin-bottom: 23px; }

.product-compare {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .product-compare__label {
    display: flex;
    gap: 8px;
    cursor: pointer; }
  .product-compare__check-compare {
    display: flex;
    gap: 8px;
    align-items: center; }
    .product-compare__check-compare .span {
      font-size: 14px; }
      @media only screen and (max-width: 576px) {
        .product-compare__check-compare .span {
          font-size: 12px;
          line-height: 20px; } }
  .product-compare__checkbox {
    width: 20px;
    height: 20px;
    cursor: pointer; }

.product-selects {
  display: flex;
  justify-content: space-between;
  gap: 14px; }
  .product-selects--direction {
    flex-direction: column; }
  .product-selects__item {
    width: 100%; }

.product-price__main {
  padding: 0;
  font-size: 22px; }
  @media only screen and (max-width: 576px) {
    .product-price__main {
      font-size: 20px; } }
  .product-price__main--min {
    font-size: 18px; }
    @media only screen and (max-width: 576px) {
      .product-price__main--min {
        font-size: 16px; } }
  .product-price__main--small {
    font-size: 16px; }
    @media only screen and (max-width: 576px) {
      .product-price__main--small {
        font-size: 12px; } }

.product-price__old {
  font-size: 18px; }
  .product-price__old--small {
    font-size: 14px; }

.product-advantages {
  gap: 17px;
  display: flex;
  flex-direction: column; }
  .product-advantages__text {
    font-size: 13px; }

.product-configuration__advantages {
  margin-top: 0; }
  .product-configuration__advantages .product-advantages__item {
    margin-top: 0; }

.product-basket {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 15px 20px; }
  .product-basket__text-first {
    max-width: 225px;
    width: 100%;
    text-align: left;
    color: #000; }
  .product-basket__text {
    font-size: 14px; }
  .product-basket__icon {
    width: 23px;
    height: 23px;
    color: #fff; }
    .product-basket__icon--blue {
      color: #24306B; }

.product-link-order {
  text-decoration: none;
  color: #24306B;
  display: flex;
  justify-content: center; }

.product-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px; }
  @media only screen and (max-width: 1024px) {
    .product-info {
      gap: 20px; } }
  @media only screen and (max-width: 976px) {
    .product-info {
      flex-direction: column-reverse; } }
  .product-info__row {
    margin-bottom: 27px; }
  .product-info__link {
    margin: 24px auto 0;
    color: #24306B;
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center; }
    .product-info__link:after {
      content: url("/img/icons/arrow-down.svg"); }
    .product-info__link--open:after {
      transform: rotate(180deg); }

.product-cards-slider {
  width: calc(100% - 510px);
  max-width: 1080px; }
  @media only screen and (max-width: 1280px) {
    .product-cards-slider {
      width: calc(100% - 420px); } }
  @media only screen and (max-width: 978px) {
    .product-cards-slider {
      width: 100%; } }
  .product-cards-slider .swiper-slide {
    background-size: cover;
    background-position: center; }
    .product-cards-slider .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .product-cards-slider .js-product-cards-slider2 {
    height: auto;
    width: 100%;
    border: 1px solid #EDEDED; }
  .product-cards-slider .js-product-cards-slider {
    width: 100%;
    height: 150px;
    box-sizing: border-box;
    padding: 10px 0; }
    @media only screen and (max-width: 1279px) {
      .product-cards-slider .js-product-cards-slider {
        height: 30%; } }
    .product-cards-slider .js-product-cards-slider .swiper-slide {
      width: 25%;
      height: 100%;
      opacity: 0.4;
      cursor: pointer;
      border: 1px solid #EDEDED; }
    .product-cards-slider .js-product-cards-slider .swiper-slide-thumb-active {
      opacity: 1;
      border: 1px solid #24306B; }

.product-cards {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px; }
  @media only screen and (max-width: 1279px) {
    .product-cards {
      gap: 10px; } }
  @media only screen and (max-width: 978px) {
    .product-cards {
      gap: 0; } }

.useful-articles__link-main {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10; }
  .useful-articles__link-main:hover ~ .article-card__content .article-card__link {
    background: #F6F5F5;
    color: #24306B; }

.useful-articles__bottom {
  display: flex; }

.useful-articles__head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 27px; }
  @media only screen and (max-width: 576px) {
    .useful-articles__head {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px; } }

.useful-articles__cards {
  display: flex; }
  @media only screen and (max-width: 976px) {
    .useful-articles__cards {
      flex-direction: column; } }
  .useful-articles__cards-column {
    max-width: 480px;
    width: 100%;
    display: flex;
    flex-direction: column; }
    @media only screen and (max-width: 976px) {
      .useful-articles__cards-column {
        flex-direction: row;
        max-width: 100%; } }
    @media only screen and (max-width: 576px) {
      .useful-articles__cards-column {
        flex-direction: column; } }

.useful-articles__title {
  font-size: 35px;
  font-family: "TT Commons Pro Expanded Medium"; }
  @media only screen and (max-width: 1024px) {
    .useful-articles__title {
      font-size: 30px; } }
  @media only screen and (max-width: 576px) {
    .useful-articles__title {
      font-size: 28px; } }

.useful-articles__card {
  width: 100%; }
  @media only screen and (max-width: 976px) {
    .useful-articles__card {
      min-height: 240px;
      padding: 43px 30px; }
      .useful-articles__card .article-card__content {
        justify-content: flex-start; }
      .useful-articles__card.article-card--big {
        justify-content: center;
        align-items: center;
        min-height: 645px; } }
    @media only screen and (max-width: 976px) and (max-width: 576px) {
      .useful-articles__card.article-card--big {
        min-height: 240px; } }
  @media only screen and (max-width: 976px) {
        .useful-articles__card.article-card--big .article-card__content {
          align-items: center; } }
      @media only screen and (max-width: 976px) and (max-width: 576px) {
        .useful-articles__card.article-card--big .article-card__content {
          align-items: flex-start; } }

@media only screen and (max-width: 976px) {
  .homepage-questions {
    width: calc(100% + 15px);
    overflow: auto; } }
  @media only screen and (max-width: 976px) and (max-width: 576px) {
    .homepage-questions {
      width: 100%; } }

.homepage-questions__inner {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  min-width: 960px; }
  @media only screen and (max-width: 576px) {
    .homepage-questions__inner {
      min-width: 100%;
      flex-direction: column;
      gap: 10px; } }

.homepage-questions__item {
  max-width: 477px;
  width: 100%;
  background: #F6F5F5;
  padding: 70px 70px 46px 65px; }
  @media only screen and (max-width: 1024px) {
    .homepage-questions__item {
      padding: 30px; } }

.homepage-reviews__title {
  max-width: 464px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 60px; }
  @media only screen and (max-width: 1024px) {
    .homepage-reviews__title {
      margin-bottom: 30px; } }
  @media only screen and (max-width: 976px) {
    .homepage-reviews__title {
      max-width: 100%; } }

.users-review {
  display: flex;
  justify-content: space-between;
  font-family: "TT Commons Pro Expanded Medium";
  color: #000; }
  @media only screen and (max-width: 976px) {
    .users-review {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px 55px; } }
  @media only screen and (max-width: 576px) {
    .users-review {
      grid-template-columns: 1fr; } }

.children-bedroom {
  display: flex;
  overflow: hidden; }
  @media only screen and (max-width: 1280px) {
    .children-bedroom {
      flex-wrap: wrap; } }

@media only screen and (max-width: 976px) {
  .section-advantages {
    width: calc(100% + 15px);
    overflow: auto; } }

.section-advantages__inner {
  display: flex;
  justify-content: space-around;
  background: #F6F5F5;
  border-radius: 50px;
  padding: 18px 0; }
  @media only screen and (max-width: 1024px) {
    .section-advantages__inner {
      padding-left: 31px;
      padding-right: 31px;
      justify-content: space-between; } }
  @media only screen and (max-width: 976px) {
    .section-advantages__inner {
      width: 960px; } }
  @media only screen and (max-width: 576px) {
    .section-advantages__inner {
      padding: 10px 15px; } }

.ymaps-2-1-79-float-button {
  width: 38px;
  height: 38px !important;
  border-radius: 11px !important; }

.ymaps-2-1-79-float-button-icon {
  padding: 5px; }

.ymaps-2-1-79-zoom {
  height: 20px !important; }

.ymaps-2-1-79-zoom__plus .ymaps-2-1-79-zoom__icon {
  background-image: url("/img/yamap_plus.svg") !important; }

.ymaps-2-1-79-zoom__minus .ymaps-2-1-79-zoom__icon {
  background-image: url("/img/yamap_minus.svg") !important; }

.ymaps-2-1-79-zoom__plus {
  border-radius: 10px 10px 0 0 !important; }

.ymaps-2-1-79-zoom__minus {
  border-radius: 0 0 10px 10px !important; }

.ymaps-2-1-79-controls__control_toolbar {
  position: absolute !important;
  top: 256px;
  right: 13px; }

.ymaps-2-1-79-controls__control {
  position: absolute !important;
  right: 23px !important;
  top: 177px !important; }

.custom-select {
  position: relative; }
  .custom-select__img {
    display: none; }
  .custom-select__option {
    display: flex;
    justify-content: space-between;
    padding: 0 25px 0 0;
    cursor: pointer; }
  .custom-select--open .custom-select__header {
    border-radius: 27px 27px 0 0;
    box-shadow: 0px 10px 31px rgba(0, 0, 0, 0.12);
    border: 1px solid #24306B; }
  .custom-select--open .custom-select__arrow .icon {
    transform: rotate(180deg); }
  .custom-select--open .custom-select__body {
    transform: translateY(0px);
    opacity: 1;
    pointer-events: auto; }
  .custom-select__header {
    min-width: 155px;
    border-radius: 27px;
    background: #ffffff;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    width: 100%;
    transition: background, border ease .2s;
    border: 1px solid #EDEDED; }
    .custom-select__header:hover {
      border: 1px solid #24306B; }
    .custom-select__header--close {
      border-radius: 27px 27px 0px 0px; }
    .custom-select__header--big {
      padding: 19px 25px; }
    .custom-select__header--min {
      padding: 10px 15px; }
  .custom-select__body {
    position: absolute;
    top: 53px;
    left: 0;
    right: 0;
    background: #fff;
    transition: box-shadow ease .3s, transform ease .3s, opacity ease .3s;
    z-index: 10;
    transform: translateY(15px);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    overflow-y: auto;
    border-top: 1px solid transparent;
    border: 1px solid #24306B;
    border-radius: 0px 0px 27px 27px; }
    .custom-select__body--min {
      top: 35px;
      box-shadow: 0px 10px 31px rgba(0, 0, 0, 0.12);
      border: transparent; }
  .custom-select__selected {
    line-height: 1;
    font-size: 14px;
    color: #000000;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .custom-select__selected--double-text {
      padding-top: 15px; }
  .custom-select__arrow {
    color: #000000;
    margin-left: 27px;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .custom-select .icon {
    font-size: 11px !important;
    transition: transform ease .3s; }
  .custom-select__label {
    padding: 12px 7px 15px 15px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    background: #fff;
    transition: background ease .3s;
    cursor: pointer;
    display: block;
    user-select: none; }
    .custom-select__label:hover {
      color: #3B4FB0; }
  .custom-select [type="checkbox"],
  .custom-select [type="radio"] {
    display: none; }
    .custom-select [type="checkbox"]:checked ~ .custom-select__img,
    .custom-select [type="radio"]:checked ~ .custom-select__img {
      display: block; }
    .custom-select [type="checkbox"]:checked ~ .custom-select__label,
    .custom-select [type="radio"]:checked ~ .custom-select__label {
      color: #3B4FB0; }
  .custom-select__svg {
    width: 9px;
    height: 9px;
    color: #24306B; }
  .custom-select__description {
    font-family: "TT Commons Pro Expanded Regular";
    font-size: 13px;
    line-height: 16px;
    color: #000;
    opacity: 0.5;
    position: absolute;
    top: 10px; }

.checkbox-btn {
  display: inline-block;
  margin: 0 5px 0 0;
  user-select: none;
  position: relative; }
  @media only screen and (max-width: 976px) {
    .checkbox-btn {
      margin-right: 15px; } }
  .checkbox-btn--brown span {
    background: #F6F5F5; }
  .checkbox-btn input[type=checkbox] {
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0; }
  .checkbox-btn span {
    display: inline-block;
    cursor: pointer;
    padding: 10px 13px;
    border: 1px solid #EDEDED;
    border-radius: 10px;
    transition: 0.2s ease;
    font-size: 12px;
    line-height: 18px;
    color: #000000; }
  .checkbox-btn input[type=checkbox]:checked + span {
    color: #fff;
    background: #24306B; }

/* Checked */
/* Focus */
.focused span {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

/* Hover */
.checkbox-btn:hover span {
  color: #24306B; }

.checkbox-btn input[type=checkbox]:active:not(:disabled) + span {
  color: #000; }

.checkbox-btn input[type=checkbox]:disabled + span {
  background: #d7d3d3;
  color: #9d9999;
  cursor: not-allowed; }

.checkbox-form {
  max-width: 220px;
  display: flex;
  padding: 10px 20px;
  gap: 8px; }
  @media only screen and (max-width: 576px) {
    .checkbox-form {
      align-items: center;
      max-width: 100%;
      padding: 0; } }
  .checkbox-form__input {
    width: 33px;
    height: 20px; }
  .checkbox-form__text {
    font-size: 14px;
    color: #000; }

.custom-checkbox {
  display: flex;
  gap: 8px;
  cursor: pointer; }
  .custom-checkbox__input {
    width: 20px;
    height: 20px; }
  .custom-checkbox__text {
    font-size: 14px; }

.ui-chackbox-forms form {
  margin-bottom: 20px; }

.page-home__section-slider {
  margin: 0 0 50px; }
  @media only screen and (max-width: 576px) {
    .page-home__section-slider {
      margin-bottom: 30px; } }

.page-home__bedding {
  position: relative;
  width: calc(100% + 50px);
  padding: 0 25px;
  transform: translateX(-25px);
  margin: 60px 0; }
  @media only screen and (max-width: 976px) {
    .page-home__bedding {
      width: calc(100% + 65px); } }
  @media only screen and (max-width: 976px) {
    .page-home__bedding .common-slider__btn--prev {
      left: 15px; } }
  .page-home__bedding .swiper-button-disabled {
    opacity: 0;
    cursor: none; }
    @media only screen and (max-width: 1024px) {
      .page-home__bedding .swiper-button-disabled {
        margin-top: 15px; }
        .page-home__bedding .swiper-button-disabled .common-slider__btn {
          display: none; } }
  @media only screen and (max-width: 976px) {
    .page-home__bedding {
      margin-top: 0; } }

.page-home__border {
  margin: 60px 0;
  border: 1px solid rgba(0, 0, 0, 0.1); }

.page-home__sleeping-systems_subtitle--min {
  margin-bottom: 22px; }
  @media only screen and (max-width: 976px) {
    .page-home__sleeping-systems_subtitle--min + .section-advantages {
      margin-bottom: 60px; } }
  @media only screen and (max-width: 576px) {
    .page-home__sleeping-systems_subtitle--min {
      margin-bottom: 30px; } }

.page-home__services {
  margin-bottom: 60px; }
  @media only screen and (max-width: 976px) {
    .page-home__services {
      margin-bottom: 30px; } }
  .page-home__services__novelties-slider {
    margin-bottom: 60px; }

.page-home__reviews {
  margin-top: 60px;
  margin-bottom: 55px; }

.page-home__questions {
  margin-bottom: 63px; }

.page-home__useful-articles {
  margin-bottom: 60px; }

.sofa-article-link {
  position: absolute; }
  .sofa-article-link__link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2; }
    .sofa-article-link__link:hover ~ .sofa-link__text .sofa-article-link__btn {
      background: #F6F5F5;
      color: #24306B; }
  .sofa-article-link__title {
    padding: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    background: rgba(255, 255, 255, 0.5);
    mix-blend-mode: normal;
    backdrop-filter: blur(4px);
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    text-decoration: none; }
    @media only screen and (max-width: 576px) {
      .sofa-article-link__title {
        padding: 3px 10px;
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 1; } }
    .sofa-article-link__title--children-article {
      padding: 4px 10px; }
    .sofa-article-link__title--article-dark {
      background: rgba(154, 154, 154, 0.2); }
  .sofa-article-link__subtitle {
    font-size: 27px;
    line-height: 35px;
    color: #000000;
    margin: 20px 0 24px;
    font-family: "TT Commons Pro Expanded Medium"; }
    @media only screen and (max-width: 1280px) {
      .sofa-article-link__subtitle {
        font-size: 40px;
        line-height: 50px; } }
    @media only screen and (max-width: 976px) {
      .sofa-article-link__subtitle {
        font-size: 30px;
        line-height: 37.5px; } }
    @media only screen and (max-width: 576px) {
      .sofa-article-link__subtitle {
        margin: 0 0 15px 0;
        font-size: 14px;
        line-height: 17.7px; } }
    .sofa-article-link__subtitle--min {
      font-size: 23px;
      line-height: 27px; }
    .sofa-article-link__subtitle--main-slider {
      font-weight: 500;
      font-size: 40px;
      line-height: 50px; }
    .sofa-article-link__subtitle--children-article {
      font-size: 23px;
      max-width: 279px; }
  .sofa-article-link__btn {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    line-height: 20px; }
    @media only screen and (max-width: 576px) {
      .sofa-article-link__btn {
        font-size: 12px;
        line-height: 140%; } }

.top-slider {
  display: flex;
  justify-content: space-between; }

.main-slide {
  position: relative; }
  .main-slide__text {
    max-width: 334px;
    top: 122px;
    right: 163px; }
    @media only screen and (max-width: 1280px) {
      .main-slide__text {
        max-width: 465px;
        right: 50px; } }
    @media only screen and (max-width: 1024px) {
      .main-slide__text {
        top: 106px; } }
    @media only screen and (max-width: 976px) {
      .main-slide__text {
        max-width: 330px;
        top: 60px;
        right: 36px; } }
    @media only screen and (max-width: 576px) {
      .main-slide__text {
        top: 30px;
        max-width: 155px; } }
  .main-slide__image {
    height: 540px; }
    .main-slide__image img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
    @media only screen and (max-width: 1024px) {
      .main-slide__image {
        height: 430px; } }
    @media only screen and (max-width: 976px) {
      .main-slide__image {
        height: 360px; } }
    @media only screen and (max-width: 576px) {
      .main-slide__image {
        height: 160px; } }

.section-slider {
  display: flex;
  justify-content: space-between;
  gap: 20px; }
  @media only screen and (max-width: 976px) {
    .section-slider {
      gap: 10px; } }
  @media only screen and (max-width: 576px) {
    .section-slider {
      flex-direction: column;
      gap: 30px; } }

.btn-systems {
  display: flex;
  gap: 14px;
  padding: 13px 20px;
  background: #F6F5F5;
  border-radius: 50px;
  align-items: center;
  cursor: pointer;
  color: #24306B;
  transition: .3s; }
  .btn-systems--white {
    background: #fff;
    color: #000;
    padding: 0; }
    .btn-systems--white:hover {
      background: #fff !important;
      color: #000 !important; }
  .btn-systems:hover {
    background: #24306B;
    color: #fff; }
  .btn-systems:hover .btn-systems__icon {
    color: #fff; }
  .btn-systems__material {
    font-family: "TT Commons Pro Expanded Medium";
    font-size: 14px;
    line-height: 18px;
    opacity: 0.5; }
  .btn-systems--long {
    justify-content: space-between;
    color: #000;
    width: 100%;
    margin-bottom: 20px; }
  .btn-systems__description {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  .btn-systems__text {
    font-family: "TT Commons Pro Expanded Medium";
    font-size: 14px;
    line-height: 18px; }
  .btn-systems__icon {
    width: 16px;
    color: #24306B;
    transition: .3s; }

.big-article {
  position: relative; }
  .big-article__img {
    max-width: 959px;
    width: 100%;
    object-fit: contain; }
    .big-article__img .img {
      width: 100%; }
  .big-article__wrapper {
    position: absolute;
    top: 1px; }

.product-page {
  max-width: 1472px;
  padding-left: 16px;
  padding-right: 16px; }
  @media only screen and (max-width: 1280px) {
    .product-page {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 1024px) {
    .product-page {
      padding-left: 32px;
      padding-right: 32px; } }
  @media only screen and (max-width: 976px) {
    .product-page {
      padding-left: 15px;
      padding-right: 15px; } }
  .product-page__bread-crumbs {
    margin: 30px 0 44px; }
  .product-page__product-info {
    margin-bottom: 60px; }
    @media only screen and (max-width: 976px) {
      .product-page__product-info {
        margin-bottom: 30px; } }
  .product-page__product-advantages {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    gap: 10px; }
    @media only screen and (max-width: 976px) {
      .product-page__product-advantages {
        margin-bottom: 30px; } }
  .product-page__reviews {
    margin-bottom: 70px; }
  .product-page__product-characteristics {
    gap: 40px;
    margin-bottom: 60px; }
    @media only screen and (max-width: 976px) {
      .product-page__product-characteristics {
        gap: 20px; } }
  .product-page__product-description {
    margin-bottom: 60px; }
  .product-page__similar-slider {
    margin-bottom: 60px; }
  .product-page__related-slider {
    margin-bottom: 60px; }
  .product-page__double-card {
    margin-bottom: 60px; }
  .product-page__common-questions {
    margin-bottom: 60px; }

.product-slider {
  display: none; }
  @media only screen and (max-width: 976px) {
    .product-slider {
      display: block; } }
  @media only screen and (max-width: 576px) {
    .product-slider {
      margin-bottom: 25px; } }
  .product-slider__slide img {
    width: 100%;
    object-fit: cover; }

.hide-more {
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-decoration: none;
  color: #24306B;
  display: none; }
  .hide-more--show {
    display: flex;
    opacity: 1;
    -webkit-animation: display-none-transition 1s both;
    animation: display-none-transition 1s both; }

@-webkit-keyframes display-none-transition {
  0% {
    opacity: 0; } }

@keyframes display-none-transition {
  0% {
    opacity: 0; } }

.show-more {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-decoration: none;
  color: #24306B; }
  .show-more--hide {
    display: none; }
  .show-more__link-icon {
    color: #24306B;
    width: 10px;
    height: 10px; }
    .show-more__link-icon--up {
      transform: rotate(180deg); }

.more-cards {
  display: none; }
  .more-cards--show {
    display: block;
    opacity: 1;
    -webkit-animation: display-none-transition 1s both;
    animation: display-none-transition 1s both; }

@-webkit-keyframes display-none-transition {
  0% {
    opacity: 0; } }

@keyframes display-none-transition {
  0% {
    opacity: 0; } }
  .more-cards__review {
    margin-top: 30px; }

.js-hide-photo,
.js-product-card-hide,
.js-hide-review {
  display: none;
  opacity: 1;
  -webkit-animation: display-none-transition 1s both;
  animation: display-none-transition 1s both; }

@-webkit-keyframes display-none-transition {
  0% {
    opacity: 0; } }

@keyframes display-none-transition {
  0% {
    opacity: 0; } }

.catalogue-page.container--min {
  padding-left: 16px;
  padding-right: 16px; }

.catalogue-page__bread-crumbs {
  margin: 30px 0 60px; }

.catalogue-page__wrapper {
  display: flex;
  justify-content: space-between;
  gap: 60px; }
  @media only screen and (max-width: 1280px) {
    .catalogue-page__wrapper {
      gap: 30px; } }

.catalogue-page__filter {
  max-width: 236px; }
  @media only screen and (max-width: 976px) {
    .catalogue-page__filter {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100vw;
      max-width: 100vw;
      padding: 24px 15px;
      overflow: auto;
      z-index: 100;
      background-color: #fff;
      transform: translateX(100%);
      transition: transform .3s linear; }
      .catalogue-page__filter--active {
        transform: translateX(0); }
      .catalogue-page__filter .select-section__buttons {
        position: sticky;
        bottom: 0; } }

.catalogue-page__content {
  width: calc(100% - 291px); }
  @media only screen and (max-width: 1280px) {
    .catalogue-page__content {
      width: calc(100% - 261px); } }
  @media only screen and (max-width: 976px) {
    .catalogue-page__content {
      width: 100%; } }

.catalogue-page__questions {
  margin-bottom: 60px; }

.catalogue-page__description {
  margin-bottom: 60px; }

.filter-column__title {
  display: none; }
  @media only screen and (max-width: 976px) {
    .filter-column__title {
      display: block;
      margin-bottom: 20px;
      font-size: 16px;
      font-family: "TT Commons Pro Expanded Medium";
      text-align: center; } }

@media only screen and (max-width: 976px) {
  .filter-column .btn-reset {
    position: fixed;
    top: 20px;
    left: 15px;
    padding: 0; }
    .filter-column .btn-reset svg {
      display: none; } }

.filter-column__close {
  display: none; }
  @media only screen and (max-width: 976px) {
    .filter-column__close {
      display: block;
      position: absolute;
      top: 20px;
      right: 15px;
      padding: 0; } }

.filter-column__select-section {
  margin-bottom: 30px; }
  @media only screen and (max-width: 976px) {
    .filter-column__select-section {
      margin-bottom: 0; } }

.select-section {
  display: flex;
  flex-direction: column;
  gap: 30px; }
  @media only screen and (max-width: 976px) {
    .select-section {
      gap: 0; } }
  @media only screen and (max-width: 976px) {
    .select-section .smart-filter-parameters-box {
      max-width: 330px; } }
  @media only screen and (max-width: 976px) {
    .select-section .custom-select {
      max-width: 330px; } }
  @media only screen and (max-width: 976px) {
    .select-section__select {
      padding-top: 40px;
      padding-bottom: 40px;
      border-top: 1px solid #EDEDED; }
      .select-section__select:first-child {
        padding-top: 25px; } }
  .select-section__title {
    font-size: 14px;
    font-family: "TT Commons Pro Expanded Medium";
    margin-bottom: 15px; }
  .select-section__buttons {
    display: flex;
    flex-direction: column;
    gap: 25px; }

.select-form {
  display: flex;
  flex-wrap: wrap;
  gap: 7px 0; }

.catalogue-title {
  display: flex;
  align-items: end;
  gap: 15px; }
  .catalogue-title__subtitle {
    font-size: 40px;
    color: #000;
    font-family: "TT Commons Pro Expanded Medium"; }
    @media only screen and (max-width: 976px) {
      .catalogue-title__subtitle {
        font-size: 30px; } }
    @media only screen and (max-width: 576px) {
      .catalogue-title__subtitle {
        font-size: 28px; } }
  .catalogue-title__text {
    font-size: 16px;
    color: #000;
    opacity: 0.5;
    padding-bottom: 5px; }

.catalogue-content__filter-dropdown {
  display: none; }
  @media only screen and (max-width: 976px) {
    .catalogue-content__filter-dropdown {
      display: block; } }
  .catalogue-content__filter-dropdown-btn {
    padding: 0; }
    .catalogue-content__filter-dropdown-btn:focus {
      box-shadow: none; }
  .catalogue-content__filter-dropdown-icon {
    display: none; }
  .catalogue-content__filter-dropdown .dropdown-item--active + .catalogue-content__filter-dropdown-icon {
    display: block; }
  .catalogue-content__filter-dropdown-menu {
    width: 240px;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    border: 1px solid #F0F0F0;
    box-shadow: 0 10px 31px rgba(0, 0, 0, 0.12);
    border-radius: 27px; }
    .catalogue-content__filter-dropdown-menu li {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .catalogue-content__filter-dropdown-menu.show {
      display: flex; }
    .catalogue-content__filter-dropdown-menu .dropdown-item {
      padding: 0; }
  .catalogue-content__filter-dropdown-title {
    font-size: 16px;
    font-weight: 700; }

.catalogue-content__title {
  margin-bottom: 30px; }
  @media only screen and (max-width: 976px) {
    .catalogue-content__title {
      margin-bottom: 25px; } }

.catalogue-content__filter {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px 0;
  margin-bottom: 30px; }

.catalogue-content__filter-wrapp {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media only screen and (max-width: 976px) {
    .catalogue-content__filter-wrapp {
      margin-bottom: 45px; } }

.catalogue-content__open-filter {
  display: none;
  padding: 0; }
  @media only screen and (max-width: 976px) {
    .catalogue-content__open-filter {
      display: block; } }

.catalogue-content__sort {
  display: flex;
  justify-content: flex-start;
  gap: 23px;
  margin-bottom: 30px; }
  @media only screen and (max-width: 976px) {
    .catalogue-content__sort {
      display: none; } }

.catalogue-content__cards {
  margin-bottom: 40px; }

.catalogue-content__btn-show {
  margin-bottom: 76px; }
  @media only screen and (max-width: 976px) {
    .catalogue-content__btn-show {
      margin-bottom: 60px; } }

.catalogue-content__pagination {
  justify-content: flex-start;
  margin-bottom: 60px; }

.filter-item {
  padding: 10px 15px;
  border-radius: 50px;
  font-size: 14px;
  display: inline-block;
  margin: 0 5px 0 0;
  user-select: none;
  background: #F6F5F5; }

.item-card {
  height: 100%;
  max-width: 100%;
  opacity: 1;
  -webkit-animation: display-none-transition 1s both;
  animation: display-none-transition 1s both; }

@-webkit-keyframes display-none-transition {
  0% {
    opacity: 0; } }

@keyframes display-none-transition {
  0% {
    opacity: 0; } }
  .item-card--hide {
    display: none; }
  .item-card__border {
    position: relative;
    width: calc(100% + 20px);
    height: 1px;
    margin-top: auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 15px; }
    @media only screen and (max-width: 576px) {
      .item-card__border {
        width: 100%; } }
  .item-card__characteristics {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100px;
    margin-bottom: 15px; }
    @media only screen and (max-width: 576px) {
      .item-card__characteristics {
        min-height: 95px; } }
  .item-card__comparison-title {
    font-size: 12px;
    color: #808080;
    margin-bottom: 10px;
    text-transform: uppercase; }
  .item-card__comparison-text {
    position: relative; }
  .item-card__price {
    display: flex;
    align-items: center;
    min-height: 31px;
    gap: 15px;
    margin-bottom: 10px; }
    @media only screen and (max-width: 1280px) {
      .item-card__price {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px; } }
  .item-card__slider {
    margin-bottom: 20px; }
    @media only screen and (max-width: 576px) {
      .item-card__slider {
        margin-bottom: 18px; } }
  .item-card__title {
    margin-bottom: auto; }
    @media only screen and (max-width: 576px) {
      .item-card__title {
        font-size: 15px; } }
    .item-card__title--wide {
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 10px;
      overflow: hidden; }
  .item-card__rating--wide {
    margin-bottom: 30px; }
  .item-card__advantages {
    gap: 7px;
    margin-bottom: 27px; }
  .item-card__slider-size {
    max-width: 100%;
    overflow: hidden;
    margin-bottom: 13px; }
  .item-card__img {
    position: relative;
    max-width: 270px;
    width: 100%;
    height: 162px;
    object-fit: contain; }
    @media only screen and (max-width: 1280px) {
      .item-card__img {
        height: 130px; } }
    @media only screen and (max-width: 576px) {
      .item-card__img {
        height: 95px; } }
    .item-card__img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .item-card__image {
    width: 100%;
    height: 100%; }

.item-slider__pagination {
  position: absolute;
  bottom: 5px !important; }

.item-slider__item {
  position: relative; }
  .item-slider__item--wide {
    margin-bottom: 24px;
    display: flex; }
    @media only screen and (max-width: 576px) {
      .item-slider__item--wide {
        margin-bottom: 20px; } }

.item-slider__new {
  position: absolute;
  display: flex;
  gap: 8px;
  top: 0; }
  @media only screen and (max-width: 976px) {
    .item-slider__new {
      left: 1px; } }

.item-slider__img {
  width: 100%;
  height: 160px; }
  @media only screen and (max-width: 1280px) {
    .item-slider__img {
      height: 130px; } }
  @media only screen and (max-width: 976px) {
    .item-slider__img {
      height: 140px; } }
  @media only screen and (max-width: 576px) {
    .item-slider__img {
      height: 100px; } }

.item-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.size-slider__item {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  padding: 0; }
  .size-slider__item:focus {
    box-shadow: none; }
  .size-slider__item--active {
    color: #000;
    text-decoration: underline; }

.catalogue-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 50px 22px; }
  @media only screen and (max-width: 576px) {
    .catalogue-cards {
      gap: 40px 15px; } }
  .catalogue-cards .catalogue-content__card {
    width: calc(25% - 17px); }
    @media only screen and (max-width: 1024px) {
      .catalogue-cards .catalogue-content__card {
        width: calc(33.33% - 15px); } }
    @media only screen and (max-width: 576px) {
      .catalogue-cards .catalogue-content__card {
        width: calc(50% - 8px); }
        .catalogue-cards .catalogue-content__card:nth-child(2n) {
          order: 1; }
        .catalogue-cards .catalogue-content__card:nth-child(n+3) {
          order: 3; } }
  .catalogue-cards__link {
    width: calc(25% - 17px); }
    @media only screen and (max-width: 1024px) {
      .catalogue-cards__link {
        width: calc(33.33% - 15px);
        padding: 16px; }
        .catalogue-cards__link .sofa-article-link__subtitle {
          font-size: 16px; } }
    @media only screen and (max-width: 576px) {
      .catalogue-cards__link {
        width: 100%;
        order: 2;
        padding: 40px 20px; } }

.company-description__title {
  font-family: "TT Commons Pro Expanded Medium";
  font-size: 30px;
  color: #000;
  opacity: 0.5;
  margin-bottom: 20px; }

.company-description__text {
  font-size: 16px;
  color: #000;
  opacity: 0.5; }

.product-item {
  display: flex;
  flex-direction: column;
  height: 100%; }

.about-page {
  max-width: 1472px;
  padding-left: 16px;
  padding-right: 16px; }
  .about-page .container {
    padding: 0; }
  @media only screen and (max-width: 1280px) {
    .about-page {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 1024px) {
    .about-page {
      padding-left: 32px;
      padding-right: 32px; } }
  @media only screen and (max-width: 976px) {
    .about-page {
      padding-left: 15px;
      padding-right: 15px; } }
  .about-page__top {
    margin-bottom: 41px; }
  .about-page__title {
    margin-bottom: 60px;
    text-align: center; }
    @media only screen and (max-width: 976px) {
      .about-page__title {
        margin-bottom: 40px; } }
    @media only screen and (max-width: 576px) {
      .about-page__title {
        margin-bottom: 30px; } }
  .about-page__review {
    margin-bottom: 56px; }
  .about-page__text {
    margin-bottom: 60px; }
  .about-page__cards {
    margin-bottom: 80px; }
    @media only screen and (max-width: 976px) {
      .about-page__cards {
        margin-bottom: 60px; } }
  .about-page__amount-daily {
    margin-bottom: 123px; }
    @media only screen and (max-width: 976px) {
      .about-page__amount-daily {
        margin-bottom: 60px; } }
  .about-page__bottom {
    margin-bottom: 80px; }
    @media only screen and (max-width: 976px) {
      .about-page__bottom {
        margin-bottom: 60px; } }
  .about-page__bread-crumbs {
    margin: 30px 0 40px; }

.section-double {
  display: flex; }
  @media only screen and (max-width: 976px) {
    .section-double {
      flex-direction: column; } }
  .section-double__link {
    max-width: 861px;
    width: 100%; }
  .section-double__link-city {
    max-width: 579px;
    width: 100%; }
    @media only screen and (max-width: 976px) {
      .section-double__link-city {
        max-width: 100%;
        min-height: 375px;
        align-items: flex-end; } }
    @media only screen and (max-width: 576px) {
      .section-double__link-city {
        min-height: 200px; } }
  .section-double__background:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    background: radial-gradient(65.35% 65.72% at 37.82% 21.54%, rgba(213, 223, 233, 0) 0%, #98ACBE 100%);
    opacity: 0.8; }

.usual-card {
  position: relative;
  min-height: 475px;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 1024px) {
    .usual-card {
      min-height: 310px; } }
  @media only screen and (max-width: 976px) {
    .usual-card {
      min-height: 375px; } }
  @media only screen and (max-width: 576px) {
    .usual-card {
      min-height: 200px; } }
  .usual-card__preview {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 861px;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex; }
  .usual-card__img {
    width: 100%;
    height: auto;
    object-fit: cover; }
    .usual-card__img img {
      object-fit: cover; }
  .usual-card__content {
    position: relative;
    z-index: 2; }
  .usual-card__text {
    font-size: 40px;
    font-family: "TT Commons Pro Expanded Medium";
    text-align: center;
    max-width: 530px; }
    @media only screen and (max-width: 1024px) {
      .usual-card__text {
        max-width: 400px;
        font-size: 30px; } }
    @media only screen and (max-width: 576px) {
      .usual-card__text {
        font-size: 20px; } }

.text-block {
  background: #F9F9F9;
  border: 2px solid #FFFFFF; }
  .text-block--flex {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none; }
  .text-block__text {
    max-width: 1033px;
    width: 100%;
    font-size: 20px;
    padding: 65px 0;
    margin: 0 auto; }
    @media only screen and (max-width: 1024px) {
      .text-block__text {
        padding: 40px; } }
    @media only screen and (max-width: 976px) {
      .text-block__text {
        padding: 30px;
        font-size: 16px; } }
    @media only screen and (max-width: 576px) {
      .text-block__text {
        padding: 20px; } }
    .text-block__text--min {
      max-width: 528px; }

.quadruple-card {
  display: grid;
  grid-template-columns: 1fr 1fr; }
  @media only screen and (max-width: 576px) {
    .quadruple-card {
      grid-template-columns: 1fr; } }
  .quadruple-card__img .img {
    width: 100%;
    height: 100%; }

.amount-goods__title {
  color: #000;
  font-family: "TT Commons Pro Expanded Medium";
  font-size: 35px;
  text-align: center;
  margin-bottom: 31px; }
  @media only screen and (max-width: 976px) {
    .amount-goods__title {
      font-size: 30px; } }
  @media only screen and (max-width: 576px) {
    .amount-goods__title {
      font-size: 28px; } }

.amount-goods__content {
  display: flex;
  justify-content: space-around; }
  @media only screen and (max-width: 976px) {
    .amount-goods__content {
      flex-direction: column;
      gap: 60px; } }
  @media only screen and (max-width: 576px) {
    .amount-goods__content {
      gap: 30px; } }

.amount-item__num {
  color: #24306B;
  font-size: 100px;
  font-family: "TT Commons Pro Expanded Medium";
  text-align: center; }
  @media only screen and (max-width: 576px) {
    .amount-item__num {
      font-size: 64px; } }

.amount-item__description {
  text-align: center;
  color: #000;
  font-size: 20px;
  font-family: "TT Commons Pro Expanded Medium"; }
  @media only screen and (max-width: 576px) {
    .amount-item__description {
      font-size: 16px; } }

.salons-page {
  max-width: 1472px;
  padding-left: 16px;
  padding-right: 16px; }
  .salons-page .container {
    padding: 0; }
  @media only screen and (max-width: 1280px) {
    .salons-page {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 1024px) {
    .salons-page {
      padding-left: 32px;
      padding-right: 32px; } }
  @media only screen and (max-width: 976px) {
    .salons-page {
      padding-left: 15px;
      padding-right: 15px; } }
  .salons-page__title {
    margin-bottom: 60px;
    text-align: center; }
  .salons-page__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 80px 35px;
    margin-bottom: 80px; }
    @media only screen and (max-width: 976px) {
      .salons-page__list {
        gap: 50px 35px;
        grid-template-columns: repeat(2, 1fr); } }
    @media only screen and (max-width: 576px) {
      .salons-page__list {
        grid-template-columns: 1fr;
        gap: 40px; } }
  .salons-page__map {
    height: 480px;
    width: 100%;
    margin-bottom: 60px; }
    @media only screen and (max-width: 576px) {
      .salons-page__map {
        height: 300px;
        overflow: hidden; } }
  .salons-page__bread-crumbs {
    margin: 30px 0 40px; }

.info-card {
  max-width: 333px;
  width: 100%; }
  .info-card--wide {
    max-width: 344px; }
  .info-card__link {
    color: #24306B;
    display: flex;
    font-size: 15px;
    margin-bottom: 10px; }
  .info-card__title {
    font-size: 18px;
    color: #24306B;
    font-family: "TT Commons Pro Expanded Medium";
    margin-bottom: 20px; }
  .info-card__subtitle {
    margin-bottom: 20px; }
  .info-card__text {
    font-size: 15px;
    display: flex;
    text-decoration: none;
    color: #000; }
    .info-card__text--usual {
      font-size: 14px;
      margin-bottom: 10px; }
    .info-card__text--num {
      font-size: 18px; }
    .info-card__text--grey {
      color: #808080;
      margin-bottom: 10px; }

.image-string {
  display: flex;
  gap: 14px; }
  .image-string__img:first-child {
    width: 20px;
    height: 20px; }
  .image-string__img {
    color: #24306B;
    width: 17px;
    height: 17px; }
  .image-string__text {
    font-size: 15px;
    min-height: 40px; }

.blog-page {
  max-width: 1472px;
  padding-left: 16px;
  padding-right: 16px; }
  .blog-page .container {
    padding: 0; }
  @media only screen and (max-width: 1280px) {
    .blog-page {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 1024px) {
    .blog-page {
      padding-left: 32px;
      padding-right: 32px; } }
  @media only screen and (max-width: 976px) {
    .blog-page {
      padding-left: 15px;
      padding-right: 15px; } }
  .blog-page__title {
    margin-bottom: 40px;
    text-align: center; }
  .blog-page__bread-crumbs {
    margin: 30px 0 40px; }
  .blog-page__links {
    gap: 10px;
    display: flex;
    margin-bottom: 40px; }
    @media only screen and (max-width: 576px) {
      .blog-page__links {
        margin-bottom: 30px; } }
  .blog-page__title {
    margin-bottom: 20px;
    text-align: center; }
  .blog-page__content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 36px 30px;
    margin-bottom: 40px; }
    @media only screen and (max-width: 1024px) {
      .blog-page__content {
        grid-template-columns: repeat(3, 1fr);
        gap: 25px 20px; } }
    @media only screen and (max-width: 576px) {
      .blog-page__content {
        grid-template-columns: 1fr; } }
  @media only screen and (max-width: 976px) {
    .blog-page__catalog {
      grid-template-columns: repeat(2, 1fr); }
      .blog-page__catalog .article-card--min {
        max-width: 100%; } }
  @media only screen and (max-width: 576px) {
    .blog-page__catalog {
      grid-template-columns: 1fr; } }
  .blog-page__catalog .article-card__title {
    text-align: center; }
  .blog-page__bread-crumbs {
    margin: 30px 0 40px; }
  .blog-page__btn {
    width: 100%; }

.link-item {
  background: rgba(213, 223, 233, 0.47);
  padding: 10px;
  text-decoration: none;
  color: #000; }
  @media only screen and (max-width: 576px) {
    .link-item {
      font-size: 12px; } }
  .link-item--min {
    padding: 4px 10px;
    background: rgba(154, 154, 154, 0.2); }

.blog-card__title {
  margin-bottom: 30px; }

.blog-card__links {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 36px 30px;
  margin-bottom: 60px; }
  .blog-card__links .article-card:nth-child(n+5) {
    display: none !important; }
  .blog-card__links .blog-card__btn {
    display: none; }
  @media only screen and (max-width: 1280px) {
    .blog-card__links {
      gap: 30px 24px; } }
  @media only screen and (max-width: 1024px) {
    .blog-card__links {
      grid-template-columns: repeat(3, 1fr); }
      .blog-card__links .article-card:nth-child(n+4) {
        display: none !important; } }
  @media only screen and (max-width: 976px) {
    .blog-card__links {
      gap: 20px; } }
  @media only screen and (max-width: 576px) {
    .blog-card__links {
      grid-template-columns: 1fr;
      gap: 40px; } }

.blog-card__bread-crumbs {
  margin: 30px 0 60px; }

.blog-card__btn {
  width: 100%; }

.article-description {
  display: flex;
  gap: 70px;
  margin-bottom: 60px; }
  @media only screen and (max-width: 976px) {
    .article-description {
      flex-direction: column;
      gap: 30px; } }
  .article-description__img {
    max-width: 575px;
    width: 100%; }
    @media only screen and (max-width: 1280px) {
      .article-description__img {
        max-width: 475px; } }
    @media only screen and (max-width: 1024px) {
      .article-description__img {
        max-width: 100%;
        max-height: 400px; } }
    .article-description__img .img {
      width: 100%;
      object-fit: cover; }
      @media only screen and (max-width: 1024px) {
        .article-description__img .img {
          height: 400px; } }
      @media only screen and (max-width: 576px) {
        .article-description__img .img {
          height: 270px; } }

.text-article__link {
  display: inline-block;
  margin-bottom: 10px; }

.text-article__title {
  text-align: left;
  margin-bottom: 30px; }
  @media only screen and (max-width: 1024px) {
    .text-article__title {
      margin-bottom: 20px; } }

.text-article__text {
  font-size: 18px;
  line-height: 34px;
  margin-bottom: 30px; }
  @media only screen and (max-width: 1024px) {
    .text-article__text {
      font-size: 16px;
      line-height: 24px; } }

.text-article {
  max-width: 794px; }
  @media only screen and (max-width: 1024px) {
    .text-article {
      max-width: 460px; } }
  @media only screen and (max-width: 976px) {
    .text-article {
      max-width: 100%; } }

.social-share {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 250px; }
  .social-share__text {
    padding-right: 10px; }

.contacts-page {
  max-width: 1472px;
  padding-left: 16px;
  padding-right: 16px; }
  .contacts-page .container {
    padding: 0; }
  @media only screen and (max-width: 1280px) {
    .contacts-page {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 1024px) {
    .contacts-page {
      padding-left: 32px;
      padding-right: 32px; } }
  @media only screen and (max-width: 976px) {
    .contacts-page {
      padding-left: 15px;
      padding-right: 15px; } }
  .contacts-page__title {
    margin-bottom: 40px;
    text-align: center; }
  .contacts-page__bread-crumbs {
    margin: 30px 0 40px; }

.main-title {
  font-size: 35px;
  color: #000;
  font-family: "TT Commons Pro Expanded Medium"; }
  @media only screen and (max-width: 1024px) {
    .main-title {
      font-size: 30px; } }
  @media only screen and (max-width: 576px) {
    .main-title {
      font-size: 28px; } }

.contacts-content {
  display: flex;
  gap: 44px;
  margin-bottom: 60px; }
  @media only screen and (max-width: 1280px) {
    .contacts-content {
      gap: 40px; } }
  @media only screen and (max-width: 1024px) {
    .contacts-content {
      flex-direction: column; } }
  .contacts-content__requisites {
    display: flex;
    gap: 83px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 976px) {
      .contacts-content__requisites {
        gap: 25px; } }
    @media only screen and (max-width: 576px) {
      .contacts-content__requisites {
        flex-direction: column;
        gap: 40px; } }
  .contacts-content__numbers {
    display: flex;
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .contacts-content__numbers:last-child {
      margin-bottom: 0; }
    @media only screen and (max-width: 576px) {
      .contacts-content__numbers {
        flex-direction: column;
        gap: 40px; } }
  .contacts-content__numbers:last-child {
    border-bottom: none; }
  .contacts-content__map {
    max-width: 579px;
    max-height: 475px;
    width: 100%; }
    @media only screen and (max-width: 1280px) {
      .contacts-content__map {
        max-width: 425px; } }
    @media only screen and (max-width: 1024px) {
      .contacts-content__map {
        min-height: 476px;
        align-items: flex-end; } }
    @media only screen and (max-width: 976px) {
      .contacts-content__map {
        min-height: 385px;
        max-width: 100%; } }
    @media only screen and (max-width: 576px) {
      .contacts-content__map {
        width: calc(100% + 30px);
        transform: translateX(-15px);
        min-height: 295px;
        max-width: calc(100% + 30px); } }

.stock-section {
  max-width: 1472px;
  padding-left: 16px;
  padding-right: 16px; }
  .stock-section .container {
    padding: 0; }
  @media only screen and (max-width: 1280px) {
    .stock-section {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 1024px) {
    .stock-section {
      padding-left: 32px;
      padding-right: 32px; } }
  @media only screen and (max-width: 976px) {
    .stock-section {
      padding-left: 15px;
      padding-right: 15px; } }
  .stock-section__title {
    text-align: center;
    margin-bottom: 39px; }
  .stock-section__content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 36px 30px;
    margin-bottom: 60px; }
    @media only screen and (max-width: 1024px) {
      .stock-section__content {
        grid-template-columns: repeat(3, 1fr);
        gap: 25px 20px; } }
    @media only screen and (max-width: 976px) {
      .stock-section__content {
        grid-template-columns: repeat(2, 1fr); } }
    @media only screen and (max-width: 576px) {
      .stock-section__content {
        grid-template-columns: 1fr; } }
  .stock-section__bread-crumbs {
    margin: 30px 0 40px; }
  .stock-section__btn {
    width: 100%; }

.order-formed {
  max-width: 440px;
  width: 100%;
  margin: 0 auto; }
  .order-formed--wide {
    max-width: 524px; }
  .order-formed__title {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 20px; }
  .order-formed__link {
    color: #24306B; }
  .order-formed__span {
    font-family: "TT Commons Pro Expanded Medium"; }
  .order-formed__number {
    font-size: 16px;
    font-family: "TT Commons Pro Expanded Medium";
    text-align: center;
    margin-bottom: 20px; }
  .order-formed__text {
    font-size: 16px;
    text-align: center;
    margin-bottom: 60px; }
  .order-formed__buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 365px; }
  .order-formed__button {
    max-width: 172px;
    width: 100%;
    padding: 18px 0px; }

.page-cart {
  max-width: 1472px;
  padding-left: 16px;
  padding-right: 16px; }
  .page-cart .container {
    padding: 0; }
  @media only screen and (max-width: 1280px) {
    .page-cart {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 1024px) {
    .page-cart {
      padding-left: 32px;
      padding-right: 32px; } }
  @media only screen and (max-width: 976px) {
    .page-cart {
      padding-left: 15px;
      padding-right: 15px; } }
  .page-cart__title {
    margin-bottom: 20px; }
  .page-cart__basket {
    margin-bottom: 30px; }
  .page-cart__form {
    margin-bottom: 25px; }
  .page-cart__personal {
    margin-bottom: 20px; }
  .page-cart__payment {
    margin-bottom: 20px; }
  .page-cart__total {
    margin-bottom: 25px; }
  .page-cart__bread-crumbs {
    margin: 30px 0 60px; }
  .page-cart__payment {
    display: flex;
    flex-direction: column;
    gap: 10px; }

.title-goods__title {
  font-size: 40px;
  color: #000;
  font-family: "TT Commons Pro Expanded Medium"; }
  @media only screen and (max-width: 1024px) {
    .title-goods__title {
      font-size: 30px; } }
  @media only screen and (max-width: 976px) {
    .title-goods__title {
      font-size: 28px; } }

.title-goods__text {
  font-size: 16px;
  color: #000;
  opacity: 0.5;
  margin-left: 15px; }

.personal-form {
  display: flex;
  flex-direction: column;
  gap: 10px; }

.price-basket {
  background: #D5DFE9;
  padding: 30px 20px;
  border-radius: 5px;
  max-width: 410px;
  width: 100%; }

.send-form {
  display: flex;
  flex-direction: column;
  gap: 25px; }
  .send-form__button {
    max-width: 275px; }
  .send-form__checkbox {
    max-width: 347px; }

.basket-section {
  background: #fff;
  border-radius: 10px; }
  .basket-section__content {
    max-width: 410px; }
  .basket-section__payment {
    gap: 15px;
    display: flex;
    flex-direction: column; }
  .basket-section__home-input {
    max-width: 215px;
    padding: 20px; }
  .basket-section--padd {
    padding: 40px; }
    @media only screen and (max-width: 976px) {
      .basket-section--padd {
        padding: 30px 20px; } }
  .basket-section--padd-min {
    padding: 40px 22px;
    display: flex;
    gap: 65px; }
    @media only screen and (max-width: 976px) {
      .basket-section--padd-min {
        gap: 20px; } }
    @media only screen and (max-width: 576px) {
      .basket-section--padd-min {
        flex-direction: column;
        gap: 25px; } }
  .basket-section__title {
    font-size: 20px;
    color: #000;
    font-family: "TT Commons Pro Expanded Medium";
    margin-bottom: 21px; }
  .basket-section__goods {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 50px; }
    @media only screen and (max-width: 976px) {
      .basket-section__goods {
        padding: 20px; } }
    @media only screen and (max-width: 576px) {
      .basket-section__goods {
        padding: 25px 15px; } }
  .basket-section__total {
    padding: 40px;
    display: flex;
    justify-content: space-between; }
    @media only screen and (max-width: 976px) {
      .basket-section__total {
        padding: 20px; } }
    @media only screen and (max-width: 576px) {
      .basket-section__total {
        flex-direction: column; } }
  .basket-section__street-input, .basket-section__promo {
    max-width: 410px;
    padding: 20px; }
  .basket-section__frame-input {
    max-width: 215px;
    padding: 20px; }
  .basket-section__form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px 0;
    margin-bottom: 20px; }
  .basket-section__select {
    width: 100%; }
  .basket-section__flat-input {
    max-width: 280px;
    padding: 20px; }
  .basket-section__location-delivery .form-cart__payer {
    display: flex;
    flex-direction: column;
    gap: 20px; }
  .basket-section__location-delivery .form-cart__sity {
    display: none; }
  .basket-section__location-delivery .form-cart__index {
    display: flex;
    flex-direction: column; }
  .basket-section__location-delivery .form-cart__index-label {
    margin-bottom: 20px; }
  .basket-section__location-delivery .dropdown-block {
    width: 100%;
    padding: 15px 40px;
    border-radius: 50px;
    border: 1px solid #f0f0f0; }
  .basket-section__location-delivery .bx-sls .dropdown-icon {
    top: 24px;
    left: 15px; }
  .basket-section__location-delivery .bx-sls .bx-ui-sls-clear {
    top: 15px;
    right: 10px; }
  .basket-section__location-delivery .dropdown-fade2white {
    display: none; }
  .basket-section__location-delivery .bx-sls .bx-ui-sls-pane {
    top: 66px; }
  .basket-section__location-delivery .bx-sls .quick-location-tag {
    color: #000; }

.basket-item {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media only screen and (max-width: 576px) {
    .basket-item {
      position: relative;
      flex-wrap: wrap; } }
  .basket-item__link {
    display: flex;
    gap: 30px;
    text-decoration: none;
    width: 40%; }
    @media only screen and (max-width: 576px) {
      .basket-item__link {
        width: 100%;
        gap: 15px;
        margin-bottom: 15px; } }
  .basket-item__img {
    max-width: 110px;
    height: 70px;
    object-fit: contain; }
    .basket-item__img .img {
      width: 100%;
      height: 100%; }
  .basket-item__count {
    display: flex;
    align-items: flex-end; }
  @media only screen and (max-width: 576px) {
    .basket-item__btn-close {
      position: absolute;
      top: 0; } }

.item-description {
  display: flex;
  flex-direction: column;
  max-width: 303px;
  gap: 11px; }
  .item-description__title {
    font-size: 16px;
    color: #000;
    font-family: "TT Commons Pro Expanded Medium"; }
    @media only screen and (max-width: 576px) {
      .item-description__title {
        font-size: 14px; } }
  .item-description__text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5); }

.quantity {
  padding: 14px 20px;
  border: 1px solid #EDEDED;
  border-radius: 50px;
  height: 50px; }
  .quantity__field {
    display: flex; }
  .quantity__btn {
    background: none;
    border: none;
    cursor: pointer; }
    .quantity__btn:disabled {
      opacity: 0.5;
      cursor: auto; }
  .quantity__input {
    text-align: center;
    width: 60px;
    border: none;
    outline: none; }
  .quantity__icon {
    width: 9px;
    height: 9px;
    color: #24306B; }
    .quantity__icon--left {
      transform: rotate(180deg); }

.section-items {
  background: #D5DFE9;
  margin: 0 20px;
  border-radius: 5px;
  padding: 20px; }
  .section-items__top {
    display: flex;
    justify-content: space-between; }
  .section-items__goods {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .section-items__title {
    font-size: 16px;
    color: #000;
    font-family: "TT Commons Pro Expanded Medium";
    margin-bottom: 16px; }

.small-card {
  max-width: 200px; }
  .small-card__title {
    display: flex;
    gap: 15px;
    margin-bottom: 16px; }
  .small-card__img {
    max-width: 110px;
    width: 100%;
    height: 70px;
    object-fit: contain;
    margin-bottom: 14px; }
    .small-card__img .img {
      width: 100%;
      height: 100%; }

.promo-input {
  width: 100%; }
  .promo-input__input {
    padding: 20px !important; }
  .promo-input__enter {
    margin-bottom: 10px; }
  .promo-input__message {
    display: flex;
    flex-direction: column;
    gap: 10px; }
  .promo-input__success {
    top: 60px;
    background: #24306B;
    color: #fff;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 14px;
    width: fit-content; }
  .promo-input__error {
    top: 100px;
    background: #D5DFE9;
    color: #000;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 14px;
    width: fit-content; }

.main-input {
  width: 100%;
  height: 55px;
  border: 1px solid #F0F0F0;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  outline: none;
  padding: 20px; }
  .main-input--promo {
    padding: 9px; }
  .main-input__enter {
    width: 100%;
    height: 55px;
    padding: 5px 8px;
    border: 1px solid #F0F0F0;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 10px; }
  .main-input__span {
    position: absolute;
    background: white;
    color: rgba(0, 0, 0, 0.5);
    font-size: 15px;
    margin-left: 10px; }
  .main-input__message {
    display: flex;
    flex-direction: column;
    gap: 10px; }
  .main-input__input {
    border: none;
    outline: none;
    width: 100%;
    height: 20px;
    padding: 10px;
    resize: none; }
    .main-input__input::-webkit-calendar-picker-indicator {
      opacity: 0; }
    .main-input__input:before {
      content: url("/img/icons/calendar.svg");
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%); }
  .main-input__icon {
    width: 17px;
    height: 17px;
    color: #fff; }
  .main-input__button {
    width: 80px;
    height: 44px;
    border: none;
    background: #24306B;
    opacity: 0.5;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .3s; }
    .main-input__button:hover {
      opacity: 1; }

.total-price {
  padding: 0; }
  .total-price__item {
    display: flex;
    justify-content: space-between; }
  .total-price li {
    align-items: baseline;
    display: flex;
    margin-bottom: 18px; }
  .total-price li::before {
    content: '';
    border-bottom: 1px dashed #ccc;
    flex-grow: 1;
    order: 2;
    margin: 0 5px; }
  .total-price .total-price__span {
    order: 3; }

.total-goods {
  max-width: 382px;
  width: 100%; }
  .total-goods__price {
    margin-top: 30px;
    font-family: "TT Commons Pro Expanded Medium";
    margin-bottom: 0 !important; }
  .total-goods__span {
    font-family: "TT Commons Pro Expanded Medium";
    font-size: 20px; }

.form-radio {
  display: flex;
  flex-direction: column;
  gap: 20px; }
  .form-radio__text {
    font-size: 16px;
    font-family: "TT Commons Pro Expanded Medium"; }
  .form-radio__span {
    color: #24306B;
    font-family: "TT Commons Pro Expanded Medium";
    display: contents; }

.card-min {
  display: flex;
  gap: 40px; }
  .card-min__content {
    max-width: 303px;
    width: 100%;
    display: flex;
    flex-direction: column; }
  .card-min__title {
    font-size: 16px;
    font-family: "TT Commons Pro Expanded Medium";
    color: #000;
    margin-bottom: 11px; }
  .card-min__text {
    font-size: 14px;
    color: #000; }
  .card-min__img {
    max-width: 110px;
    width: 100%;
    height: 100px;
    object-fit: contain; }
    .card-min__img .img {
      width: 100%;
      height: 100%; }

.manage-basket-item-remove {
  transform: translateY(-25px); }
  @media only screen and (max-width: 576px) {
    .manage-basket-item-remove {
      position: absolute;
      top: 25px;
      right: 10px; } }

.cart-note {
  margin-top: 25px; }

.pickup .bx-soa-pickup-subTitle {
  margin-bottom: 20px;
  font-size: 20px; }

.pickup .bx-soa-pickup-l-item-name {
  margin-bottom: 20px;
  font-size: 18px;
  color: #24306B;
  font-family: "TT Commons Pro Expanded Medium"; }

.pickup .bx-soa-pickup-l-item-adress {
  display: none; }

.pickup .bx-soa-pickup-list-item:not(:last-child) {
  margin-bottom: 30px; }

.pickup .btn-choice-point {
  display: inline-block;
  margin-top: 10px; }

.pickup .bx-pagination-container ul {
  display: flex;
  align-items: center;
  gap: 20px; }

.pickup .bx-pag-prev a,
.pickup .bx-pag-next a {
  color: #fff; }

.cart-main__properties-title, .cart-main__delivery-title, .cart-main__paysystem-title, .cart-main__region-title {
  margin-top: 0;
  margin-bottom: 20px; }

.page-feedback__title {
  margin-bottom: 60px; }
  @media only screen and (max-width: 1280px) {
    .page-feedback__title {
      margin-bottom: 50px; } }
  @media only screen and (max-width: 976px) {
    .page-feedback__title {
      margin-top: 30px;
      margin-bottom: 30px; } }

.page-feedback__card {
  margin-bottom: 60px; }
  @media only screen and (max-width: 1280px) {
    .page-feedback__card {
      margin-bottom: 50px; } }
  @media only screen and (max-width: 976px) {
    .page-feedback__card {
      margin-bottom: 30px; } }

.page-feedback__subtitle {
  font-size: 16px;
  font-family: "TT Commons Pro Expanded Medium";
  color: #000;
  margin-bottom: 20px; }

.page-feedback__rating {
  margin-bottom: 60px; }
  @media only screen and (max-width: 1280px) {
    .page-feedback__rating {
      margin-bottom: 50px; } }
  @media only screen and (max-width: 976px) {
    .page-feedback__rating {
      margin-bottom: 30px; } }

.page-feedback__input {
  max-width: 606px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 60px;
  min-height: 110px; }

.page-feedback__input-inside {
  min-height: 100px; }

.page-feedback__button {
  padding: 18px 25px;
  font-size: 14px; }
  @media only screen and (max-width: 976px) {
    .page-feedback__button {
      margin-bottom: 0; } }

.page-favorites {
  max-width: 1472px;
  padding-left: 16px;
  padding-right: 16px; }
  @media only screen and (max-width: 1280px) {
    .page-favorites {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 1024px) {
    .page-favorites {
      padding-left: 32px;
      padding-right: 32px; } }
  @media only screen and (max-width: 976px) {
    .page-favorites {
      padding-left: 15px;
      padding-right: 15px; } }
  .page-favorites__title {
    margin-bottom: 60px; }
    @media only screen and (max-width: 976px) {
      .page-favorites__title {
        margin-bottom: 30px; } }
  .page-favorites__cards {
    gap: 30px 20px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px; }
    @media only screen and (max-width: 976px) {
      .page-favorites__cards {
        gap: 30px 15px; } }
  .page-favorites__card {
    width: calc(20% - 16px); }
    @media only screen and (max-width: 1024px) {
      .page-favorites__card {
        width: calc(25% - 15px); } }
    @media only screen and (max-width: 976px) {
      .page-favorites__card {
        width: calc(33% - 8px); } }
    @media only screen and (max-width: 576px) {
      .page-favorites__card {
        width: calc(50% - 8px); } }
  .page-favorites__bread-crumbs {
    margin: 30px 0 60px; }

.page-404 {
  max-width: 1472px;
  padding-left: 16px;
  padding-right: 16px; }
  .page-404 .container {
    padding: 0; }
  @media only screen and (max-width: 1280px) {
    .page-404 {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 1024px) {
    .page-404 {
      padding-left: 32px;
      padding-right: 32px; } }
  @media only screen and (max-width: 976px) {
    .page-404 {
      padding-left: 15px;
      padding-right: 15px; } }
  .page-404__title {
    font-size: 100px;
    color: #24306B;
    font-family: "TT Commons Pro Expanded Medium";
    text-align: center;
    margin-top: 173px; }
    @media only screen and (max-width: 576px) {
      .page-404__title {
        margin-top: 30px; } }
  .page-404__subtitle {
    font-size: 18px;
    color: #000;
    font-family: "TT Commons Pro Expanded Medium";
    text-align: center;
    margin-bottom: 51px; }
  .page-404__text {
    max-width: 755px;
    text-align: center;
    margin: 0 auto 36px;
    font-size: 18px;
    line-height: 31px; }
  .page-404__button {
    width: fit-content;
    display: flex;
    padding: 15px 97px;
    margin: 0 auto 172px; }
    @media only screen and (max-width: 576px) {
      .page-404__button {
        margin-bottom: 60px; } }

.page-comparison {
  max-width: 1472px;
  padding-left: 16px;
  padding-right: 16px; }
  .page-comparison .container {
    padding: 0; }
  @media only screen and (max-width: 1280px) {
    .page-comparison {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 1024px) {
    .page-comparison {
      padding-left: 32px;
      padding-right: 32px; } }
  @media only screen and (max-width: 976px) {
    .page-comparison {
      padding-left: 15px;
      padding-right: 15px; } }
  .page-comparison__bread-crumbs {
    margin: 30px 0 60px; }
  .page-comparison__title {
    margin-bottom: 60px; }
    @media only screen and (max-width: 976px) {
      .page-comparison__title {
        margin-bottom: 30px; } }
  .page-comparison__slider {
    margin: 0 60px 60px;
    position: relative; }
    .page-comparison__slider-mobile-controll {
      position: relative;
      max-width: 110px;
      margin: 0 auto 37px; }
    .page-comparison__slider-mobile-prev {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      z-index: 2; }
    .page-comparison__slider-mobile-next {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: 2; }
    @media only screen and (max-width: 1280px) {
      .page-comparison__slider {
        margin: 0 0 60px; }
        .page-comparison__slider .js-comparison-slider-btn-next {
          position: absolute;
          right: -25px; } }
    @media only screen and (max-width: 1280px) and (max-width: 976px) {
      .page-comparison__slider .js-comparison-slider-btn-next {
        right: -5px; } }
    @media only screen and (max-width: 1280px) {
        .page-comparison__slider .js-comparison-slider-btn-prev {
          position: absolute;
          left: -25px; } }
    @media only screen and (max-width: 1280px) and (max-width: 976px) {
      .page-comparison__slider .js-comparison-slider-btn-prev {
        left: -5px; } }
    @media only screen and (max-width: 576px) {
      .page-comparison__slider {
        display: none; } }
  .page-comparison__sliders-mobile {
    display: none;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px; }
    @media only screen and (max-width: 576px) {
      .page-comparison__sliders-mobile {
        display: grid; } }
    .page-comparison__sliders-mobile .swiper-pagination {
      left: 0;
      right: 0;
      bottom: -17px;
      display: flex;
      justify-content: center;
      gap: 5px;
      z-index: 1; }

.slider-fraction {
  display: flex;
  gap: 5px;
  justify-content: center;
  font-size: 14px;
  color: #808080; }

.text-main__title {
  font-size: 35px;
  font-family: "TT Commons Pro Expanded Medium";
  text-align: center;
  margin-bottom: 40px; }

.text-main__subtitle {
  font-size: 25px;
  font-family: "TT Commons Pro Expanded Medium";
  margin-bottom: 23px; }

.text-main__text {
  font-size: 18px;
  line-height: 31px;
  margin-bottom: 30px; }
  @media only screen and (max-width: 976px) {
    .text-main__text {
      font-size: 16px;
      line-height: 1.5; } }

.text-main__border {
  height: 1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 30px; }

.page-privacy {
  max-width: 1472px;
  padding-left: 16px;
  padding-right: 16px; }
  .page-privacy .container {
    padding: 0; }
  @media only screen and (max-width: 1280px) {
    .page-privacy {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 1024px) {
    .page-privacy {
      padding-left: 32px;
      padding-right: 32px; } }
  @media only screen and (max-width: 976px) {
    .page-privacy {
      padding-left: 15px;
      padding-right: 15px; } }
  .page-privacy__bread-crumbs {
    margin: 30px 0 40px; }
  .page-privacy__content {
    margin-bottom: 60px; }

.page-guarantee {
  max-width: 1472px;
  padding-left: 16px;
  padding-right: 16px; }
  .page-guarantee .container {
    padding: 0; }
  @media only screen and (max-width: 1280px) {
    .page-guarantee {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 1024px) {
    .page-guarantee {
      padding-left: 32px;
      padding-right: 32px; } }
  @media only screen and (max-width: 976px) {
    .page-guarantee {
      padding-left: 15px;
      padding-right: 15px; } }
  .page-guarantee__bread-crumbs {
    margin: 30px 0 40px; }
  .page-guarantee__content {
    margin-bottom: 60px; }

.page-installment {
  max-width: 1472px;
  padding-left: 16px;
  padding-right: 16px; }
  .page-installment .container {
    padding: 0; }
  @media only screen and (max-width: 1280px) {
    .page-installment {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 1024px) {
    .page-installment {
      padding-left: 32px;
      padding-right: 32px; } }
  @media only screen and (max-width: 976px) {
    .page-installment {
      padding-left: 15px;
      padding-right: 15px; } }
  .page-installment__bread-crumbs {
    margin: 30px 0 40px; }
    @media only screen and (max-width: 976px) {
      .page-installment__bread-crumbs {
        margin-bottom: 20px;
        padding-bottom: 10px; } }
  .page-installment__image {
    max-width: 1440px;
    width: 100%;
    object-fit: contain;
    margin-bottom: 60px; }
  .page-installment__img {
    width: 100%; }
  .page-installment__content {
    display: flex;
    gap: 36px;
    margin-bottom: 60px; }
    @media only screen and (max-width: 976px) {
      .page-installment__content {
        flex-direction: column; } }
  .page-installment__text-block {
    max-width: 948px;
    width: 100%; }
  .page-installment__partners {
    min-width: 280px; }

.content-images {
  max-width: 280px;
  width: 100%; }

.partners-img__title {
  font-family: "TT Commons Pro Expanded Medium";
  color: #000;
  font-size: 25px;
  margin-bottom: 23px; }

.page-offer__bread-crumbs {
  margin: 30px 0 60px; }

.page-offer__title {
  margin-bottom: 40px; }

.page-offer__cards {
  display: flex;
  flex-wrap: wrap;
  gap: 50px 22px;
  margin-bottom: 60px; }
  .page-offer__cards [data-entity="items-row"] {
    width: calc(20% - 18px); }
    @media only screen and (max-width: 1024px) {
      .page-offer__cards [data-entity="items-row"] {
        width: calc(25% - 17px); } }
    @media only screen and (max-width: 976px) {
      .page-offer__cards [data-entity="items-row"] {
        width: calc(25% - 12px); } }
    @media only screen and (max-width: 576px) {
      .page-offer__cards [data-entity="items-row"] {
        width: calc(50% - 8px); } }
  @media only screen and (max-width: 976px) {
    .page-offer__cards {
      gap: 40px 15px; } }

.delivery-page {
  max-width: 1472px;
  padding-left: 16px;
  padding-right: 16px; }
  .delivery-page .container {
    padding: 0; }
  @media only screen and (max-width: 1280px) {
    .delivery-page {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 1024px) {
    .delivery-page {
      padding-left: 32px;
      padding-right: 32px; } }
  @media only screen and (max-width: 976px) {
    .delivery-page {
      padding-left: 15px;
      padding-right: 15px; } }
  .delivery-page__bread-crumbs {
    margin: 30px 0 40px; }
  .delivery-page__title {
    text-align: center;
    margin-bottom: 57px; }
  .delivery-page__content {
    display: flex;
    gap: 40px;
    justify-content: space-between; }
    @media only screen and (max-width: 1024px) {
      .delivery-page__content {
        flex-direction: column;
        gap: 60px;
        margin-bottom: 60px; } }
  .delivery-page__delivery-cards {
    max-width: 948px;
    width: 100%; }
  .delivery-page__column {
    max-width: 456px;
    width: 100%; }
  .delivery-page__cards-title {
    font-family: "TT Commons Pro Expanded Medium";
    font-size: 23px;
    margin-bottom: 27px; }
  .delivery-page__card {
    margin-bottom: 40px; }

.delivery-icon {
  display: flex;
  gap: 12px;
  font-size: 14px; }
  .delivery-icon__icon {
    width: 19px;
    height: 19px;
    color: #24306B; }

.delivery-card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .delivery-card:last-child {
    border-bottom: none; }
    @media only screen and (max-width: 1024px) {
      .delivery-card:last-child {
        padding-bottom: 0;
        margin-bottom: 0; } }
  .delivery-card__content {
    max-width: 543px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 13px; }
  .delivery-card__text {
    font-size: 18px;
    line-height: 31px;
    max-width: 543px;
    width: 100%; }
    @media only screen and (max-width: 1024px) {
      .delivery-card__text {
        font-size: 16px; } }
    @media only screen and (max-width: 976px) {
      .delivery-card__text {
        max-width: 400px; } }
  .delivery-card__info {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-top: 38px; }
    @media only screen and (max-width: 576px) {
      .delivery-card__info {
        flex-direction: column;
        margin-top: 30px; } }
  .delivery-card__payment-methods {
    max-width: 353px;
    width: 100%; }
  @media only screen and (max-width: 976px) {
    .delivery-card__pay {
      max-width: 400px; } }

.info-price {
  padding: 20px 48px 20px 20px;
  background: #F6F5F5;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 455px;
  width: 100%; }
  @media only screen and (max-width: 976px) {
    .info-price {
      max-width: 400px; } }
  .info-price__payment {
    color: #24306B;
    max-width: 63px;
    width: 100%;
    text-align: left; }

.article-list {
  max-width: 353px; }
  .article-list__title {
    font-size: 20px;
    font-family: "TT Commons Pro Expanded Medium";
    margin-bottom: 15px; }
  .article-list__item {
    font-size: 18px;
    margin-left: 27px;
    list-style-type: disc;
    line-height: 31px;
    font-family: "TT Commons Pro Expanded Regular"; }

.installment-card {
  background: #D5DFE9;
  padding: 55px 40px 50px 40px;
  height: fit-content;
  width: 58%; }
  @media only screen and (max-width: 1024px) {
    .installment-card {
      width: 100%;
      max-width: 370px; } }
  @media only screen and (max-width: 576px) {
    .installment-card {
      width: 100%; } }
  .installment-card__text {
    font-size: 18px;
    line-height: 31px;
    margin-top: 30px; }
    @media only screen and (max-width: 1024px) {
      .installment-card__text {
        font-size: 16px; } }

.faq-page {
  max-width: 1472px;
  padding-left: 16px;
  padding-right: 16px; }
  .faq-page .container {
    padding: 0; }
  @media only screen and (max-width: 1280px) {
    .faq-page {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 1024px) {
    .faq-page {
      padding-left: 32px;
      padding-right: 32px; } }
  @media only screen and (max-width: 976px) {
    .faq-page {
      padding-left: 15px;
      padding-right: 15px; } }
  .faq-page__bread-crumbs {
    margin: 30px 0 40px; }
  .faq-page__title {
    text-align: center;
    margin-bottom: 40px; }
  .faq-page__accordion {
    margin: 0 auto; }

.accordion {
  margin-bottom: 50px;
  max-width: 948px;
  width: 100%; }
  .accordion__icon {
    width: 14px;
    height: 14px;
    color: #24306B;
    transition: .4s; }
    .accordion__icon--active {
      transform: rotate(180deg); }
  .accordion__title {
    color: #24306B;
    font-size: 18px;
    font-family: "TT Commons Pro Expanded Medium"; }
    @media only screen and (max-width: 576px) {
      .accordion__title {
        font-size: 16px; } }
  .accordion__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 30px 10px;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
    .accordion__button--border {
      border-bottom: 1px solid transparent; }
  .accordion__body {
    display: none;
    padding: 0px 10px 30px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 18px;
    line-height: 31px;
    opacity: 1;
    -webkit-animation: display-none-transition 1s both;
    animation: display-none-transition 1s both; }
    @media only screen and (max-width: 576px) {
      .accordion__body {
        font-size: 16px;
        line-height: 22px; } }

@-webkit-keyframes display-none-transition {
  0% {
    opacity: 0; } }

@keyframes display-none-transition {
  0% {
    opacity: 0; } }
    .accordion__body.active {
      display: block;
      overflow: initial; }

.personal-page {
  max-width: 1472px;
  padding-left: 16px;
  padding-right: 16px; }
  .personal-page .container {
    padding: 0; }
  @media only screen and (max-width: 1280px) {
    .personal-page {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 1024px) {
    .personal-page {
      padding-left: 32px;
      padding-right: 32px; } }
  @media only screen and (max-width: 976px) {
    .personal-page {
      padding-left: 15px;
      padding-right: 15px; } }
  .personal-page__bread-crumbs {
    margin: 30px 0 60px; }
  .personal-page__content {
    display: flex;
    gap: 36px; }

.personal-cart {
  max-width: 1200px;
  width: 100%;
  padding: 30px 0 30px 40px;
  position: relative; }
  @media only screen and (max-width: 576px) {
    .personal-cart {
      width: calc(100% + 30px);
      transform: translateX(-15px); } }
  .personal-cart__preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex; }
  .personal-cart__img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px; }
  .personal-cart__title {
    font-size: 30px;
    color: #fff;
    font-family: "TT Commons Pro Expanded Medium"; }
    @media only screen and (max-width: 576px) {
      .personal-cart__title {
        font-size: 20px; } }
  .personal-cart__content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-end; }
  .personal-cart__button {
    padding: 13px 20px;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 14px; }
  .personal-cart__buttons {
    display: flex;
    gap: 10px; }

.card-status-yellow {
  background: #FFC700; }

.card-status-blue {
  background: #3C5AED; }

.card-status-orange {
  background: #F98600; }

.card-status-red {
  background: #CA0000; }

.card-status-green {
  background: #7FA392; }

.card-status-grey {
  background: #C0C0C0; }

.order-card {
  width: 100%;
  background: #F9F9F9;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 20px; }
  .order-card--wide {
    max-width: 582px; }
  .order-card__block {
    display: flex;
    justify-content: space-between; }
    @media only screen and (max-width: 576px) {
      .order-card__block {
        flex-direction: column; } }
  .order-card__title {
    font-size: 15px;
    line-height: 19px;
    color: #000;
    font-family: "TT Commons Pro Expanded Medium";
    margin-bottom: 5px; }
  .order-card__subtitle {
    font-size: 15px;
    line-height: 19px;
    color: #808080;
    margin-bottom: 10px; }
  .order-card__text {
    color: #24306B;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 35px; }
    .order-card__text--space {
      margin-bottom: 0px;
      min-width: 135px;
      text-align: right; }
      @media only screen and (max-width: 576px) {
        .order-card__text--space {
          margin: 10px 0;
          text-align: left; } }
  .order-card__images {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between; }
    .order-card__images--width {
      max-width: 250px;
      width: 100%; }
  .order-card__status {
    padding: 5px 10px;
    color: #fff;
    margin-top: 15px;
    margin-bottom: 30px; }
  .order-card__link {
    color: #24306B;
    text-decoration: none; }
  .order-card__preview {
    max-width: 53px;
    width: 100%;
    height: 35px;
    object-fit: contain; }
  .order-card__img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.personal-card {
  max-width: 200px;
  width: 100%; }
  .personal-card__preview {
    max-width: 110px;
    width: 100%;
    height: 70px;
    object-fit: contain; }
  .personal-card__img {
    width: 100%;
    margin-bottom: 14px; }
  .personal-card__title {
    margin-bottom: 10px; }

.personal-main {
  display: flex;
  flex-direction: row;
  gap: 36px; }
  @media only screen and (max-width: 976px) {
    .personal-main {
      gap: 20px; } }
  @media only screen and (max-width: 576px) {
    .personal-main {
      flex-direction: column; } }
  .personal-main__header {
    display: flex;
    white-space: nowrap;
    gap: 7px;
    flex-direction: column;
    align-items: flex-start;
    max-width: 210px;
    width: 100%; }
  .personal-main__content {
    width: calc(100% - 246px);
    opacity: 1;
    transition: .3s; }
    @media only screen and (max-width: 576px) {
      .personal-main__content {
        width: 100%; } }
  .personal-main__button {
    text-align: left;
    padding: 15px 0 15px 15px;
    width: 100%;
    transition: .3s;
    color: #000; }
    .personal-main__button--active {
      border-radius: 10px;
      color: #24306B;
      background: #D5DFE9; }
  .personal-main__main-personal {
    margin-bottom: 76px; }

.main-personal__top {
  margin-bottom: 30px; }
  @media only screen and (max-width: 576px) {
    .main-personal__top {
      margin-bottom: 60px; } }

.main-personal__title {
  margin-bottom: 25px; }

.main-personal__cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 20px; }
  @media only screen and (max-width: 1280px) {
    .main-personal__cards {
      grid-template-columns: repeat(3, 1fr); }
      .main-personal__cards .order-card:nth-child(n+4) {
        display: none; } }
  @media only screen and (max-width: 1024px) {
    .main-personal__cards {
      display: none; } }

.main-personal__cards-slider {
  display: none; }
  @media only screen and (max-width: 1024px) {
    .main-personal__cards-slider {
      display: block;
      width: calc( 100% + 32px);
      margin-bottom: 20px; } }

.main-personal__link {
  width: fit-content;
  margin-bottom: 30px; }

.title-min {
  font-size: 20px;
  color: #000;
  font-family: "TT Commons Pro Expanded Medium"; }

.personal-data__info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px 10px;
  margin-bottom: 20px; }
  @media only screen and (max-width: 1024px) {
    .personal-data__info {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 576px) {
    .personal-data__info {
      grid-template-columns: 1fr;
      gap: 25px; } }

.personal-data__button {
  font-family: "TT Commons Pro Expanded Medium"; }

.personal-data__footer {
  margin-bottom: 60px;
  display: flex;
  gap: 12px;
  align-items: center;
  position: relative; }

.personal-data__title {
  margin-bottom: 30px; }

.personal-data__link {
  padding: 0;
  margin-bottom: 60px; }

.personal-data__password {
  margin-bottom: 20px; }

.personal-data__card {
  margin-bottom: 60px; }

.card-personal {
  display: flex;
  flex-direction: column;
  gap: 4px; }
  .card-personal__title {
    font-size: 13px;
    line-height: 16px;
    opacity: 0.5;
    color: #000; }
  .card-personal__text {
    font-size: 15px;
    line-height: 19px;
    color: #000; }

.sale-card {
  position: relative;
  max-width: 680px;
  width: 100%;
  border: 1px solid #F6F5F5;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  overflow: hidden; }
  .sale-card__title {
    font-size: 24px;
    line-height: 30px;
    color: #000;
    font-family: "TT Commons Pro Expanded Medium";
    margin-bottom: 20px; }
    @media only screen and (max-width: 576px) {
      .sale-card__title {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 22px; } }
  .sale-card__subtitle {
    font-size: 15px;
    line-height: 19px;
    color: #000;
    font-family: "TT Commons Pro Expanded Medium";
    margin-bottom: 30px; }
    @media only screen and (max-width: 576px) {
      .sale-card__subtitle {
        font-size: 14px; } }
  .sale-card__text {
    font-size: 14px;
    line-height: 18px;
    color: #24306B;
    margin-bottom: 20px; }
  .sale-card__column {
    position: relative; }
    @media only screen and (max-width: 976px) {
      .sale-card__column:first-child {
        max-width: 330px; }
      .sale-card__column:not(:first-child) {
        position: absolute;
        right: -50px; } }
    @media only screen and (max-width: 576px) {
      .sale-card__column:first-child {
        max-width: 71%; }
      .sale-card__column:not(:first-child) {
        position: absolute;
        top: 55px;
        right: -60px;
        width: 127px; } }
  .sale-card__preview {
    max-width: 163px;
    width: 100%;
    height: 163px;
    object-fit: contain; }
    .sale-card__preview--min {
      position: absolute;
      top: 7px;
      left: -33px;
      max-width: 70px;
      height: 70px; }
  .sale-card__background, .sale-card__image {
    width: 100%; }
  .sale-card__checkbox {
    display: flex;
    gap: 25px; }

.img-info {
  position: relative; }
  .img-info__img {
    cursor: pointer; }
  .img-info:hover .img-info__content {
    display: flex; }
  .img-info__content {
    position: absolute;
    max-width: 281px;
    width: 100%;
    padding: 15px;
    box-shadow: 4px 8px 23px #C7C7C7;
    top: -138px;
    left: 15px;
    z-index: 3;
    background: #fff;
    font-size: 13px;
    display: none;
    opacity: 1;
    -webkit-animation: display-none-transition .3s both;
    animation: display-none-transition .3s both; }

@-webkit-keyframes display-none-transition {
  0% {
    opacity: 0; } }

@keyframes display-none-transition {
  0% {
    opacity: 0; } }
  .img-info__block {
    width: 25px;
    height: 25px;
    background: #fff;
    position: absolute;
    transform: rotate(136deg);
    left: 44%;
    bottom: -13px; }

@media only screen and (max-width: 976px) {
  .personal-redact .page-favorites__card {
    width: calc(50% - 8px); } }

.personal-redact__personal-order {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px 10px;
  margin-bottom: 60px;
  background: #F9F9F9;
  border-radius: 4px;
  padding: 20px;
  position: relative; }
  @media only screen and (max-width: 976px) {
    .personal-redact__personal-order {
      grid-template-columns: 1fr;
      padding-bottom: 70px; } }

.personal-redact__status {
  padding: 5px 10px;
  width: fit-content;
  color: #fff;
  margin-bottom: 20px; }

.personal-redact__card-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 60px; }
  @media only screen and (max-width: 1024px) {
    .personal-redact__card-box {
      grid-template-columns: 1fr; } }
  @media only screen and (max-width: 576px) {
    .personal-redact__card-box {
      gap: 40px; } }

.personal-redact__goods {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 60px; }
  @media only screen and (max-width: 576px) {
    .personal-redact__goods {
      grid-template-columns: repeat(2, 1fr);
      gap: 16px; } }

.personal-redact__personal-card {
  margin-bottom: 60px; }

.personal-redact__button {
  width: fit-content;
  margin-bottom: 34px; }

.personal-redact__title {
  margin-bottom: 30px; }

.personal-redact__form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 30px; }
  @media only screen and (max-width: 768px) {
    .personal-redact__form {
      grid-template-columns: 1fr;
      gap: 25px; } }

.personal-redact__form-passwords {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px; }

.personal-redact__button-submit {
  padding: 15px 20px;
  margin-bottom: 445px; }

.total-personal-card {
  display: flex;
  justify-content: space-between;
  max-width: 606px;
  width: 100%;
  border: 1px solid #CACACA;
  border-radius: 10px;
  padding: 40px;
  align-items: center; }
  @media only screen and (max-width: 976px) {
    .total-personal-card {
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;
      padding: 20px; } }
  .total-personal-card__column {
    max-width: 285px;
    width: 100%; }
  .total-personal-card__button {
    padding: 18px 50px;
    display: flex;
    align-items: center;
    gap: 12px;
    height: 53px;
    justify-content: center; }
    @media only screen and (max-width: 576px) {
      .total-personal-card__button {
        width: 100%; } }
  .total-personal-card__border {
    border: 1px solid black;
    color: #000;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px; }
  .total-personal-card__icon {
    width: 20px;
    height: 20px; }
  .total-personal-card__description {
    max-width: 300px;
    width: 100%;
    display: flex; }
  .total-personal-card__text {
    font-size: 14px;
    line-height: 31px;
    color: #000;
    max-width: 100px;
    width: 100%; }
    .total-personal-card__text--big {
      font-size: 20px;
      line-height: 25px;
      font-family: "TT Commons Pro Expanded Medium";
      max-width: 150px; }

.btn-close-empty {
  display: flex;
  align-items: center;
  gap: 14px;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0; }
  @media only screen and (max-width: 1024px) {
    .btn-close-empty {
      top: auto;
      bottom: 20px; } }
  @media only screen and (max-width: 976px) {
    .btn-close-empty {
      bottom: 20px;
      left: 20px; } }
  .btn-close-empty__icon {
    width: 11px;
    height: 11px;
    color: #24306B; }
  .btn-close-empty__text {
    font-size: 14px;
    line-height: 18px;
    font-family: "TT Commons Pro Expanded Medium";
    color: #24306B; }

.input-text {
  position: relative;
  max-width: 348px;
  width: 100%; }
  .input-text__input {
    padding: 20px 20px 4px;
    height: 60px;
    max-width: 348px;
    width: 100%; }
  .input-text__text {
    position: absolute;
    top: 9px;
    left: 21px;
    font-size: 13px;
    line-height: 16px;
    color: #000;
    opacity: 0.5; }

.single-card {
  display: flex;
  gap: 40px; }
  @media only screen and (max-width: 576px) {
    .single-card {
      flex-direction: column;
      gap: 20px; } }
  .single-card__preview {
    max-width: 110px;
    width: 100%;
    height: 100px;
    object-fit: cover; }
  .single-card__img {
    width: 100%;
    height: 100%; }
  .single-card__description {
    max-width: 303px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px; }
  .single-card__title {
    font-size: 16px;
    line-height: 20px;
    color: #000;
    font-family: "TT Commons Pro Expanded Medium"; }
  .single-card__text {
    font-size: 14px; }

.password {
  width: 300px;
  margin: 15px auto;
  position: relative; }

.password-control {
  position: absolute;
  top: 21px;
  right: 23px;
  display: inline-block;
  width: 23px;
  height: 21px;
  background: url("/img/icons/open-oko.svg") 0 0 no-repeat; }

.password-control.view {
  background: url("/img/icons/close-oko.svg") 0 0 no-repeat; }

.widget_list {
  padding-left: 0px; }

.ui {
  margin-bottom: 50px; }
  .ui-section {
    padding: 50px; }
    .ui-section td {
      padding: 10px; }
    .ui-section .input-group {
      max-width: 480px;
      margin-bottom: 20px; }
  .ui-header {
    padding: 35px 50px;
    background: #F4F7FB;
    color: #5A6872;
    font-size: 54px; }
  .ui-title {
    font-size: 24px;
    padding-bottom: 18px; }

.ui-colors {
  display: flex;
  align-items: flex-start;
  margin-bottom: 45px;
  flex-wrap: wrap; }

.ui-color {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 22px; }
  .ui-color__bg {
    width: 80px;
    height: 50px;
    margin-bottom: 10px;
    border: 1px solid #EFF2F5; }
  .ui-color__name {
    color: #5A6872;
    margin-bottom: 10px; }
  .ui-color__code {
    color: #000;
    text-decoration: underline; }
