.rating-area {
		overflow: hidden;
		max-width: 115px;
		width: 100%;

		&__icon {
				width: 23px;
				height: 23px; } }

.rating-area:not(:checked) > input {
		display: none; }

.rating-area:not(:checked) > label {
		float: right;
		padding: 0;
		cursor: pointer;
		font-size: 32px;
		line-height: 32px;
		color: lightgrey; }

.rating-area > input:checked ~ label {
		color: gold; }

.rating-area:not(:checked) > label:hover,
.rating-area:not(:checked) > label:hover ~ label {
		color: gold; }

.rating-area > input:checked + label:hover,
.rating-area > input:checked + label:hover ~ label,
.rating-area > input:checked ~ label:hover,
.rating-area > input:checked ~ label:hover ~ label,
.rating-area > label:hover ~ input:checked ~ label {
		color: gold; }

.rate-area > label:active {
		position: relative; }
