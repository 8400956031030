.rating-stock {
		width: fit-content;
		display: flex;
		align-items: center;
		gap: 4px;

		&__icon {
				width: 23px;
				height: 23px;
				opacity: .3;
				color: white;
				stroke: #ebc914;
				stroke-width: 2px;

				&--min {
						width: 19px;
						height: 19px; }

				&--current {
						color: $c-additional-yellow;
						opacity: 1; }

				// Не показывать более 5 иконок при ошибочной генерации
				&:nth-child(6n) {
						display: none !important; } } }
